import { CREATE_MALFUNCTION_TYPE, FETCH_MALFUNCTION_TYPES, UPDATE_MALFUNCTION_TYPE, DELETE_MALFUNCTION_TYPE } from "../constants/actionTypes"


const malfunctionTypeReducer = ( malfunctionType = [], action ) => {
    switch (action.type) {
        case CREATE_MALFUNCTION_TYPE:
            return [...malfunctionType, action.payload]

        case FETCH_MALFUNCTION_TYPES:
            return action.payload
        
        case UPDATE_MALFUNCTION_TYPE:
            return malfunctionType.map((type) => type._id === action.payload._id ? action.payload : type)

        case DELETE_MALFUNCTION_TYPE:
            return malfunctionType.filter((type) => type._id !== action.payload)
    
        default:
            return malfunctionType
    }
}

export default malfunctionTypeReducer