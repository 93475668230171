
import { CREATE_NEW_OTHERSITEINFORMATION, FETCH_ALL_OTHERSITEINFORMATIONS, UPDATE_OTHERSITEINFORMATION, DELETE_OTHERSITEINFORMATION } from "../constants/actionTypes"

const OtherSiteInformationReducer = ( OtherSiteInformation = [], action ) => {

    switch (action.type) {

        case CREATE_NEW_OTHERSITEINFORMATION:
            return [...OtherSiteInformation, action.payload]
        
        case FETCH_ALL_OTHERSITEINFORMATIONS:
            return action.payload
                
        case UPDATE_OTHERSITEINFORMATION:
            return OtherSiteInformation.map((e) => e._id === action.payload._id ? action.payload : e)
        
        case DELETE_OTHERSITEINFORMATION:
            return OtherSiteInformation.filter((e) => e._id !== action.payload)
            
        default:
            return OtherSiteInformation

    }
}
        
export default OtherSiteInformationReducer
        