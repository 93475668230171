import "./finishedTransformsFormFile.css"

import axios from "axios"
import { saveAs } from "file-saver"

import { Close } from "@material-ui/icons"

import { ReactComponent as ArrowDown } from "../../../../SVG/arrowDownSVG.svg"

export default function FinishedTransformsFormFile({ setOpenTransformsFile, transformsData, chosenTransformsFile, setChosenTransformsFile }) {

    const ChosenTransformsFile = transformsData.find((data) => data._id === chosenTransformsFile)

    const FileName = (ChosenTransformsFile.created_At.slice(4, 10) +" "+ ChosenTransformsFile.driver )

    const createAndDownloadPdf = () => {

        axios.post("/create-transform-pdf", ChosenTransformsFile)
        .then(() => axios.get("/fetch-transform-pdf", { responseType: "arraybuffer" }))
        .then((res) => {
          const pdfBlob = new Blob([res.data], { type: "application/pdf" });
  
          saveAs(pdfBlob, `${FileName}.pdf`);
        })
      }

    return (
        
        <div className="FinishedTransformsFormFile fadeInDown"> 
            <div className="FinishedTransformsFormFileInner fadeIn">
                <button className="endTransformsInnerExit" onClick={(e) => {
                    setOpenTransformsFile(false) 
                    setChosenTransformsFile()
                    }}>
                    <Close/>
                    </button>
                    <button className="endTransformsInnerDownload endTransformsInnerExit" onClick={createAndDownloadPdf}>
                    הורד קובץ שינוע<ArrowDown className="finishedTransformIcon transformIcon"/>
                    </button>
                <div className="endTransformsContainer">
                    <div className="endTransformsTop">

                        <h2 className="endTransformsTitle">נהג שינוע - {ChosenTransformsFile.driver}</h2>
                        <h3 className="TransformsInformation">מאבטחי שינוע:</h3>

                        <ul className="activeEntryInfoList transformsInfoList">
                            {ChosenTransformsFile.guards.map((guard, index) => {
                                return(
                                <div key={index} className="guardsInformationContainer">
                                    <h4 className="guardsInfoName">{guard.guards_name}</h4>
                                    <h4 className="guardsInfoHour">{guard.guards_Hours}</h4>
                                </div>
                                )
                            })}

                        </ul>

                        <div className="transformsHrContainer">
                            <hr className="transformsHr" />
                        </div>

                        <div className="TransformsFormsLeftMiddle">
                            <ul className="transformDataList">
                            {Object.keys(ChosenTransformsFile.data).length > 0 && ChosenTransformsFile.data.map((transform, index) => {
                                return(
                                    <div key={index} className="transformsDataLine">
                                        <span className="transformDataDateItem">{transform.update_Hour.slice(16,21)}</span>
                                        <li className="transformDataItem">{transform.way_Data}</li>
                                    </div>
                                    )
                                })}
                            </ul>
                        </div>

                    </div>
                        <div className="endTransformsBottom">
                            {ChosenTransformsFile.sideNote ?
                            <>
                            <h4 className="FinishedTransformsSideNoteTitle">אירועים חריגים שהתרחשו במהלך השינוע:</h4>
                            <p className="FinishedTransformsSideNote">{ChosenTransformsFile.sideNote}</p>
                            </>
                            : 
                            <h4 className="FinishedTransformsSideNoteTitle">לא התרחשו אירועים חריגים במהלך השינוע</h4>}
                        </div>
                </div>
            </div>
        </div>
    )
}

