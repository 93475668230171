import "./managerSiteInformation.css";

import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";

import ManagerClientList from "./ManagerClientList/ManagerClientList";

import {
  CreateSiteInformation,
  UpdateSiteInformation,
  DeleteSiteInformation,
} from "../../../actions/siteInformation";
import ManagerMaintenanceList from "./ManagerMaintenanceList/ManagerMaintenanceList";
import ManagerCanEnterList from "./ManagerCanEnterList/ManagerCanEnterList";
import ManagerCanApproveList from "./ManagerCanApproveList/ManagerCanApproveList";
import ManagerPatrolList from "./ManagerPatrolList/ManagerPatrolList";
import ManagerSideNoteList from "./ManagerSideNoteList/ManagerSideNoteList";
import ManagerSiteInformationName from "./ManagerSiteInformationName/ManagerSiteInformationName";

const initSiteInformationData = {
  client: [],
  siteName: "",
  manbat: "",
  system: "",
  maintenance: [],
  canEnter: [],
  canApprove: [],
  patrol: [],
  frequency: "",
  gate: "",
  alarm: "",
  sideNote: [],
  mokedType: "",
  malfunctions: [
    {
      urgent: [],
      notUrgent: [],
      emailList: [],
      malfunctionGroup: "",
      malfunctionGroupNotes: [],
    },
  ],
  technology: "",
  location: "",
  type: "",
};

export default function ManagerSiteInformation({
  chosenCURDType,
  setSiteInfoUpdater,
  sitesInformation,
  sitesInformationName,
}) {
  const [siteNameValue, setSiteNameValue] = useState("");

  return (
    <>
      {chosenCURDType && (
        <div className="siteInformationSection">
          <div className="siteInformationWrapper">
            <div className="mokedWorkerRight">
              <h1 className="mokedWorkerTitle">{chosenCURDType}</h1>
            </div>
            <div className="siteInformationContainer">
              {chosenCURDType === "רישום" ? (
                <CreateSiteInformationCRUD
                  setSiteInfoUpdater={setSiteInfoUpdater}
                />
              ) : chosenCURDType === "עריכה" ? (
                <EditSiteInformationCRUD
                  sitesInformation={sitesInformation}
                  setSiteInfoUpdater={setSiteInfoUpdater}
                  siteNameValue={siteNameValue}
                  setSiteNameValue={setSiteNameValue}
                  sitesInformationName={sitesInformationName}
                />
              ) : (
                <DeleteSiteInformationCRUD
                  setSiteInfoUpdater={setSiteInfoUpdater}
                  siteNameValue={siteNameValue}
                  setSiteNameValue={setSiteNameValue}
                  sitesInformationName={sitesInformationName}
                  sitesInformation={sitesInformation}
                />
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

const CreateSiteInformationCRUD = ({ setSiteInfoUpdater }) => {
  const dispatch = useDispatch();

  const [clientArray, setClientArray] = useState([]);
  const [maintenanceArray, setMaintenanceArray] = useState([]);
  const [canEnterArray, setCanEnterArray] = useState([]);
  const [canApproveArray, setCanApproveArray] = useState([]);
  const [patrolArray, setPatrolArray] = useState([]);
  const [sideNoteArray, setSideNoteArray] = useState([]);

  const [siteInformationData, setSiteInformationData] = useState(
    initSiteInformationData
  );

  useEffect(() => {
    setSiteInformationData((prev) => ({
      ...prev,
      client: clientArray,
      maintenance: maintenanceArray,
      canEnter: canEnterArray,
      canApprove: canApproveArray,
      patrol: patrolArray,
      sideNote: sideNoteArray,
    }));
  }, [
    clientArray,
    maintenanceArray,
    canEnterArray,
    canApproveArray,
    patrolArray,
    sideNoteArray,
  ]);

  const handleSubmit = (e) => {
    e.preventDefault();

    dispatch(CreateSiteInformation(siteInformationData));

    Clear();
  };

  const Clear = () => {
    setSiteInformationData(initSiteInformationData);

    setClientArray([]);
    setMaintenanceArray([]);
    setCanEnterArray([]);
    setCanApproveArray([]);
    setPatrolArray([]);
    setSideNoteArray([]);

    setSiteInfoUpdater((prev) => !prev);
  };

  return (
    <div>
      <form className="mokedWorkerForm" onSubmit={handleSubmit}>
        <div className="siteInformationContainerTop">
          <input
            type="text"
            className="mokedWorkerInput"
            value={siteInformationData.siteName}
            placeholder="שם האתר"
            onChange={(e) =>
              setSiteInformationData({
                ...siteInformationData,
                siteName: e.target.value,
              })
            }
          />
          <ManagerClientList
            clientArray={clientArray}
            setClientArray={setClientArray}
          />
          <input
            type="text"
            className="mokedWorkerInput"
            value={siteInformationData.manbat}
            placeholder="שם המנב''ט"
            onChange={(e) =>
              setSiteInformationData({
                ...siteInformationData,
                manbat: e.target.value,
              })
            }
          />
          <input
            type="text"
            className="mokedWorkerInput"
            value={siteInformationData.system}
            placeholder="חברת מערכת"
            onChange={(e) =>
              setSiteInformationData({
                ...siteInformationData,
                system: e.target.value,
              })
            }
          />
          <ManagerMaintenanceList
            maintenanceArray={maintenanceArray}
            setMaintenanceArray={setMaintenanceArray}
          />
          <ManagerCanEnterList
            canEnterArray={canEnterArray}
            setCanEnterArray={setCanEnterArray}
          />
          <ManagerCanApproveList
            canApproveArray={canApproveArray}
            setCanApproveArray={setCanApproveArray}
          />
          <ManagerPatrolList
            patrolArray={patrolArray}
            setPatrolArray={setPatrolArray}
          />
          <input
            type="text"
            className="mokedWorkerInput"
            value={siteInformationData.frequency}
            placeholder="תדירות סיור"
            onChange={(e) =>
              setSiteInformationData({
                ...siteInformationData,
                frequency: e.target.value,
              })
            }
          />
          <input
            type="text"
            className="mokedWorkerInput"
            value={siteInformationData.gate}
            placeholder="שערים"
            onChange={(e) =>
              setSiteInformationData({
                ...siteInformationData,
                gate: e.target.value,
              })
            }
          />
          <input
            type="text"
            className="mokedWorkerInput"
            value={siteInformationData.alarm}
            placeholder="כריזה"
            onChange={(e) =>
              setSiteInformationData({
                ...siteInformationData,
                alarm: e.target.value,
              })
            }
          />
          <input
            type="text"
            className="mokedWorkerInput"
            value={siteInformationData.technology}
            placeholder="טכנולוגיה"
            onChange={(e) =>
              setSiteInformationData({
                ...siteInformationData,
                technology: e.target.value,
              })
            }
          />
          <input
            type="text"
            className="mokedWorkerInput"
            value={siteInformationData.location}
            placeholder="מיקום"
            onChange={(e) =>
              setSiteInformationData({
                ...siteInformationData,
                location: e.target.value,
              })
            }
          />
          <ManagerSideNoteList
            sideNoteArray={sideNoteArray}
            setSideNoteArray={setSideNoteArray}
          />
          <input
            type="text"
            className="mokedWorkerInput"
            value={siteInformationData.mokedType}
            placeholder="אשכול \ נתיבות"
            onChange={(e) =>
              setSiteInformationData({
                ...siteInformationData,
                mokedType: e.target.value,
              })
            }
          />
          <h4>סוג מוקד</h4>
          <div className="mokedTypeMangerInfo">
            <input
              type="checkbox"
              checked={siteInformationData.type === "big"}
              className="mokedTypeMangerInfoCheckbox"
              value="big"
              onChange={(e) =>
                setSiteInformationData({
                  ...siteInformationData,
                  type: e.target.value,
                })
              }
            />
            <p className="mokedTypeMangerInfoLabel">מוקד גדול</p>
            <input
              type="checkbox"
              checked={siteInformationData.type === "small"}
              className="mokedTypeMangerInfoCheckbox"
              value="small"
              onChange={(e) =>
                setSiteInformationData({
                  ...siteInformationData,
                  type: e.target.value,
                })
              }
            />
            <p className="mokedTypeMangerInfoLabel">מוקד קטן</p>
          </div>
        </div>
        <div className="buttonWrapperUserClient">
          <input
            type="submit"
            className="BottomFormMokedWorkersButton enterCRUDButton"
            value="רשום אתר"
          />
        </div>
      </form>
    </div>
  );
};

const EditSiteInformationCRUD = ({
  sitesInformationName,
  siteNameValue,
  setSiteNameValue,
  sitesInformation,
  setSiteInfoUpdater,
}) => {
  const dispatch = useDispatch();

  const chosenSiteInformation = sitesInformation.find(
    (site) => site.siteName === siteNameValue
  );

  const [clientArray, setClientArray] = useState([]);
  const [maintenanceArray, setMaintenanceArray] = useState([]);
  const [canEnterArray, setCanEnterArray] = useState([]);
  const [canApproveArray, setCanApproveArray] = useState([]);
  const [patrolArray, setPatrolArray] = useState([]);
  const [sideNoteArray, setSideNoteArray] = useState([]);

  const [siteInformationData, setSiteInformationData] = useState(
    initSiteInformationData
  );

  useEffect(() => {
    if (chosenSiteInformation) {
      setSiteInformationData({
        ...initSiteInformationData,
        ...chosenSiteInformation,
      });

      setClientArray(chosenSiteInformation.client);
      setMaintenanceArray(chosenSiteInformation.maintenance);
      setCanEnterArray(chosenSiteInformation.canEnter);
      setCanApproveArray(chosenSiteInformation.canApprove);
      setPatrolArray(chosenSiteInformation.patrol);
      setSideNoteArray(chosenSiteInformation.sideNote);
    }
  }, [chosenSiteInformation]);

  useEffect(() => {
    setSiteInformationData((prev) => ({
      ...prev,
      client: clientArray,
      maintenance: maintenanceArray,
      canEnter: canEnterArray,
      canApprove: canApproveArray,
      patrol: patrolArray,
      sideNote: sideNoteArray,
    }));
  }, [
    clientArray,
    maintenanceArray,
    canEnterArray,
    canApproveArray,
    patrolArray,
    sideNoteArray,
  ]);

  const handleSubmit = (e) => {
    e.preventDefault();

    dispatch(
      UpdateSiteInformation(chosenSiteInformation._id, siteInformationData)
    );

    Clear();
  };

  const Clear = () => {
    setSiteInformationData(initSiteInformationData);

    setClientArray([]);
    setMaintenanceArray([]);
    setCanEnterArray([]);
    setCanApproveArray([]);
    setPatrolArray([]);
    setSideNoteArray([]);

    setSiteNameValue("");

    setSiteInfoUpdater((prev) => !prev);
  };

  return (
    <div>
      <form className="mokedWorkerForm" onSubmit={handleSubmit}>
        <div className="siteInformationContainerTop">
          <ManagerSiteInformationName
            sitesInformationName={sitesInformationName}
            siteNameValue={siteNameValue}
            setSiteNameValue={setSiteNameValue}
          />
          <input
            type="text"
            className="mokedWorkerInput"
            value={siteInformationData.siteName}
            placeholder="שם האתר"
            onChange={(e) =>
              setSiteInformationData({
                ...siteInformationData,
                siteName: e.target.value,
              })
            }
          />
          <ManagerClientList
            clientArray={clientArray}
            setClientArray={setClientArray}
          />
          <input
            type="text"
            className="mokedWorkerInput"
            value={siteInformationData.manbat}
            placeholder="שם המנב''ט"
            onChange={(e) =>
              setSiteInformationData({
                ...siteInformationData,
                manbat: e.target.value,
              })
            }
          />
          <input
            type="text"
            className="mokedWorkerInput"
            value={siteInformationData.system}
            placeholder="חברת מערכת"
            onChange={(e) =>
              setSiteInformationData({
                ...siteInformationData,
                system: e.target.value,
              })
            }
          />
          <ManagerMaintenanceList
            maintenanceArray={maintenanceArray}
            setMaintenanceArray={setMaintenanceArray}
          />
          <ManagerCanEnterList
            canEnterArray={canEnterArray}
            setCanEnterArray={setCanEnterArray}
          />
          <ManagerCanApproveList
            canApproveArray={canApproveArray}
            setCanApproveArray={setCanApproveArray}
          />
          <ManagerPatrolList
            patrolArray={patrolArray}
            setPatrolArray={setPatrolArray}
          />
          <input
            type="text"
            className="mokedWorkerInput"
            value={siteInformationData.frequency}
            placeholder="תדירות סיור"
            onChange={(e) =>
              setSiteInformationData({
                ...siteInformationData,
                frequency: e.target.value,
              })
            }
          />
          <input
            type="text"
            className="mokedWorkerInput"
            value={siteInformationData.gate}
            placeholder="שערים"
            onChange={(e) =>
              setSiteInformationData({
                ...siteInformationData,
                gate: e.target.value,
              })
            }
          />
          <input
            type="text"
            className="mokedWorkerInput"
            value={siteInformationData.alarm}
            placeholder="כריזה"
            onChange={(e) =>
              setSiteInformationData({
                ...siteInformationData,
                alarm: e.target.value,
              })
            }
          />
          <input
            type="text"
            className="mokedWorkerInput"
            value={siteInformationData.technology}
            placeholder="טכנולוגיה"
            onChange={(e) =>
              setSiteInformationData({
                ...siteInformationData,
                technology: e.target.value,
              })
            }
          />
          <input
            type="text"
            className="mokedWorkerInput"
            value={siteInformationData.location}
            placeholder="מיקום"
            onChange={(e) =>
              setSiteInformationData({
                ...siteInformationData,
                location: e.target.value,
              })
            }
          />
          <ManagerSideNoteList
            sideNoteArray={sideNoteArray}
            setSideNoteArray={setSideNoteArray}
          />
          <input
            type="text"
            className="mokedWorkerInput"
            value={siteInformationData.mokedType}
            placeholder="אשכול \ נתיבות"
            onChange={(e) =>
              setSiteInformationData({
                ...siteInformationData,
                mokedType: e.target.value,
              })
            }
          />
          <h4>סוג מוקד</h4>
          <div className="mokedTypeMangerInfo">
            <input
              type="checkbox"
              checked={siteInformationData.type === "big"}
              className="mokedTypeMangerInfoCheckbox"
              value="big"
              onChange={(e) =>
                setSiteInformationData({
                  ...siteInformationData,
                  type: e.target.value,
                })
              }
            />
            <p className="mokedTypeMangerInfoLabel">מוקד גדול</p>
            <input
              type="checkbox"
              checked={siteInformationData.type === "small"}
              className="mokedTypeMangerInfoCheckbox"
              value="small"
              onChange={(e) =>
                setSiteInformationData({
                  ...siteInformationData,
                  type: e.target.value,
                })
              }
            />
            <p className="mokedTypeMangerInfoLabel">מוקד קטן</p>
          </div>
        </div>
        <div className="buttonWrapperUserClient">
          <input
            type="submit"
            className="BottomFormMokedWorkersButton editCRUDButton"
            value="ערוך אתר"
          />
        </div>
      </form>
    </div>
  );
};

const DeleteSiteInformationCRUD = ({
  setSiteInfoUpdater,
  sitesInformationName,
  siteNameValue,
  setSiteNameValue,
  sitesInformation,
}) => {
  const dispatch = useDispatch();

  const chosenSiteInformation = sitesInformation.find(
    (site) => site.siteName === siteNameValue
  );

  const handleSubmit = (e) => {
    e.preventDefault();

    dispatch(DeleteSiteInformation(chosenSiteInformation._id));

    Clear();
  };

  const Clear = () => {
    setSiteNameValue("");

    setSiteInfoUpdater((prev) => !prev);
  };

  return (
    <div className="clientUserFormContainer">
      <div className="EditMokedWorkerCRUDTop">
        <ManagerSiteInformationName
          sitesInformationName={sitesInformationName}
          siteNameValue={siteNameValue}
          setSiteNameValue={setSiteNameValue}
        />
      </div>
      <form className="mokedWorkerForm" onSubmit={handleSubmit}>
        <div className="buttonWrapperUserClient">
          <input
            type="submit"
            className="BottomFormMokedWorkersButton deleteCRUDButton"
            value="מחק אתר"
          />
        </div>
      </form>
    </div>
  );
};
