import React, { useEffect, useState } from "react";
import { io } from "socket.io-client";

function useSocket() {
  const [socket, setSocket] = useState();

  useEffect(() => {
    const s = io("/", { options: { secure: true } });
    // const s = io("http://localhost:5001/");
    setSocket(s);

    return () => {
      s.disconnect();
    };
  }, []);
  return {
    socket,
  };
}

export default useSocket;
