import { CREATE_MALFUNCTION_ARCHIVE, FETCH_MALFUNCTION_ARCHIVE } from "../constants/actionTypes"

const MalfunctionArchiveReducer = ( malfunctionArchive = [], action ) => {
    switch (action.type) {
        case CREATE_MALFUNCTION_ARCHIVE:
            return [...malfunctionArchive, action.payload]

        case FETCH_MALFUNCTION_ARCHIVE:
            return action.payload
    
        default:
            return malfunctionArchive
    }
}

export default MalfunctionArchiveReducer