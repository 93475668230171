
import { useState, useEffect } from "react"
import { useDispatch } from "react-redux"

import { UpdateSiteInformation } from "../../../actions/siteInformation"
import ManagerSiteInformationName from "./ManagerSiteInformationName/ManagerSiteInformationName"
import ManagerUrgentList from "./ManagerUrgentList/ManagerUrgentList"
import ManagerNotUrgentList from "./ManagerNotUrgentList/ManagerNotUrgentList"
import ManagerEmailListList from "./ManagerEmailListList/ManagerEmailListList"
import ManagerGroupNotes from "./ManagerGroupNotes/ManagerGroupNotes"
import { UpdateFarmsInformation } from "../../../actions/FarmsInformation"


export default function ManagerFarmsMalfunction({ chosenCURDType, setFarmInfoUpdater, farmsInformation }) {

const [ siteNameValue, setSiteNameValue ] = useState("")

const farmsInformationName = farmsInformation.map((farm) => farm.siteName)

return(
    <>
    { chosenCURDType && <div className="siteInformationSection">
      <div className="siteInformationWrapper">
        <div className="mokedWorkerRight">
          <h1 className="mokedWorkerTitle">{chosenCURDType}</h1>
        </div>
          <div className="siteInformationContainer">
            {chosenCURDType === "רישום" ?
            <CreateSiteMalfunctionCRUD />
            : chosenCURDType === "עריכה" ?
            <EditSiteMalfunctionCRUD farmsInformation={farmsInformation} setFarmInfoUpdater={setFarmInfoUpdater}
            siteNameValue={siteNameValue} setSiteNameValue={setSiteNameValue} farmsInformationName={farmsInformationName} />
             :
             <DeleteSiteMalfunctionCRUD />
            }
          </div>
        </div>
      </div>}
      </>
    )
}

const CreateSiteMalfunctionCRUD = () => {


    return(
            <div>
                    <form className="mokedWorkerForm">
                    <div className="siteInformationContainerTop">
                        <h1>באזור זה ניתן רק לערוך</h1>
                    </div>
                    <div className="buttonWrapperUserClient">
                    </div>
                </form>
            </div>
    )
    
    }

const EditSiteMalfunctionCRUD = ({ farmsInformationName, siteNameValue, setSiteNameValue, farmsInformation, setFarmInfoUpdater }) => {

    const dispatch = useDispatch();

    const chosenSiteInformation = farmsInformation.find(site => site.siteName === siteNameValue)

    const [ urgent, setUrgent ] = useState([])
    const [ notUrgent, setNotUrgent ] = useState([])
    const [ emailList, setEmailList ] = useState([])
    const [ malfunctions, setMalfunctions ] = useState({
        urgent: [],
        notUrgent: [],
        emailList: [],
      })


    const [siteInformationData, setSiteInformationData] = useState({
        siteName: '',
        manbat: '',
        manager: '',
        system: '',
        connected: '',
        password: '',
        patrol: [],
        security: '',
        sideNote: [],
        gate: '',
        malfunctions: [{
          urgent: [],
          notUrgent: [],
          emailList: [],
        }] 
    })

    useEffect(() => {

        if(chosenSiteInformation){

        setSiteInformationData( 
            chosenSiteInformation
         )

         if(chosenSiteInformation.malfunctions[0]){
            setUrgent(chosenSiteInformation.malfunctions[0].urgent)
            setNotUrgent(chosenSiteInformation.malfunctions[0].notUrgent)
            setEmailList(chosenSiteInformation.malfunctions[0].emailList)
         }

    }

    }, [chosenSiteInformation])

    useEffect(() => {

        setMalfunctions(prev => ({
            ...prev,
            urgent: urgent,
            notUrgent: notUrgent,
            emailList: emailList,
        }))

    }, [ urgent, notUrgent, emailList ])

    useEffect(() => {

        setSiteInformationData((prev) => ({
            ...prev,
            malfunctions: malfunctions
        }))

    }, [ malfunctions ])

    const handleSubmit = (e) => {
        e.preventDefault()

        if(chosenSiteInformation){

            dispatch(UpdateFarmsInformation(chosenSiteInformation._id, siteInformationData))
      
            Clear()
        }
        
      }
      
      const Clear = () => {
      
        setSiteInformationData({
            siteName: '',
            manbat: '',
            manager: '',
            system: '',
            connected: '',
            password: '',
            patrol: [],
            security: '',
            sideNote: [],
            gate: '',
            malfunctions: [{
              urgent: [],
              notUrgent: [],
              emailList: [],
            }] 
        })

        setUrgent([])
        setNotUrgent([])
        setEmailList([])

        setMalfunctions({
            urgent: [],
            notUrgent: [],
            emailList: [],
        })

        setSiteNameValue("")

        setFarmInfoUpdater(prev => !prev)
    
      }  
    
    return(
        <div>
        <form className="mokedWorkerForm" onSubmit= {handleSubmit}>
            <div className="siteInformationContainerTop">
                <ManagerSiteInformationName sitesInformationName={farmsInformationName} siteNameValue={siteNameValue} setSiteNameValue={setSiteNameValue}/>
                <ManagerUrgentList urgent={urgent} setUrgent={setUrgent} />
                <ManagerNotUrgentList notUrgent={notUrgent} setNotUrgent={setNotUrgent} />
                <ManagerEmailListList emailList={emailList} setEmailList={setEmailList} />
            </div>
            <div className="buttonWrapperUserClient">
                <input type="submit" className="BottomFormMokedWorkersButton editCRUDButton" value="ערוך חווה" />
            </div>
        </form>
        </div>
    )
}

const DeleteSiteMalfunctionCRUD = () => {
    
    return(
            <div className="clientUserFormContainer">
                <div className="EditMokedWorkerCRUDTop">
                    <h1>באזור זה ניתן רק לערוך</h1>
                </div>
            </div>
    )
}