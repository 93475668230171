
import { CREATE_NEW_SHIFTPAGETYPE, FETCH_ALL_SHIFTPAGETYPES, UPDATE_SHIFTPAGETYPE, DELETE_SHIFTPAGETYPE } from "../constants/actionTypes"

const shiftPageTypeReducer = ( shiftPageType = [], action ) => {

    switch (action.type) {

        case CREATE_NEW_SHIFTPAGETYPE:
            return [...shiftPageType, action.payload]
        
        case FETCH_ALL_SHIFTPAGETYPES:
            return action.payload
                
        case UPDATE_SHIFTPAGETYPE:
            return shiftPageType.map((e) => e._id === action.payload._id ? action.payload : e)
        
        case DELETE_SHIFTPAGETYPE:
            return shiftPageType.filter((e) => e._id !== action.payload)
            
        default:
            return shiftPageType

    }
}
        
export default shiftPageTypeReducer
        