import { AUTH, AUTH_SIGNUP, AUTH_EDIT_SIGNUP, LOGOUT } from "../constants/actionTypes"

const authReducer = (state = { authData: null }, action) => {
    switch (action.type) {
        case AUTH:
            localStorage.setItem("profile", JSON.stringify({ ...action?.data }))
            return { ...state, authData: action?.data }
        
        case AUTH_SIGNUP:
            return { ...state, authData: action?.data }

        case AUTH_EDIT_SIGNUP:
            return { ...state, authData: action?.data }

        case LOGOUT:
            localStorage.clear()
            return { ...state, authData: null }

        default:
        return state

  }
}

export default authReducer
