import React, { useEffect, useState } from "react";

function Loader() {
  const [time, setTime] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setTime((prevTime) => (prevTime + 0.05) % 2);
    }, 30);
    return () => clearInterval(timer);
  }, []);

  const rings = [0, 1, 2];
  const angles = [0, 60, 120, 180, 240, 300];

  const hexagonPath = "M0,-10 L8.66,-5 L8.66,5 L0,10 L-8.66,5 L-8.66,-5 Z";

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "90vh",
        width: "100%",
      }}
    >
      <svg width="350" height="350" viewBox="-60 -60 120 120">
        {rings.map((ring) =>
          angles.map((angle, index) => {
            const delay = (ring * 6 + index) * 0.1;
            const t = (time + 2 - delay) % 2;
            const scale = 0.8 + 0.2 * Math.sin(Math.PI * t);
            const opacity = Math.sin(Math.PI * t);

            return (
              <path
                key={`${ring}-${index}`}
                d={hexagonPath}
                fill="none"
                stroke="rgba(106, 156, 31, 0.7)"
                strokeWidth="1"
                transform={`rotate(${angle}) translate(0, -${
                  20 + ring * 15
                }) scale(${scale})`}
                style={{
                  opacity: opacity,
                }}
              />
            );
          })
        )}
        <circle
          cx="0"
          cy="0"
          r="5"
          fill="rgba(106, 156, 31)"
          style={{
            animation: "ping 1s cubic-bezier(0, 0, 0.2, 1) infinite",
          }}
        />
      </svg>
      <style jsx={"true"}>{`
        @keyframes ping {
          75%,
          100% {
            transform: scale(2);
            opacity: 0;
          }
        }
      `}</style>
    </div>
  );
}

export default Loader;
