import React from 'react'

export default function FinishedTransformsInformation({filteredFinishedTransformsDateSorted}) {

    return (
        <div className="EntryInformation">
            <ul className="EntryInformationList">
                <li className="EntryInformationItem">
                    { "נמצאו " + Object.keys(filteredFinishedTransformsDateSorted).length + " טפסי שינוע"}
                </li>
            </ul>
        </div>
    )
}
