
import useAutocomplete from "@material-ui/lab/useAutocomplete"

export default function ChosenDriverName({ driversNameArray, chosenDriverName, setChosenDriverName}) {

    const { getRootProps, getInputProps, getListboxProps, getOptionProps, groupedOptions} = useAutocomplete({
        value: chosenDriverName || null,
        onChange:(event, newValue) => {setChosenDriverName(newValue)},
        matchFrom: "start",
        openOnFocus: true,
        options: driversNameArray,
        getOptionLabel: (option) => option
      })     

      return (
        <div >
          <div  {...getRootProps()}>
            <div className="siteNameInput">
                <input 
                className="entryFormInput entryFormInputEmployList" 
                placeholder="הכנס את שם האתר המבוקש"

                {...getInputProps()} />
            
            {groupedOptions.length > 0 ? (
                <ul className="siteNameList" {...getListboxProps()}>
                {groupedOptions.map((option, index) => (
                    <li className="siteNameListItem" {...getOptionProps({ option, index })}>{option}</li>
                ))}
                </ul>
            ) : null}
            </div>
          </div>
        </div>
      );
    }