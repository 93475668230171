import "./managerPlaceMiddle.css";

import MokedWorkers from "../MokedWorkers/MokedWorkers";
import ClientUsers from "../ClientUsers/ClientUsers";
import ManagerSiteInformation from "../ManagerSiteInformation/ManagerSiteInformation";
import ManagerSiteMalfunction from "../ManagerSiteMalfunction/ManagerSiteMalfunction";
import MalfunctionType from "../MalfunctionType/MalfunctionType";
import PopupMessages from "../PopUpMessages/PopUpMessages";
import MokedScansSchema from "../MokedScansSchema/MokedScansSchema";
import ShiftPageType from "../ShiftPageType/ShiftPageType";
import ImageUploader from "../ImageUploader/ImageUploader";
import CheckListEvent from "../CheckListEvent/CheckListEvent";
import ManagerFarmsInformation from "../ManagerFarmsInformation/ManagerFarmsInformation";
import ManagerFarmsMalfunction from "../ManagerFarmsMalfunction/ManagerFarmsMalfunction";
import { useMemo } from "react";
import ManagerOtherSiteInformation from "../ManagerOtherSitesInformation/ManagerOtherSitesInformation";

export default function ManagerPlaceMiddle({
  imageList,
  shiftPageType,
  sitesInformationNameAndType,
  chosenManagerPlace,
  chosenCURDType,
  setChosenCURDType,
  fetchMokedWorkers,
  mokedScansSchemaData,
  setMokedWorkerUpdater,
  setUserClientUpdater,
  setSiteInfoUpdater,
  fetchUserClient,
  sitesInformationName,
  sitesInformation,
  setMokedScansSchemaUpdater,
  setMalfunctionTypeUpdater,
  malfunctionType,
  setFarmInfoUpdater,
  farmsInformation,
  otherSiteInformation,
  setOtherSiteInfoUpdater,
}) {
  const params = useMemo(() => {
    return {
      mokedWorkers: {
        chosenCURDType,
        fetchMokedWorkers,
        setMokedWorkerUpdater,
      },
      popupMessages: {
        chosenCURDType,
        fetchMokedWorkers,
        setMokedWorkerUpdater,
      },
      generalInfo: {
        chosenCURDType,
        setUserClientUpdater,
        fetchMokedWorkers,
        fetchUserClient,
        sitesInformationName,
        sitesInformation,
        setSiteInfoUpdater,
      },
      farmsInfo: {
        chosenCURDType,
        farmsInformation,
        setFarmInfoUpdater,
      },
      otherSiteInfo: {
        chosenCURDType,
        otherSiteInformation,
        setOtherSiteInfoUpdater,
      },
      malfunctions: {
        chosenCURDType,
        setUserClientUpdater,
        fetchMokedWorkers,
        fetchUserClient,
        sitesInformationName,
        sitesInformation,
        setSiteInfoUpdater,
      },
      farmsInfoMalfunctions: {
        chosenCURDType,
        farmsInformation,
        setFarmInfoUpdater,
      },
      siteUsers: {
        chosenCURDType,
        setUserClientUpdater,
        fetchMokedWorkers,
        fetchUserClient,
        sitesInformationName,
      },
      mokedScansSchema: {
        chosenCURDType,
        setMokedScansSchemaUpdater,
        mokedScansSchemaData,
        sitesInformationNameAndType,
      },
      shiftPageType: {
        chosenCURDType,
        shiftPageType,
      },
      ImageUploader: {
        chosenCURDType,
        imageList,
      },
      checkListEvents: {
        chosenCURDType,
        imageList,
      },
      malfunctionType: {
        chosenCURDType,
        setUserClientUpdater,
        fetchMokedWorkers,
        fetchUserClient,
        sitesInformationName,
        setMalfunctionTypeUpdater,
        malfunctionType,
      },
    };
  }, [
    chosenCURDType,
    fetchMokedWorkers,
    setMokedWorkerUpdater,
    setUserClientUpdater,
    fetchUserClient,
    sitesInformationName,
    sitesInformation,
    setSiteInfoUpdater,
    farmsInformation,
    setFarmInfoUpdater,
    mokedScansSchemaData,
    sitesInformationNameAndType,
    shiftPageType,
    imageList,
    malfunctionType,
  ]);
  return (
    <div className="managerPlaceMiddleContainer">
      <div className="managerPlaceMiddleWrapper">
        {chosenManagerPlace ? (
          <div className="managerPlaceMiddleTopButtons fadeInDown">
            <button
              className="TopCRUDButton enterCRUDButton fadeIn first"
              onClick={() => setChosenCURDType("רישום")}
            >
              יצירה
            </button>
            <button
              className="TopCRUDButton editCRUDButton fadeIn second"
              onClick={() => setChosenCURDType("עריכה")}
            >
              עריכה
            </button>
            <button
              className="TopCRUDButton deleteCRUDButton fadeIn third"
              onClick={() => setChosenCURDType("מחיקה")}
            >
              מחיקה
            </button>
          </div>
        ) : (
          <>
            <h1 className="managerWelcome">
              שלום מאיה, ברוכה הבאה לאזור האישי שלך
            </h1>
            <h2 className="managerWelcome">
              את מוזמנת לבחור קטגוריה בסרגל מצד ימין לתחילת עבודה
            </h2>
          </>
        )}
        <div className="managerPlaceMiddleComponent">
          {chosenManagerPlace === "mokedWorkers" ? (
            chosenCURDType && <MokedWorkers {...params.mokedWorkers} />
          ) : chosenManagerPlace === "popupMessages" ? (
            chosenCURDType && <PopupMessages {...params.popupMessages} />
          ) : chosenManagerPlace === "generalInfo" ? (
            <ManagerSiteInformation {...params.generalInfo} />
          ) : chosenManagerPlace === "farmsInfo" ? (
            <ManagerFarmsInformation {...params.farmsInfo} />
          ) : chosenManagerPlace === "otherSiteInfo" ? (
            <ManagerOtherSiteInformation {...params.otherSiteInfo} />
          ) : chosenManagerPlace === "malfunctions" ? (
            <ManagerSiteMalfunction {...params.malfunctions} />
          ) : chosenManagerPlace === "farmsInfoMalfunctions" ? (
            <ManagerFarmsMalfunction {...params.farmsInfoMalfunctions} />
          ) : chosenManagerPlace === "siteUsers" ? (
            <ClientUsers {...params.siteUsers} />
          ) : chosenManagerPlace === "mokedScansSchema" ? (
            <MokedScansSchema {...params.mokedScansSchema} />
          ) : chosenManagerPlace === "shiftPageType" ? (
            <ShiftPageType {...params.shiftPageType} />
          ) : chosenManagerPlace === "ImageUploader" ? (
            <ImageUploader {...params.ImageUploader} />
          ) : chosenManagerPlace === "checkListEvents" ? (
            <CheckListEvent {...params.checkListEvents} />
          ) : chosenManagerPlace === "malfunctionType" ? (
            <MalfunctionType {...params.malfunctionType} />
          ) : null}
        </div>
      </div>
    </div>
  );
}
