import React, { useState } from "react";
import ScoutTypeLayout from "../ScoutTypeLayout";
import MultiChoiseInput from "../../../components/MultiChoiseInput/MultiChoiseInput";

function JumpScout(props) {
  const {
    setScoutType,
    handleScoutSubmit,
    sitesInformation,
    mokedWorkersName,
  } = props;

  const [siteNameValue, setSiteNameValue] = useState("");
  const [mokedWorkerValue, setMokedWorkerValue] = useState("");
  const [scoutForm, setScoutForm] = useState({
    type: "jumpScout",
    siteName: "",
    mokedWorker: "",
    scouter: "",
    jumpReason: "",
    sideNote: "",
  });

  const params = {
    siteName: {
      options: sitesInformation,
      chooseBy: "siteName",
      placeholder: "הכנס את שם האתר המבוקש",
      value: siteNameValue,
      setValue: setSiteNameValue,
    },
    mokedWorker: {
      options: mokedWorkersName,
      chooseBy: "userName",
      placeholder: "הכנס את שמך",
      value: mokedWorkerValue,
      setValue: setMokedWorkerValue,
    },
    scouterName: {
      type: "text",
      placeholder: "הכנס את שם הסייר",
      className: "ScoutFormInput",
      value: scoutForm.scouter,
      onChange: (e) =>
        setScoutForm((prev) => ({ ...prev, scouter: e.target.value })),
    },
    jumpReason: {
      type: "text",
      placeholder: "הכנס סיבת הקפצה",
      className: "ScoutFormInput",
      value: scoutForm.jumpReason,
      onChange: (e) =>
        setScoutForm((prev) => ({ ...prev, jumpReason: e.target.value })),
    },
    sideNote: {
      type: "text",
      placeholder: "הוסף הערה לסיור",
      className: "ScoutFormInput",
      value: scoutForm.sideNote,
      onChange: (e) =>
        setScoutForm((prev) => ({ ...prev, sideNote: e.target.value })),
    },
  };

  const reset = () => {
    setMokedWorkerValue("");
    setSiteNameValue("");
    setScoutForm({
      type: "jumpScout",
      siteName: "",
      mokedWorker: "",
      scouter: "",
      jumpReason: "",
      sideNote: "",
    });
  };

  const formCheck = (form) => {
    if (!form.siteName) {
      alert("יש לבחור את שם האתר");
      return false;
    }
    if (!form.mokedWorker) {
      alert("יש לבחור את שם המוקדן");
      return false;
    }
    if (!form.scouter) {
      alert("יש להכניס את שם הסייר");
      return false;
    }
    if (!form.jumpReason) {
      alert("יש להכניס את סיבת הקפצה");
      return false;
    }
    return true;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formToSubmit = {
      ...scoutForm,
      siteName: siteNameValue,
      mokedWorker: mokedWorkerValue,
    };
    if (!formCheck(formToSubmit)) return;
    handleScoutSubmit(formToSubmit);
    reset();
  };

  return (
    <ScoutTypeLayout setScoutType={setScoutType}>
      <h2>סיור הקפצה</h2>
      <form className="ScoutTypeForm" onSubmit={handleSubmit}>
        <label className="ScoutTypeFormLabel">
          שם האתר:
          <MultiChoiseInput {...params.siteName} />
        </label>
        <label className="ScoutTypeFormLabel">
          שם המוקדן:
          <MultiChoiseInput {...params.mokedWorker} />
        </label>
        <label className="ScoutTypeFormLabel">
          שם הסייר:
          <input {...params.scouterName} />
        </label>
        <label className="ScoutTypeFormLabel">
          סיבת הקפצה:
          <input {...params.jumpReason} />
        </label>
        <label className="ScoutTypeFormLabel">
          הערות:
          <input {...params.sideNote} />
        </label>
        <button type="submit" className="ScoutTypeFormButton">
          צור סיור
        </button>
      </form>
    </ScoutTypeLayout>
  );
}

export default JumpScout;
