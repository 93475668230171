import "./topBarDynamic.css";

import { useState, useEffect } from "react";

import { Link, useLocation } from "react-router-dom";

import { CSSTransition } from "react-transition-group";

import { ReactComponent as Document } from "../SVG/documentSVG.svg";
import { ReactComponent as MagnifyingGlass } from "../SVG/magnifyingGlassSVG.svg";
import { ReactComponent as Error } from "../SVG/errorSVG.svg";
import { ReactComponent as PinPoint } from "../SVG/pinPointSVG.svg";
import { ReactComponent as User } from "../SVG/userSVG.svg";
import { ReactComponent as ArrowDown } from "../SVG/arrowDownSVG.svg";
import { ReactComponent as BackArrow } from "../SVG/backArrowSVG.svg";
import { ReactComponent as InOut } from "../SVG/inOutSVG.svg";
import { ReactComponent as Documents } from "../SVG/documentsSVG.svg";
import { ReactComponent as Errors } from "../SVG/errorsSVG.svg";
import { ReactComponent as Archive } from "../SVG/archiveSVG.svg";
import { ReactComponent as ControlCenter } from "../SVG/controlCenterSVG.svg";

export default function Topbar() {
  const location = useLocation();

  const [user, setUser] = useState(JSON.parse(localStorage.getItem("profile")));

  const [openMenu, setOpenMenu] = useState(false);

  useEffect(() => {
    setUser(JSON.parse(localStorage.getItem("profile")));
  }, [location]);

  return (
    <NavBar>
      <div className="topbarLogo">
        <Link to="/">
          <img src="/assets/AsSomlogo.png" alt="as./som" className="logoImg" />
        </Link>
      </div>
      {user && (
        <NavItem
          openMenu={openMenu}
          setOpenMenu={setOpenMenu}
          icon={<ArrowDown />}
        >
          <DropDownMenu setOpenMenu={setOpenMenu} />
        </NavItem>
      )}
    </NavBar>
  );
}

const NavBar = (props) => {
  return (
    <nav className="navbar">
      <ul className="navbar-nav">{props.children}</ul>
    </nav>
  );
};

const NavItem = (props) => {
  return (
    <li className="nav-item">
      <div
        className="icon-button"
        onClick={() => props.setOpenMenu(!props.openMenu)}
      >
        {props.icon}
      </div>

      {props.openMenu && props.children}
    </li>
  );
};

const DropDownMenu = (props) => {
  const [activeMenu, setActiveMenu] = useState("main");
  const [menuHeight, setMenuHeight] = useState(null);

  const setOpenMenu = props.setOpenMenu;

  const calcHeight = (el) => {
    const height = el.offsetHeight;
    setMenuHeight(height);
  };

  const user = JSON.parse(localStorage.getItem("profile"));

  let moked = "";

  if (user) {
    if (user.result.userName === "מנהל" || user.result?.isMoked) {
      moked = user.result.userName;
    }
  }

  const DropDownItem = (props) => {
    return (
      <>
        {props.toPage ? (
          <Link
            className="dropDownItem"
            onClick={() => {
              props.goToMenu && setActiveMenu(props.goToMenu);
              setOpenMenu(false);
            }}
            to={props.toPage}
          >
            <span className="icon-button menuIcon">{props.rightIcon}</span>

            {props.children}

            <span className="icon-left menuIconLeft">{props.leftIcon}</span>
          </Link>
        ) : (
          <div
            className="dropDownItem"
            onClick={() => props.goToMenu && setActiveMenu(props.goToMenu)}
          >
            <span className="icon-button menuIcon">{props.rightIcon}</span>

            {props.children}

            <span className="icon-left menuIconLeft">{props.leftIcon}</span>
          </div>
        )}
      </>
    );
  };

  return (
    <div className="dropDown" style={{ height: menuHeight }}>
      <CSSTransition
        in={activeMenu === "main"}
        unmountOnExit
        timeout={500}
        classNames="menu-primary"
        onEnter={calcHeight}
      >
        <div className="menu">
          {user && (
            <DropDownItem rightIcon={<User />} toPage={"/AuthSignIn"}>
              {user.result.userName}
            </DropDownItem>
          )}
          <hr className="menuHr" />
          {moked ? (
            <>
              {user.result.userName === "מנהל" && (
                <DropDownItem
                  rightIcon={<ControlCenter />}
                  toPage={"/managerPlace"}
                >
                  מרכז שליטה
                </DropDownItem>
              )}
              <DropDownItem rightIcon={<PinPoint />} toPage={"/CheckList"}>
                צ׳ק ליסט אירועים
              </DropDownItem>
              <DropDownItem
                rightIcon={<Documents />}
                toPage={"/farmsInformation"}
              >
                צ׳ק ליסט חוות קנאביס
              </DropDownItem>
              <DropDownItem
                rightIcon={<Documents />}
                toPage={"/otherSitesInformation"}
              >
                צ׳ק ליסט מתקנים אחרים
              </DropDownItem>
              <DropDownItem rightIcon={<Documents />} toPage={"/scouts"}>
                סיורים
              </DropDownItem>
              <DropDownItem
                rightIcon={<MagnifyingGlass />}
                toPage={"/imageList"}
              >
                הרשאות כניסה
              </DropDownItem>
              <DropDownItem rightIcon={<InOut />} toPage={"/"}>
                כניסות ויציאות
              </DropDownItem>
              <DropDownItem rightIcon={<Document />} toPage={"/transforms"}>
                שינועים
              </DropDownItem>
              <DropDownItem rightIcon={<Error />} toPage={"/Malfunctions"}>
                דו"ח תקלות
              </DropDownItem>
              <DropDownItem rightIcon={<Archive />} goToMenu="archive">
                ארכיון
              </DropDownItem>
            </>
          ) : (
            <>
              {user && (
                <DropDownItem
                  rightIcon={<MagnifyingGlass />}
                  toPage={`/ClientEntries/${user.result.userName}`}
                >
                  כניסות לאתרים
                </DropDownItem>
              )}
              {user.result.isTransform && (
                <>
                  <DropDownItem rightIcon={<Document />} toPage={"/transforms"}>
                    שינועים
                  </DropDownItem>
                  <DropDownItem
                    rightIcon={<Documents />}
                    toPage={"/TransformsForms"}
                  >
                    טפסי שינוע
                  </DropDownItem>
                </>
              )}
              <DropDownItem
                rightIcon={<Errors />}
                toPage={`/MalfunctionArchive`}
              >
                דו"ח תקלות
              </DropDownItem>
            </>
          )}
        </div>
      </CSSTransition>

      <CSSTransition
        in={activeMenu === "archive"}
        unmountOnExit
        timeout={500}
        classNames="menu-secondary"
        onEnter={calcHeight}
      >
        <div className="menu">
          <DropDownItem rightIcon={<BackArrow />} goToMenu="main">
            <p className="archiveTitle">ארכיון</p>
          </DropDownItem>
          {user && (
            <DropDownItem
              rightIcon={<MagnifyingGlass />}
              toPage={`/ClientEntries/${user.result.userName}`}
            >
              כניסות לאתרים
            </DropDownItem>
          )}
          <DropDownItem rightIcon={<Documents />} toPage={"/TransformsForms"}>
            טפסי שינוע
          </DropDownItem>
          <DropDownItem rightIcon={<Errors />} toPage={`/MalfunctionArchive`}>
            תקלות סגורות
          </DropDownItem>
          <DropDownItem rightIcon={<Errors />} toPage={`/CloseScouts`}>
            סיורים
          </DropDownItem>
        </div>
      </CSSTransition>
    </div>
  );
};
