import "./transformsFormsRight.css"

import TransformsForms from "./TransformsFormsForm/TransformsFormsForm"
import ActiveTransforms from "./ActiveTransforms/ActiveTransforms"

export default function TransformsFormsRight({ moked, FetchActiveTransforms, setTransformId, transformsFormIdToEdit, setTransformsFormIdToEdit, isAlarmOpen, setIsAlarmOpen, setTypeAlarmOpen, mokedWorkersName }) {

    return (
        <div className="TransformsFormsRightContainer">
            <div className="TransformsFormsRightTop">
                {moked && <TransformsForms transformsFormIdToEdit={transformsFormIdToEdit} setTransformsFormIdToEdit={setTransformsFormIdToEdit}
                 isAlarmOpen={isAlarmOpen} setIsAlarmOpen={setIsAlarmOpen} setTransformId={setTransformId} setTypeAlarmOpen={setTypeAlarmOpen}
                 mokedWorkersName={mokedWorkersName} />}
            </div>
            <div className="TransformsFormsRightBottom">
                {Object.keys(FetchActiveTransforms).length > 0 ? FetchActiveTransforms.map((form, index) => {
                return(
                <ActiveTransforms key={index} form={form} setTransformId={setTransformId} setTransformsFormIdToEdit={setTransformsFormIdToEdit} />
                )}) : 
                <h2>אין שינועים פעילים כרגע</h2>
                }
            </div>
        </div>
    )
}
