import { CREATE_NEW_CHAT_MESSAGE, FETCH_ALL_CHAT_MESSAGE, UPDATE_CHAT_MESSAGE, DELETE_CHAT_MESSAGE } from "../constants/actionTypes"

const ChatMessageReducer = ( chatMessage = [], action ) => {
    switch (action.type) {
        case CREATE_NEW_CHAT_MESSAGE:
            return [...chatMessage, action.payload]

        case FETCH_ALL_CHAT_MESSAGE:
            return action.payload
        
        case UPDATE_CHAT_MESSAGE:
            return chatMessage.map((chatInfo) => chatInfo._id === action.payload._id ? action.payload : chatInfo)

        case DELETE_CHAT_MESSAGE:
            return chatMessage.filter((chatInfo) => chatInfo._id !== action.payload)
    
        default:
            return chatMessage
    }
}

export default ChatMessageReducer