import "./malfunctionDateFilter.css"
import MalfunctionDatePicker from "./malfunctionDatePicker"

export default function MalfunctionDateFilter({ startDate, setStartDate, endDate, setEndDate, errors, setErrors }) {

    return (
        <div className="malfunctionDateFilter">
            <div className="malfunctionDateFilterInner">
                <MalfunctionDatePicker startDate={startDate} setStartDate={setStartDate} 
                endDate={endDate} setEndDate={setEndDate} 
                errors={errors} setErrors={setErrors}/>
            </div>
        </div>
    )
}
