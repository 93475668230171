import { CREATE_NEW_SHIFT_PAGE, FETCH_ALL_SHIFT_PAGE, UPDATE_SHIFT_PAGE, DELETE_SHIFT_PAGE } from "../constants/actionTypes"

import * as api from "../api"

export const CreateShiftPage = (newShiftPage) => async (dispatch) => {

  try {

    const { data } = await api.createShiftPage(newShiftPage)

    dispatch({ type: CREATE_NEW_SHIFT_PAGE, payload: data })
    
  } catch (error) {

    console.log(error)
    
  }
}

export const FetchAllShiftPage = () => async (dispatch) => {
  try {

    const { data } = await api.fetchAllShiftPage()

    dispatch({ type: FETCH_ALL_SHIFT_PAGE, payload: data })
    
  } catch (error) {

    console.log(error)
    
  }
}

export const UpdateShiftPage = (id, updatedShiftPage) => async (dispatch) => {
  try {

    const { data } = await api.updateShiftPage(id, updatedShiftPage)

    dispatch({ type: UPDATE_SHIFT_PAGE, payload: data })
    
  } catch (error) {

    console.log(error)
    
  }
}

export const DeleteShiftPage = (id) => async (dispatch) => {
  try {

    await api.deleteShiftPage(id)

    dispatch({ type: DELETE_SHIFT_PAGE, payload: id})
    
  } catch (error) {

    console.log(error)
    
  }
}