import { CREATE_MOKED_WORKER, FETCH_USER_CLIENTS, UPDATE_USER_CLIENT, DELETE_USER_CLIENT } from "../constants/actionTypes"

const userClientReducer = ( userClient = [], action ) => {
    switch (action.type) {
        case CREATE_MOKED_WORKER:
            return [...userClient, action.payload]

        case FETCH_USER_CLIENTS:
            return action.payload
        
        case UPDATE_USER_CLIENT:
            return userClient.map((user) => user._id === action.payload._id ? action.payload : user)

        case DELETE_USER_CLIENT:
            return userClient.filter((user) => user._id !== action.payload)
    
        default:
            return userClient
    }
}

export default userClientReducer