import "./checkListEvent.css"

import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import { UpdateCheckListEvent, FetchAllCheckListEvents } from "../../../actions/CheckListEvent"
import CheckListList from "./CheckListList/CheckListList"
import CheckListDisplay from "./CheckListDisplay"

export default function CheckListEvent({ chosenCURDType}) {

    const [ checkListEvent, setCheckListEvent ] = useState({
        title: '',
        subTitle: '',
        orders: []
    })

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(FetchAllCheckListEvents())
        
    }, [dispatch])

    const checkList = useSelector((state) => state.checkListEvent)

return(
    <>
    { chosenCURDType && <div className="siteInformationSection">
      <div className="siteInformationWrapper">
            {chosenCURDType === 'עריכה' 
            ?
            <div className="checkListEventRight">
                <CheckListDisplay checkListEvent={checkListEvent} setCheckListEvent={setCheckListEvent} />
            </div>
            :
            <div className="mokedWorkerRight">
                <h1 className="mokedWorkerTitle">{chosenCURDType}</h1>
            </div>
            }
          <div className="siteInformationContainer">
            {chosenCURDType === "רישום" ?
            <CreateSiteMalfunctionCRUD />
            : chosenCURDType === "עריכה" ?
            <EditSiteMalfunctionCRUD checkList={checkList} checkListEvent={checkListEvent} setCheckListEvent={setCheckListEvent} />
             :
             <DeleteSiteMalfunctionCRUD />
            }
          </div>
        </div>
      </div>}
      </>
    )
}

const CreateSiteMalfunctionCRUD = () => {

    return(
            <div>
                    <form className="mokedWorkerForm">
                    <div className="siteInformationContainerTop">
                        <h1>באזור זה ניתן רק לערוך</h1>
                    </div>
                    <div className="buttonWrapperUserClient">
                    </div>
                </form>
            </div>
    )
    
    }

const EditSiteMalfunctionCRUD = ({checkList, checkListEvent, setCheckListEvent}) => {

    const dispatch = useDispatch();
    const [chosenValue, setChosenValue] = useState()

    const chosenMokedWorker = checkList.find(list => list.title === chosenValue)

    useEffect(() => {
        if(chosenMokedWorker){

            setCheckListEvent({
                title: chosenMokedWorker.title,
                subTitle: chosenMokedWorker.subTitle,
                orders: chosenMokedWorker.orders,
            })
        }
    }, [chosenMokedWorker, setCheckListEvent])


    const [order, setOrder] = useState("")
    const [chosenOrder, setChosenOrder] = useState()
    const [subOrder, setSubOrder] = useState("")


    const addOrder = (e) => {

        e.preventDefault()

        if(chosenOrder){

            if(subOrder){
    
                setCheckListEvent(prev => ({
                    ...prev,
                    orders: 
                        prev.orders.map(order => {
                            if(order.order === chosenOrder){
                                return {
                                    order: order.order,
                                    subOrders: [
                                        ...order.subOrders,
                                        {
                                            subOrder: subOrder,
                                        }
                                    ]
                                }
                            }else{
                                return order
                            }
                        })
                    
                }))
    
                setSubOrder("")
                setChosenOrder()
    
            }

        }else{

            if(order){
    
                setCheckListEvent(prev => ({
                    ...prev,
                    orders: [
                        ...prev.orders,
                        {
                            order: order,
                            subOrders: []
                        }
                    ]
                }))
                    
                setOrder("")
    
            }
        }

    }

    const updateCheckList = (e) => {
        e.preventDefault()
        if(chosenMokedWorker){
            dispatch(UpdateCheckListEvent(chosenMokedWorker._id, checkListEvent))
            setCheckListEvent({
                title: '',
                subTitle: '',
                orders: []
            })
            setOrder("")
            setSubOrder("")
            setChosenOrder()
            setChosenValue()
        }
    }
    
    return(
        <div>
            <div className="EditMokedWorkerCRUDTop">
                <CheckListList list={checkList} 
                               chosenValue={chosenValue} 
                               setChosenValue={setChosenValue} 
                               placeHolder="בחר צ׳ק ליסט" />
            </div>
            <form className="mokedWorkerForm" onSubmit= {(e) => updateCheckList(e)}>
                <input  className="mokedWorkerInput" 
                        placeholder="שנה את הכותרת " 
                        value={checkListEvent.title} 
                        onChange={(e) => setCheckListEvent(prev => ({ ...prev, title: e.target.value }))}/>

                <input  className="mokedWorkerInput" 
                        placeholder="שנה את תת הכותרת " 
                        value={checkListEvent.subTitle} 
                        onChange={(e) => setCheckListEvent(prev => ({ ...prev, subTitle: e.target.value }))}/>

                <input  className="mokedWorkerInput" 
                        placeholder="הוספת הוראה" 
                        value={order} 
                        onChange={(e) => setOrder(() => e.target.value)}/>

                <CheckListList list={checkListEvent.orders} 
                               chosenValue={chosenOrder} 
                               setChosenValue={setChosenOrder} 
                               placeHolder="בחר הוראה להוספת תת הוראה" 
                               order />

                <input  className="mokedWorkerInput" 
                        placeholder="הוספת תת הוראה" 
                        value={subOrder} 
                        onChange={(e) => setSubOrder(() => e.target.value)}/>

                <div className="buttonWrapperUserClient">
                    <button className="BottomFormMokedWorkersButton" onClick={(e) => addOrder(e)} >הוסף הוראה</button>
                    <input type="submit" className="BottomFormMokedWorkersButton editCRUDButton" value="ערוך אתר" />
                </div>
            </form>
        </div>
    )
}

const DeleteSiteMalfunctionCRUD = () => {
    
    return(
            <div className="clientUserFormContainer">
                <div className="EditMokedWorkerCRUDTop">
                    <h1>באזור זה ניתן רק לערוך</h1>
                </div>
            </div>
    )
}