import useAutocomplete from "@material-ui/lab/useAutocomplete";
import "./MultiChoiseInput.css";

export default function MultiChoiseInput({
  options,
  chooseBy,
  placeholder,
  value,
  setValue,
}) {
  const nameArray = options.map((option) => {
    return option[chooseBy];
  });

  const {
    getRootProps,
    getInputProps,
    getListboxProps,
    getOptionProps,
    groupedOptions,
  } = useAutocomplete({
    value: value || null,
    onChange: (event, newValue) => {
      setValue(newValue);
    },
    matchFrom: "start",
    openOnFocus: true,
    options: nameArray,
    getOptionLabel: (option) => option,
  });

  return (
    <div>
      <div {...getRootProps()}>
        <div className="MultiChoiseInput">
          <input
            className="MultiChoiseFormInput"
            placeholder={placeholder}
            {...getInputProps()}
          />

          {groupedOptions.length > 0 ? (
            <ul className="MultiChoiseList" {...getListboxProps()}>
              {groupedOptions.map((option, index) => (
                <li
                  className="MultiChoiseListItem"
                  {...getOptionProps({ option, index })}
                >
                  {option}
                </li>
              ))}
            </ul>
          ) : null}
        </div>
      </div>
    </div>
  );
}
