import "./managerPlaceLeft.css";

export default function ManagerPlaceLeft({
  imageList,
  shiftPageType,
  chosenManagerPlace,
  mokedScansSchemaData,
  fetchMokedWorkers,
  fetchUserClient,
  sitesInformation,
  malfunctionType,
  farmsInformation,
  otherSiteInformation,
}) {
  return (
    <div className="managerPlaceLeftContainer">
      <div className="managerPlaceLeftWrapper">
        {chosenManagerPlace === "mokedWorkers" ? (
          <>
            <h2 className="MokedWorkerTitleLeft">רשימת עובדי מוקד</h2>
            <ul className="mokedWorkersListLeft">
              {fetchMokedWorkers.map((worker, index) => {
                return (
                  <div key={index} className="listItemManagerPlaceLeftWrapper">
                    <h3 className="listItemManagerPlaceLeft">
                      {worker.userName}
                    </h3>
                    {worker.isAhmash && (
                      <h3 className="isAhmashManagerPlace">- אחמ"ש</h3>
                    )}
                  </div>
                );
              })}
            </ul>
          </>
        ) : chosenManagerPlace === "generalInfo" ? (
          <>
            <h2 className="MokedWorkerTitleLeft">רשימת אתרים</h2>
            <ul className="mokedWorkersListLeft">
              {sitesInformation.map((siteInfo, index) => {
                return (
                  <div key={index} className="listItemManagerPlaceLeftWrapper">
                    <h3 className="listItemManagerPlaceLeft">
                      {siteInfo.siteName}
                    </h3>
                  </div>
                );
              })}
            </ul>
          </>
        ) : chosenManagerPlace === "farmsInfo" ? (
          <>
            <h2 className="MokedWorkerTitleLeft">רשימת חוות קנאביס</h2>
            <ul className="mokedWorkersListLeft">
              {farmsInformation.map((siteInfo, index) => {
                return (
                  <div key={index} className="listItemManagerPlaceLeftWrapper">
                    <h3 className="listItemManagerPlaceLeft">
                      {siteInfo.siteName}
                    </h3>
                  </div>
                );
              })}
            </ul>
          </>
        ) : chosenManagerPlace === "farmsInfoMalfunctions" ? (
          <>
            <h2 className="MokedWorkerTitleLeft">רשימת חוות קנאביס</h2>
            <ul className="mokedWorkersListLeft">
              {farmsInformation.map((siteInfo, index) => {
                return (
                  <div key={index} className="listItemManagerPlaceLeftWrapper">
                    <h3 className="listItemManagerPlaceLeft">
                      {siteInfo.siteName}
                    </h3>
                  </div>
                );
              })}
            </ul>
          </>
        ) : chosenManagerPlace === "malfunctions" ? (
          <>
            <h2 className="MokedWorkerTitleLeft">רשימת אתרים</h2>
            <ul className="mokedWorkersListLeft">
              {sitesInformation.map((siteInfo, index) => {
                return (
                  <div key={index} className="listItemManagerPlaceLeftWrapper">
                    <h3 className="listItemManagerPlaceLeft">
                      {siteInfo.siteName}
                    </h3>
                  </div>
                );
              })}
            </ul>
          </>
        ) : chosenManagerPlace === "otherSiteInfo" ? (
          <>
            <h2 className="MokedWorkerTitleLeft">רשימת אתרים אחרים</h2>
            <ul className="mokedWorkersListLeft">
              {otherSiteInformation.map((siteInfo, index) => {
                return (
                  <div key={index} className="listItemManagerPlaceLeftWrapper">
                    <h3 className="listItemManagerPlaceLeft">
                      {siteInfo.siteName}
                    </h3>
                  </div>
                );
              })}
            </ul>
          </>
        ) : chosenManagerPlace === "mokedScansSchema" ? (
          <>
            <h2 className="MokedWorkerTitleLeft">רשימת מודלים</h2>
            <ul className="mokedWorkersListLeft">
              {mokedScansSchemaData.map((siteInfo, index) => {
                return (
                  <div key={index} className="listItemManagerPlaceLeftWrapper">
                    <h3 className="listItemManagerPlaceLeft">
                      {siteInfo.timeOfDay}
                    </h3>
                  </div>
                );
              })}
            </ul>
          </>
        ) : chosenManagerPlace === "siteUsers" ? (
          <>
            <h2 className="MokedWorkerTitleLeft">רשימת משתמשי אתר</h2>
            <ul className="mokedWorkersListLeft">
              {fetchUserClient.map((worker, index) => {
                return (
                  <div key={index} className="listItemManagerPlaceLeftWrapper">
                    <h3 className="listItemManagerPlaceLeft">
                      {worker.userName}
                    </h3>
                  </div>
                );
              })}
            </ul>
          </>
        ) : chosenManagerPlace === "shiftPageType" ? (
          <>
            <h2 className="MokedWorkerTitleLeft">רשימת קטגוריות דף חפיפה</h2>
            <ul className="mokedWorkersListLeft">
              {shiftPageType.map((worker, index) => {
                return (
                  <div key={index} className="listItemManagerPlaceLeftWrapper">
                    <h3 className="listItemManagerPlaceLeft">{worker.type}</h3>
                  </div>
                );
              })}
            </ul>
          </>
        ) : chosenManagerPlace === "ImageUploader" ? (
          <>
            <h2 className="MokedWorkerTitleLeft">הרשאות כניסה</h2>
            <ul className="mokedWorkersListLeft">
              {imageList.map((worker, index) => {
                return (
                  <div key={index} className="listItemManagerPlaceLeftWrapper">
                    <h3 className="listItemManagerPlaceLeft">
                      {worker.imageName}
                    </h3>
                  </div>
                );
              })}
            </ul>
          </>
        ) : chosenManagerPlace === "malfunctionType" ? (
          <>
            <h2 className="MokedWorkerTitleLeft">רשימת סוגי תקלות</h2>
            <ul className="mokedWorkersListLeft">
              {malfunctionType.map((type) => {
                return (
                  <div
                    key={type._id}
                    className="listItemManagerPlaceLeftWrapper"
                  >
                    <h3 className="listItemManagerPlaceLeft">
                      {type.malfunctionWith}
                    </h3>
                  </div>
                );
              })}
            </ul>
          </>
        ) : null}
      </div>
    </div>
  );
}
