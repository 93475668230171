import { AUTH, AUTH_SIGNUP, AUTH_EDIT_SIGNUP } from "../constants/actionTypes";

import * as api from "../api";


export const SignUp = (connectData) => async (dispatch) => {
  try {

    const { data } = await api.signUp(connectData);

    dispatch({ type: AUTH_SIGNUP, data });
    
  } catch (e) {

    console.log(e);
  }
}

export const SignIn = (connectData, history) => async (dispatch) => {
  try {

    const { data } = await api.signIn(connectData);

    dispatch({ type: AUTH, data });

    history.push("/");

  } catch (e) {

    console.log(e);
  }
}

export const EditSignUp = (id, connectData) => async (dispatch) => {
  try {

    const { data } = await api.editSignUp(id, connectData);

    dispatch({ type: AUTH_EDIT_SIGNUP, data });

  } catch (e) {

    console.log(e);
  }
}
