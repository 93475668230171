import ChatMessages from "./ChatMessages/ChatMessages";
import "./shiftChat.css";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { CreateChatMessage } from "../../../actions/chatMessage";

export default function ShiftChat({
  user,
  Messages,
  setChatMessageUpdater,
  socket,
}) {
  const dispatch = useDispatch();

  const [messageData, setMessageData] = useState({
    message: "",
    user: user,
  });

  const HandleSubmit = (e) => {
    e.preventDefault();

    if (!messageData.message) return;

    socket.emit("sendChatMessage", messageData);

    dispatch(CreateChatMessage(messageData));

    setMessageData({
      message: "",
      user: user,
    });
  };

  useEffect(() => {
    if (!socket) return;

    socket.on("receiveChatMessage", (messageData) => {
      setChatMessageUpdater((prev) => !prev);
    });

    return () => socket.off("receiveChatMessage");
  }, [dispatch, setChatMessageUpdater, socket]);

  return (
    <div className="shiftChatContainer">
      <div className="shiftChatComponent">
        <ChatMessages user={user} Messages={Messages} />
      </div>
      <form className="shiftChatForm" onSubmit={HandleSubmit}>
        <textarea
          type="text"
          className="shiftChatTextArea"
          value={messageData.message}
          onChange={(e) =>
            setMessageData((prev) => ({
              ...prev,
              message: e.target.value,
            }))
          }
        />
        <button type="submit" className="shiftChatSubmit">
          שלח
        </button>
      </form>
    </div>
  );
}
