import "./alarmMessage.css"

import { useEffect, useCallback } from "react"

export default function AlarmMessage({ setIsAlarmOpen, setGetTransformsUpdater, setTransformId, typeAlarmOpen, setTypeAlarmOpen }) {

    const Clear = useCallback(
        () => {
            setTimeout(() => {
            
                setIsAlarmOpen(false)
    
                setTransformId("")

                setTypeAlarmOpen("")
    
                setGetTransformsUpdater(false)
            }, 3000);
        },
        [setGetTransformsUpdater, setIsAlarmOpen, setTransformId, setTypeAlarmOpen],
    )

    useEffect(() => {

        Clear()

    }, [Clear])


    return (
        <div className="alarmMessage">
            {typeAlarmOpen === "sentForm" ? 
            <div className="alarmMessageInner">
                <h1 className="alarmMessageTitle">הטופס נשלח בהצלחה</h1>
                <img src="/assets/Success.gif" alt="" className="successIcon" />
            </div>
            : typeAlarmOpen === "editAlarm" ? 
            <div className="alarmMessageInner alarmMessageInnerEditAlarm">
                <h1 className="alarmMessageTitle">הטופס נערך בהצלחה</h1>
                <img src="/assets/edit.gif" alt="" className="successIcon" />
            </div>
            : typeAlarmOpen === "deleteAlarm" ? 
            <div className="alarmMessageInner alarmMessageInnerDeleteAlarm">
                <h1 className="alarmMessageTitle">הטופס נמחק בהצלחה</h1>
                <img src="/assets/delete.gif" alt="" className="successIcon" />
            </div>
            : null}
        </div>
    )
}
