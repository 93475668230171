import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import {
  CreateOtherSiteInformation,
  DeleteOtherSiteInformation,
  UpdateOtherSiteInformation,
} from "../../../actions/OtherSiteInformation";

import ManagerPatrolList from "../ManagerSiteInformation/ManagerPatrolList/ManagerPatrolList";
import ManagerSideNoteList from "../ManagerSiteInformation/ManagerSideNoteList/ManagerSideNoteList";
import ManagerSiteInformationName from "../ManagerSiteInformation/ManagerSiteInformationName/ManagerSiteInformationName";

export default function ManagerOtherSiteInformation({
  chosenCURDType,
  otherSiteInformation,
  setOtherSiteInfoUpdater,
}) {
  const [siteNameValue, setSiteNameValue] = useState("");

  const farmsInformationName = otherSiteInformation.map(
    (farm) => farm.siteName
  );

  return (
    <>
      {chosenCURDType && (
        <div className="siteInformationSection">
          <div className="siteInformationWrapper">
            <div className="mokedWorkerRight">
              <h1 className="mokedWorkerTitle">{chosenCURDType}</h1>
            </div>
            <div className="siteInformationContainer">
              {chosenCURDType === "רישום" ? (
                <CreateSiteInformationCRUD
                  setOtherSiteInfoUpdater={setOtherSiteInfoUpdater}
                />
              ) : chosenCURDType === "עריכה" ? (
                <EditSiteInformationCRUD
                  otherSiteInformation={otherSiteInformation}
                  setOtherSiteInfoUpdater={setOtherSiteInfoUpdater}
                  siteNameValue={siteNameValue}
                  setSiteNameValue={setSiteNameValue}
                  farmsInformationName={farmsInformationName}
                />
              ) : (
                <DeleteSiteInformationCRUD
                  otherSiteInformation={otherSiteInformation}
                  setOtherSiteInfoUpdater={setOtherSiteInfoUpdater}
                  siteNameValue={siteNameValue}
                  setSiteNameValue={setSiteNameValue}
                  farmsInformationName={farmsInformationName}
                />
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

const CreateSiteInformationCRUD = ({ setOtherSiteInfoUpdater }) => {
  const dispatch = useDispatch();

  const [sideNoteArray, setSideNoteArray] = useState([]);
  const [emailList, setEmailList] = useState([]);

  const [siteInformationData, setSiteInformationData] = useState({
    siteName: "",
    security: "",
    manager: "",
    connected: "",
    ourTech: "",
    location: "",
    gate: "",
    sideNote: [],
    emailList: [],
  });

  useEffect(() => {
    setSiteInformationData((prev) => ({
      ...prev,
      emailList: emailList,
      sideNote: sideNoteArray,
    }));
  }, [emailList, sideNoteArray]);

  const handleSubmit = (e) => {
    e.preventDefault();

    dispatch(CreateOtherSiteInformation(siteInformationData));

    Clear();
  };

  const Clear = () => {
    setSiteInformationData({
      siteName: "",
      security: "",
      manager: "",
      connected: "",
      ourTech: "",
      location: "",
      gate: "",
      sideNote: [],
      emailList: [],
    });

    setEmailList([]);
    setSideNoteArray([]);

    setOtherSiteInfoUpdater((prev) => !prev);
  };

  return (
    <div>
      <form className="mokedWorkerForm" onSubmit={handleSubmit}>
        <div className="siteInformationContainerTop">
          <input
            type="text"
            className="mokedWorkerInput"
            value={siteInformationData.siteName}
            placeholder="שם הלקוח"
            onChange={(e) =>
              setSiteInformationData({
                ...siteInformationData,
                siteName: e.target.value,
              })
            }
          />
          <input
            type="text"
            className="mokedWorkerInput"
            value={siteInformationData.security}
            placeholder="אבטחה"
            onChange={(e) =>
              setSiteInformationData({
                ...siteInformationData,
                security: e.target.value,
              })
            }
          />
          <input
            type="text"
            className="mokedWorkerInput"
            value={siteInformationData.manager}
            placeholder="אחראי אבטחה"
            onChange={(e) =>
              setSiteInformationData({
                ...siteInformationData,
                manager: e.target.value,
              })
            }
          />
          <input
            type="text"
            className="mokedWorkerInput"
            value={siteInformationData.connected}
            placeholder="האם מחוברת טכנולוגיה"
            onChange={(e) =>
              setSiteInformationData({
                ...siteInformationData,
                connected: e.target.value,
              })
            }
          />
          <input
            type="text"
            className="mokedWorkerInput"
            value={siteInformationData.ourTech}
            placeholder="האם טכנולוגיה בשירות שלנו"
            onChange={(e) =>
              setSiteInformationData({
                ...siteInformationData,
                ourTech: e.target.value,
              })
            }
          />
          <input
            type="text"
            className="mokedWorkerInput"
            value={siteInformationData.location}
            placeholder="מיקום"
            onChange={(e) =>
              setSiteInformationData({
                ...siteInformationData,
                location: e.target.value,
              })
            }
          />
          <input
            type="text"
            className="mokedWorkerInput"
            value={siteInformationData.gate}
            placeholder="האם ישנן פונציית שערים"
            onChange={(e) =>
              setSiteInformationData({
                ...siteInformationData,
                gate: e.target.value,
              })
            }
          />
          <ManagerSideNoteList
            sideNoteArray={sideNoteArray}
            setSideNoteArray={setSideNoteArray}
          />
          <ManagerSideNoteList
            sideNoteArray={emailList}
            setSideNoteArray={setEmailList}
            placeholder="הכנס מיילים לאתר (רב בחירה)"
          />
        </div>
        <div className="buttonWrapperUserClient">
          <input
            type="submit"
            className="BottomFormMokedWorkersButton enterCRUDButton"
            value="רשום אתר חדש"
          />
        </div>
      </form>
    </div>
  );
};

const EditSiteInformationCRUD = ({
  otherSiteInformation,
  setOtherSiteInfoUpdater,
  farmsInformationName,
  siteNameValue,
  setSiteNameValue,
}) => {
  const dispatch = useDispatch();

  const chosenSiteInformation = otherSiteInformation.find(
    (site) => site.siteName === siteNameValue
  );

  const [sideNoteArray, setSideNoteArray] = useState([]);
  const [emailList, setEmailList] = useState([]);

  const [siteInformationData, setSiteInformationData] = useState({
    _id: "",
    siteName: "",
    security: "",
    manager: "",
    connected: "",
    ourTech: "",
    location: "",
    gate: "",
    sideNote: [],
    emailList: [],
  });

  useEffect(() => {
    if (chosenSiteInformation) {
      Clear();
      setSiteInformationData((prev) => ({
        ...prev,
        ...chosenSiteInformation,
      }));

      setEmailList(chosenSiteInformation.emailList);
      setSideNoteArray(chosenSiteInformation.sideNote);
    }
  }, [chosenSiteInformation]);

  useEffect(() => {
    setSiteInformationData((prev) => ({
      ...prev,
      emailList: emailList,
      sideNote: sideNoteArray,
    }));
  }, [emailList, sideNoteArray]);

  const handleSubmit = (e) => {
    e.preventDefault();

    dispatch(
      UpdateOtherSiteInformation(siteInformationData._id, siteInformationData)
    );

    Clear();
  };

  const Clear = () => {
    setSiteInformationData({
      siteName: "",
      security: "",
      manager: "",
      connected: "",
      ourTech: "",
      location: "",
      gate: "",
      sideNote: [],
      emailList: [],
    });

    setEmailList([]);
    setSideNoteArray([]);

    setSiteNameValue("");

    setOtherSiteInfoUpdater((prev) => !prev);
  };

  return (
    <div>
      <form className="mokedWorkerForm" onSubmit={handleSubmit}>
        <div className="siteInformationContainerTop">
          <ManagerSiteInformationName
            sitesInformationName={farmsInformationName}
            siteNameValue={siteNameValue}
            setSiteNameValue={setSiteNameValue}
          />
          <input
            type="text"
            className="mokedWorkerInput"
            value={siteInformationData.siteName}
            placeholder="שם הלקוח"
            onChange={(e) =>
              setSiteInformationData({
                ...siteInformationData,
                siteName: e.target.value,
              })
            }
          />
          <input
            type="text"
            className="mokedWorkerInput"
            value={siteInformationData.security}
            placeholder="אבטחה"
            onChange={(e) =>
              setSiteInformationData({
                ...siteInformationData,
                security: e.target.value,
              })
            }
          />
          <input
            type="text"
            className="mokedWorkerInput"
            value={siteInformationData.manager}
            placeholder="אחראי אבטחה"
            onChange={(e) =>
              setSiteInformationData({
                ...siteInformationData,
                manager: e.target.value,
              })
            }
          />
          <input
            type="text"
            className="mokedWorkerInput"
            value={siteInformationData.connected}
            placeholder="האם מחוברת טכנולוגיה"
            onChange={(e) =>
              setSiteInformationData({
                ...siteInformationData,
                connected: e.target.value,
              })
            }
          />
          <input
            type="text"
            className="mokedWorkerInput"
            value={siteInformationData.ourTech}
            placeholder="האם טכנולוגיה בשירות שלנו"
            onChange={(e) =>
              setSiteInformationData({
                ...siteInformationData,
                ourTech: e.target.value,
              })
            }
          />
          <input
            type="text"
            className="mokedWorkerInput"
            value={siteInformationData.location}
            placeholder="מיקום"
            onChange={(e) =>
              setSiteInformationData({
                ...siteInformationData,
                location: e.target.value,
              })
            }
          />
          <input
            type="text"
            className="mokedWorkerInput"
            value={siteInformationData.gate}
            placeholder="האם ישנן פונציית שערים"
            onChange={(e) =>
              setSiteInformationData({
                ...siteInformationData,
                gate: e.target.value,
              })
            }
          />
          <ManagerSideNoteList
            sideNoteArray={sideNoteArray}
            setSideNoteArray={setSideNoteArray}
          />
          <ManagerSideNoteList
            sideNoteArray={emailList}
            setSideNoteArray={setEmailList}
            placeholder="הכנס מיילים לאתר (רב בחירה)"
          />
        </div>
        <div className="buttonWrapperUserClient">
          <input
            type="submit"
            className="BottomFormMokedWorkersButton editCRUDButton"
            value="ערוך אתר"
          />
        </div>
      </form>
    </div>
  );
};

const DeleteSiteInformationCRUD = ({
  otherSiteInformation,
  setOtherSiteInfoUpdater,
  farmsInformationName,
  siteNameValue,
  setSiteNameValue,
}) => {
  const dispatch = useDispatch();

  const chosenSiteInformation = otherSiteInformation.find(
    (site) => site.siteName === siteNameValue
  );

  const handleSubmit = (e) => {
    e.preventDefault();

    dispatch(DeleteOtherSiteInformation(chosenSiteInformation._id));

    Clear();
  };

  const Clear = () => {
    setSiteNameValue("");

    setOtherSiteInfoUpdater((prev) => !prev);
  };

  return (
    <div className="clientUserFormContainer">
      <div className="EditMokedWorkerCRUDTop">
        <ManagerSiteInformationName
          sitesInformationName={farmsInformationName}
          siteNameValue={siteNameValue}
          setSiteNameValue={setSiteNameValue}
        />
      </div>
      <form className="mokedWorkerForm" onSubmit={handleSubmit}>
        <div className="buttonWrapperUserClient">
          <input
            type="submit"
            className="BottomFormMokedWorkersButton deleteCRUDButton"
            value="מחק אתר"
          />
        </div>
      </form>
    </div>
  );
};
