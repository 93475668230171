import "./managerPlace.css";

import { useState, useEffect, useMemo } from "react";

import { useDispatch, useSelector } from "react-redux";

import { FetchAllUserClient } from "../../actions/users";

import ManagerPlaceLeft from "../../components/ManagerPlaceComponents/ManagerPlaceLeft/ManagerPlaceLeft";
import ManagerPlaceMiddle from "../../components/ManagerPlaceComponents/ManagerPlaceMiddle/ManagerPlaceMiddle";
import ManagerPlaceRight from "../../components/ManagerPlaceComponents/ManagerPlaceRight/ManagerPlaceRight";

export default function ManagerPlace({
  imageList,
  shiftPageType,
  setMokedWorkerUpdater,
  setSiteInfoUpdater,
  setMalfunctionTypeUpdater,
  malfunctionType,
  setMokedScansSchemaUpdater,
  setFarmInfoUpdater,
  farmsInformation,
  otherSiteInformation,
  setOtherSiteInfoUpdater,
}) {
  const dispatch = useDispatch();

  const [chosenManagerPlace, setChosenManagerPlace] = useState("");
  const [chosenCURDType, setChosenCURDType] = useState("");
  const [userClientUpdater, setUserClientUpdater] = useState(true);

  useEffect(() => {
    dispatch(FetchAllUserClient());

    setUserClientUpdater(true);
  }, [dispatch, userClientUpdater]);

  const fetchMokedWorkers = useSelector((state) => state.mokedWorkers);
  const fetchUserClient = useSelector((state) => state.userClient);
  const sitesInformation = useSelector((state) => state.siteInformation);
  const mokedScansSchemaData = useSelector((state) => state.mokedScansSchema);

  const sitesInformationName = sitesInformation.map((siteName) => {
    return siteName.siteName;
  });
  const sitesInformationNameAndType = sitesInformation.map((siteName) => {
    return {
      siteName: siteName.siteName,
      mokedType: siteName.mokedType,
    };
  });

  const params = useMemo(() => {
    return {
      managerPlaceMiddle: {
        imageList,
        shiftPageType,
        chosenManagerPlace,
        chosenCURDType,
        setChosenCURDType,
        sitesInformation,
        fetchMokedWorkers,
        setMokedWorkerUpdater,
        fetchUserClient,
        setUserClientUpdater,
        sitesInformationName,
        sitesInformationNameAndType,
        mokedScansSchemaData,
        setMokedScansSchemaUpdater,
        setSiteInfoUpdater,
        malfunctionType,
        setMalfunctionTypeUpdater,
        setFarmInfoUpdater,
        farmsInformation,
        otherSiteInformation,
        setOtherSiteInfoUpdater,
      },
      managerPlaceLeft: {
        imageList,
        shiftPageType,
        mokedScansSchemaData,
        malfunctionType,
        chosenManagerPlace,
        fetchMokedWorkers,
        fetchUserClient,
        sitesInformation,
        farmsInformation,
        otherSiteInformation,
      },
    };
  }, [
    imageList,
    shiftPageType,
    chosenManagerPlace,
    chosenCURDType,
    sitesInformation,
    fetchMokedWorkers,
    fetchUserClient,
    sitesInformationName,
    sitesInformationNameAndType,
    mokedScansSchemaData,
    setMokedScansSchemaUpdater,
    setSiteInfoUpdater,
    malfunctionType,
    setMalfunctionTypeUpdater,
    setFarmInfoUpdater,
    farmsInformation,
  ]);

  return (
    <div className="managerPlaceContainer">
      <ManagerPlaceRight setChosenManagerPlace={setChosenManagerPlace} />
      <ManagerPlaceMiddle {...params.managerPlaceMiddle} />
      <ManagerPlaceLeft {...params.managerPlaceLeft} />
    </div>
  );
}
