import "./clientUsers.css"

import { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";

import { SignUp } from "../../../actions/auth";
import { UpdateUserClient, DeleteUserClient } from "../../../actions/users"

import UserClientName from "./UserClientName/UserClientName"
import ManagerSiteInformationName from "./ManagerSiteInformationName/ManagerSiteInformationName"

const ClientUsers = ({ chosenCURDType, setUserClientUpdater, fetchMokedWorkers, fetchUserClient, sitesInformationName }) => {

    const [ userNameValue, setUserNameValue ] = useState("")
    const isTransformCheck = useRef()

return(
    <>
    { chosenCURDType && <div className="mokedWorker">
      <div className="mokedWorkerWrapperUserClient">
        <div className="mokedWorkerRight">
          <h1 className="mokedWorkerTitle">{chosenCURDType}</h1>
        </div>
          <div className="mokedWorkerLeft">
            {chosenCURDType === "רישום" ?
            <CreateClientUsersCRUD setUserClientUpdater={setUserClientUpdater} isTransformCheck={isTransformCheck} sitesInformationName={sitesInformationName} />
            : chosenCURDType === "עריכה" ?
            <EditClientUsersCRUD fetchMokedWorkers={fetchMokedWorkers} isTransformCheck={isTransformCheck} setUserClientUpdater={setUserClientUpdater} fetchUserClient={fetchUserClient}
            userNameValue={userNameValue} setUserNameValue={setUserNameValue} sitesInformationName={sitesInformationName} />
             :
             <DeleteClientUsersCRUD setUserClientUpdater={setUserClientUpdater} fetchUserClient={fetchUserClient}
             userNameValue={userNameValue} setUserNameValue={setUserNameValue}/>
            }
          </div>
        </div>
      </div>}
      </>
    )
}

export default ClientUsers;


const CreateClientUsersCRUD = ({ setUserClientUpdater, sitesInformationName, isTransformCheck }) => {

    const dispatch = useDispatch();
    
    const [connectData, setConnectData] = useState({
      userName: "",
      password: "",
      permission:[],
      isTransform: false,
    });
    
    const [ siteNameValue, setSiteNameValue ] = useState("");
    
    const AddPermission = (e) => {
      e.preventDefault();

      if(siteNameValue){

        setConnectData(prev => ({
            ...prev,
            permission:[...prev.permission, siteNameValue]
          }))
      }
    
      setSiteNameValue("")
    }
    
    
    const handleSubmit = (e) => {
      e.preventDefault()
    
      dispatch(SignUp(connectData))

      Clear()

    }

    const DeletePermit = (e) => {
        e.preventDefault()

        const permitName = e.target.getAttribute("name")

        setConnectData(prev => ({
            ...prev,
            permission:[...prev.permission.filter(permit => permit !== permitName)]
          }))

    }

    const Clear = () => {

        setConnectData({
            userName: "",
            password: "",
            permission:[],
            isTransform: false,
        })

        setUserClientUpdater(false)

        isTransformCheck.current.checked=false

    }
    
    return(
            <div className="clientUserFormContainer">
                    <form className="mokedWorkerForm" onSubmit= {handleSubmit}>
                    <div className="clientUserFormContainerTop">
                        <input type="text" className="mokedWorkerInput" value={connectData.userName} placeholder="שם משתמש" onChange={(e) => setConnectData({ ...connectData, userName: e.target.value })}/>
                        <input type="text" className="mokedWorkerInput" value={connectData.password} placeholder="סיסמא" onChange={(e) => setConnectData({ ...connectData, password: e.target.value })}/>
                        <div className="BottomFormMokedWorkersAhmash BottomFormMokedWorkersAhmashMargin">
                          <p className="ahmashTitle">האם יש הרשאה לשינועים?</p>
                          <input type="checkbox" className="transformsFormCheck" ref={isTransformCheck} onChange={(e) => setConnectData((prev) => ({...prev, isTransform: !connectData.isTransform}))} />
                        </div>
                        <ManagerSiteInformationName sitesInformationName={sitesInformationName} siteNameValue={siteNameValue} setSiteNameValue={setSiteNameValue}/>
                    </div>
                    <div className="clientUserFormContainerPermission">
                        <ul className="clientUserFormPermissionList">
                        {connectData.permission && connectData.permission.map((permit, index) => {
                            return(
                            <li key={index} className="permitItem" name={permit} onClick={DeletePermit}>{permit}</li>
                            )
                        })}
                        </ul>
                    </div>
                    <div className="buttonWrapperUserClient">
                        <button onClick={AddPermission} className="BottomFormMokedWorkersButton enterCRUDButton">הוספת הרשאה</button>
                        <input type="submit" className="BottomFormMokedWorkersButton enterCRUDButton" value="רשום משתמש" />
                    </div>
                </form>
            </div>
    )
    
    }

const EditClientUsersCRUD = ({ fetchUserClient, isTransformCheck, setUserClientUpdater, userNameValue, setUserNameValue, sitesInformationName }) => {

    const dispatch = useDispatch();

    let chosenUserClient = fetchUserClient.find(user => user.userName === userNameValue)
    
    const [connectData, setConnectData] = useState({
      userName: "",
      password: "",
      permission:[],
      isTransform: false,
    });

    useEffect(() => {

        if(chosenUserClient){
        setConnectData({
            userName: chosenUserClient.userName,
            password: chosenUserClient.password,
            permission:[...chosenUserClient.permission],
            isTransform: chosenUserClient?.isTransform
          });

          isTransformCheck.current.checked=chosenUserClient?.isTransform
        }
    }, [chosenUserClient, isTransformCheck])
    
    const [ siteNameValue, setSiteNameValue ] = useState("");
    
    const AddPermission = (e) => {
      e.preventDefault();
    
      if(siteNameValue){

        setConnectData(prev => ({
            ...prev,
            permission:[...prev.permission, siteNameValue]
          }))
      }
    
      setSiteNameValue("")
    }
    
    
    const handleSubmit = (e) => {
      e.preventDefault()

      console.log(connectData)
    
      dispatch(UpdateUserClient( chosenUserClient._id, connectData))

      Clear()

    }

    const DeletePermit = (e) => {
        e.preventDefault()

        const permitName = e.target.getAttribute("name")

        setConnectData(prev => ({
            ...prev,
            permission:[...prev.permission.filter(permit => permit !== permitName)]
          }))

    }

    const Clear = () => {

        setConnectData({
            userName: "",
            password: "",
            permission:[],
            isTransforms: false,
        })

        setUserNameValue("")
        setUserClientUpdater(false)

        isTransformCheck.current.checked=false

    }
    
    return(
            <div className="clientUserFormContainer">
                <div className="EditMokedWorkerCRUDTop">
                    <UserClientName fetchUserClient={fetchUserClient} userNameValue={userNameValue} setUserNameValue={setUserNameValue} />
                </div>
                    <form className="mokedWorkerForm" onSubmit= {handleSubmit}>
                    <div className="clientUserFormContainerTop">
                        <input type="text" className="mokedWorkerInput" value={connectData.userName} placeholder="שם משתמש" onChange={(e) => setConnectData({ ...connectData, userName: e.target.value })}/>
                        <div className="BottomFormMokedWorkersAhmash BottomFormMokedWorkersAhmashMargin">
                          <p className="ahmashTitle">האם יש הרשאה לשינועים?</p>
                          <input type="checkbox" className="transformsFormCheck" ref={isTransformCheck} onChange={(e) => setConnectData((prev) => ({...prev, isTransform: !connectData.isTransform}))} />
                        </div>
                        <ManagerSiteInformationName sitesInformationName={sitesInformationName} siteNameValue={siteNameValue} setSiteNameValue={setSiteNameValue}/>
                    </div>
                    <div className="clientUserFormContainerPermission">
                        <ul className="clientUserFormPermissionList">
                        {connectData.permission && connectData.permission.map((permit, index) => {
                            return(
                            <li key={index} className="permitItem" name={permit} onClick={DeletePermit}>{permit}</li>
                            )
                        })}
                        </ul>
                    </div>
                    <div className="buttonWrapperUserClient">
                        <button onClick={AddPermission} className="BottomFormMokedWorkersButton enterCRUDButton">הוספת הרשאה</button>
                        <input type="submit" className="BottomFormMokedWorkersButton editCRUDButton" value="ערוך משתמש" />
                    </div>
                </form>
            </div>
    )
}

const DeleteClientUsersCRUD = ({ fetchUserClient, setUserClientUpdater, userNameValue, setUserNameValue }) => {

    const dispatch = useDispatch();

    let chosenUserClient = fetchUserClient.find(user => user.userName === userNameValue)
    
    const handleSubmit = (e) => {
      e.preventDefault()
    
      dispatch(DeleteUserClient( chosenUserClient._id))

      Clear()

    }

    const Clear = () => {

        setUserNameValue("")
        setUserClientUpdater(false)
    }
    
    return(
            <div className="clientUserFormContainer">
                <div className="EditMokedWorkerCRUDTop">
                    <UserClientName fetchUserClient={fetchUserClient} userNameValue={userNameValue} setUserNameValue={setUserNameValue} />
                </div>
                    <form className="mokedWorkerForm" onSubmit= {handleSubmit}>
                    <div className="buttonWrapperUserClient">
                        <input type="submit" className="BottomFormMokedWorkersButton deleteCRUDButton" value="מחק משתמש" />
                    </div>
                </form>
            </div>
    )
}