import "./shiftPageType.css"

import { useState } from "react";
import { useDispatch } from "react-redux";
import { CreateShiftPageType, UpdateShiftPageType, DeleteShiftPageType } from "../../../actions/shiftPageType";
import MokedWorkerName from "./MokedWorkerName/MokedWorkerName"

const ShiftPageType = ({ chosenCURDType, shiftPageType }) => {

const [ creatorNameValue, setCreatorNameValue ] = useState("")

const [workerData, setWorkerData] = useState({
  type: '',
});

return(
  <>
    { chosenCURDType && <div className="mokedWorker">
      <div className="mokedWorkerWrapper">
        <div className="mokedWorkerRight">
          <h1 className="mokedWorkerTitle">{chosenCURDType}</h1>
        </div>
          <div className="mokedWorkerLeft">
            {chosenCURDType === "רישום" ?
            <CreateShiftPageTypeCRUD workerData={workerData} setWorkerData={setWorkerData} />
            : chosenCURDType === "עריכה" ?
            <EditMokedWorkerCRUD shiftPageType={shiftPageType} creatorNameValue={creatorNameValue} setCreatorNameValue={setCreatorNameValue}
             workerData={workerData} setWorkerData={setWorkerData} />
             :
             <DeleteShiftPageTypeCRUD shiftPageType={shiftPageType} creatorNameValue={creatorNameValue} setCreatorNameValue={setCreatorNameValue}
             workerData={workerData} setWorkerData={setWorkerData} />
            }
          </div>
        </div>
      </div>}
      </>
      )
}

export default ShiftPageType;


const CreateShiftPageTypeCRUD = ({ workerData, setWorkerData }) => {

  const dispatch = useDispatch();
    
  const handleSubmit = (e) => {
    e.preventDefault()

    if(workerData.type) {

      dispatch(CreateShiftPageType(workerData))
  
      Clear()
    }

  }
  
  const Clear = () => {
  
    setWorkerData({
      type: ''
    })
  
  }

  return(
    <form className="mokedWorkerForm" onSubmit= {handleSubmit}>
      <input type="text" id="login" className="mokedWorkerInput" name="login" value={workerData.type} placeholder="קטגוריית דף חפיפה" onChange={(e) => setWorkerData({ ...workerData, type: e.target.value })}/>
      <div className="BottomFormMokedWorkers">
        <div className="BottomFormMokedWorkers">
          <input type="submit" className="BottomFormMokedWorkersButton enterCRUDButton" value="הכנס קטגוריה" />
        </div>
      </div>
  </form>
  )
}

const EditMokedWorkerCRUD = ({ shiftPageType, creatorNameValue, setCreatorNameValue, workerData, setWorkerData }) => {

  const dispatch = useDispatch();

  const chosenMokedWorker = shiftPageType.find(worker => worker.type === creatorNameValue)
    
  const handleSubmit = (e) => {
    e.preventDefault()

    if(workerData.type && chosenMokedWorker) {
  
    dispatch( UpdateShiftPageType( chosenMokedWorker._id, workerData ))
  
    Clear()

    }
  }
  
  const Clear = () => {
  
    setWorkerData({
        type: ''
    })
    
    setCreatorNameValue("")
  }

  return(
    <>
      <div className="EditMokedWorkerCRUDTop">
        <MokedWorkerName shiftPageType={shiftPageType} creatorNameValue={creatorNameValue} setCreatorNameValue={setCreatorNameValue} />
      </div>
      <form className="mokedWorkerForm" onSubmit= {handleSubmit}>
        <input type="text" id="login" className="mokedWorkerInput" name="login" value={workerData.type} placeholder="שנה שם סוג קטגוריה" onChange={(e) => setWorkerData({ ...workerData, type: e.target.value })}/>
        <div className="BottomFormMokedWorkers">
          <div className="BottomFormMokedWorkers">
            <input type="submit" className="BottomFormMokedWorkersButton editCRUDButton" value="ערוך קטגוריה" />
          </div>
        </div>
      </form>   
   </>
  )
}

const DeleteShiftPageTypeCRUD = ({ shiftPageType, creatorNameValue, setCreatorNameValue}) => {

  const dispatch = useDispatch();

  const chosenMokedWorker = shiftPageType.find(worker => worker.type === creatorNameValue)
    
  const handleSubmit = (e) => {
    e.preventDefault()

    if(chosenMokedWorker) {
  
    dispatch( DeleteShiftPageType( chosenMokedWorker._id ))
  
    Clear()

    }
  }
  
  const Clear = () => {
    
    setCreatorNameValue("")
  }

  return(
    <>
      <div className="EditMokedWorkerCRUDTop">
      <MokedWorkerName shiftPageType={shiftPageType} creatorNameValue={creatorNameValue} setCreatorNameValue={setCreatorNameValue} />
      </div>
      <form className="mokedWorkerForm" onSubmit= {handleSubmit}>
        <div className="BottomFormMokedWorkers">
          <div className="BottomFormMokedWorkers">
            <input type="submit" className="BottomFormMokedWorkersButton deleteCRUDButton" value="מחק קטגוריה" />
          </div>
        </div>
      </form>   
   </>
  )
}