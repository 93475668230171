import React, { useState } from "react";
import "./farmInformation.css";
import ManagerSiteInformationName from "../../components/ManagerPlaceComponents/ManagerSiteInformation/ManagerSiteInformationName/ManagerSiteInformationName";
import FarmInfo from "./FarmInfo/FarmInfo";
import FarmMalfunction from "./FarmMalfunction/FarmMalfunction";
import OtherSiteInfo from "./OtherSiteInfo/OtherSiteInfo";
import OtherSiteInfoEmails from "./OtherSiteInfo/OtherSiteInfoEmails";

export default function FarmInformation({ farmsInformation, otherSite }) {
  const farmsInformationName = farmsInformation.map((farm) => farm.siteName);
  const [siteNameValue, setSiteNameValue] = useState("");

  const chosenSiteInformation = farmsInformation.find(
    (site) => site.siteName === siteNameValue
  );

  return (
    <div className="farmInformationMainOuter">
      <ManagerSiteInformationName
        sitesInformationName={farmsInformationName}
        siteNameValue={siteNameValue}
        setSiteNameValue={setSiteNameValue}
      />
      {chosenSiteInformation && (
        <div className="farmInformationMain">
          {otherSite ? (
            <>
              <OtherSiteInfo chosenSiteInformation={chosenSiteInformation} />
              <OtherSiteInfoEmails
                emailList={chosenSiteInformation.emailList}
              />
            </>
          ) : (
            <>
              <FarmInfo chosenSiteInformation={chosenSiteInformation} />
              <FarmMalfunction chosenSiteInformation={chosenSiteInformation} />
            </>
          )}
        </div>
      )}
    </div>
  );
}
