const Validation = ( transformsFormsData, cameras, ituran, mears, sign ) => {

    let errors = {}
  
    if(!transformsFormsData.mokdan){
      errors.mokdan = "הכנס את שם המוקדן"
    }
    if(!transformsFormsData.driver){
      errors.driver = "הכנס שם מנהל השינוע"
    }
    if(Object.keys(transformsFormsData.guards).length < 1){
      errors.driver = "הכנס את שמות המאבטחים "
    }
    if(!cameras.current || !ituran.current || !mears.current || !sign.current){
        errors.checked = "יש לאשר את כל השדות"
    }else if(cameras.current.checked === false || ituran.current.checked === false || mears.current.checked === false || sign.current.checked === false){
        errors.checked = "יש לאשר את כל השדות"
    }

    return errors;
  }
  
  export default Validation
  