import "./managerPlaceRight.css";

export default function ManagerPlaceRight({ setChosenManagerPlace }) {
  return (
    <div className="managerPlaceRightContainer">
      <div className="managerPlaceRightWrapper">
        <button
          className="itemButton"
          onClick={() => setChosenManagerPlace("generalInfo")}
        >
          <span className="title-ItemButton text-Black ">
            חוות סולאריות - מידע כללי
          </span>
        </button>
        <button
          className="itemButton"
          onClick={() => setChosenManagerPlace("malfunctions")}
        >
          <span className="title-ItemButton text-Black ">
            חוות סולאריות - תקלות
          </span>
        </button>
        <button
          className="itemButton"
          onClick={() => setChosenManagerPlace("farmsInfo")}
        >
          <span className="title-ItemButton text-Black ">
            חוות קנאביס - מידע כללי
          </span>
        </button>
        <button
          className="itemButton"
          onClick={() => setChosenManagerPlace("farmsInfoMalfunctions")}
        >
          <span className="title-ItemButton text-Black ">
            חוות קנאביס - תקלות
          </span>
        </button>
        <button
          className="itemButton"
          onClick={() => setChosenManagerPlace("otherSiteInfo")}
        >
          <span className="title-ItemButton text-Black ">
            אתרים אחרים - מידע כללי
          </span>
        </button>
        <button
          className="itemButton"
          onClick={() => setChosenManagerPlace("malfunctionType")}
        >
          <span className="title-ItemButton text-Black ">סוגי תקלות</span>
        </button>
        <button
          className="itemButton"
          onClick={() => setChosenManagerPlace("mokedWorkers")}
        >
          <span className="title-ItemButton text-Black ">עובדי מוקד</span>
        </button>
        <button
          className="itemButton"
          onClick={() => setChosenManagerPlace("siteUsers")}
        >
          <span className="title-ItemButton text-Black ">מורשי כניסה לאתר</span>
        </button>
        <button
          className="itemButton"
          onClick={() => setChosenManagerPlace("popupMessages")}
        >
          <span className="title-ItemButton text-Black ">התראות מתוזמנות</span>
        </button>
        <button
          className="itemButton"
          onClick={() => setChosenManagerPlace("mokedScansSchema")}
        >
          <span className="title-ItemButton text-Black ">סריקות מוקד</span>
        </button>
        <button
          className="itemButton"
          onClick={() => setChosenManagerPlace("shiftPageType")}
        >
          <span className="title-ItemButton text-Black ">
            קטגוריות חפיפת משמרת
          </span>
        </button>
        <button
          className="itemButton"
          onClick={() => setChosenManagerPlace("ImageUploader")}
        >
          <span className="title-ItemButton text-Black ">הרשאות כניסה</span>
        </button>
        <button
          className="itemButton"
          onClick={() => setChosenManagerPlace("checkListEvents")}
        >
          <span className="title-ItemButton text-Black "> צ׳ק ליסט</span>
        </button>
      </div>
    </div>
  );
}
