
import { CREATE_NEW_CHECKLISTEVENT, FETCH_ALL_CHECKLISTEVENTS, UPDATE_CHECKLISTEVENT, DELETE_CHECKLISTEVENT } from "../constants/actionTypes"

const CheckListEventReducer = ( CheckListEvent = [], action ) => {

    switch (action.type) {

        case CREATE_NEW_CHECKLISTEVENT:
            return [...CheckListEvent, action.payload]
        
        case FETCH_ALL_CHECKLISTEVENTS:
            return action.payload
                
        case UPDATE_CHECKLISTEVENT:
            return CheckListEvent.map((e) => e._id === action.payload._id ? action.payload : e)
        
        case DELETE_CHECKLISTEVENT:
            return CheckListEvent.filter((e) => e._id !== action.payload)
            
        default:
            return CheckListEvent

    }
}
        
export default CheckListEventReducer
        