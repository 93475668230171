import "./MalfunctionType.css"

import { useState, useEffect } from "react"

import { CreateMalfunctionType, UpdateMalfunctionType, DeleteMalfunctionType } from "../../../actions/malfunctionType"
import { useDispatch } from "react-redux"

import MalfunctionGroup from "./MalfunctionGroup"


export default function MalfunctionType({ chosenCURDType, setMalfunctionTypeUpdater, malfunctionType }) {

return(
    <>
    { chosenCURDType && <div className="siteInformationSection">
      <div className="siteInformationWrapper">
        <div className="mokedWorkerRight">
          <h1 className="mokedWorkerTitle">{chosenCURDType}</h1>
        </div>
          <div className="siteInformationContainer">
            {chosenCURDType === "רישום" ?
            <CreateSiteMalfunctionCRUD setMalfunctionTypeUpdater={setMalfunctionTypeUpdater} /> 
            : chosenCURDType === "עריכה" ?
            <EditSiteMalfunctionCRUD setMalfunctionTypeUpdater={setMalfunctionTypeUpdater} malfunctionType={malfunctionType} />
             :
             <DeleteSiteMalfunctionCRUD setMalfunctionTypeUpdater={setMalfunctionTypeUpdater} malfunctionType={malfunctionType}  />
            }
          </div>
        </div>
      </div>}
      </>
    )
}

const CreateSiteMalfunctionCRUD = ({ setMalfunctionTypeUpdater }) => {


    const dispatch = useDispatch();
    
    const [ malfunctionType, setMalfunctionType ] = useState({
        malfunctionWith: "",
        whatIsWrong: []
    })
    
    const [ wrongValue, setWrongValue ] = useState("")
    
    const AddPermission = (e) => {
      e.preventDefault();

      if(wrongValue){

        setMalfunctionType(prev => ({
            ...prev,
            whatIsWrong:[...prev.whatIsWrong, wrongValue]
          }))
      }
    
      setWrongValue("")
    }
    
    
    const handleSubmit = (e) => {
      e.preventDefault()

      dispatch(CreateMalfunctionType(malfunctionType))
    
      Clear()

    }

    const DeletePermit = (e) => {
        e.preventDefault()

        const permitName = e.target.getAttribute("name")

        setMalfunctionType(prev => ({
            ...prev,
            whatIsWrong:[...prev.whatIsWrong.filter(permit => permit !== permitName)]
          }))

    }

    const Clear = () => {

        setMalfunctionType({
            malfunctionWith: "",
            whatIsWrong: []
        })

        setMalfunctionTypeUpdater(prev => !prev)
    }
    
    return(
            <div className="clientUserFormContainer">
                    <form className="mokedWorkerForm" onSubmit= {handleSubmit}>
                    <div className="clientUserFormContainerTop">
                        <input type="text" className="mokedWorkerInput" value={malfunctionType.malfunctionWith} placeholder="סוג תקלה" onChange={(e) => setMalfunctionType({ ...malfunctionType, malfunctionWith: e.target.value })}/>
                        <input type="text" className="mokedWorkerInput" value={wrongValue} placeholder="טיב תקלה" onChange={(e) => setWrongValue( e.target.value )}/>
                    </div>
                    <div className="malfunctionTypeContainer">
                        <ul className="malfunctionTypeContainerList">
                        {malfunctionType.whatIsWrong && malfunctionType.whatIsWrong.map((permit, index) => {
                            return(
                            <li key={index} className="malfunctionItem" name={permit} onClick={DeletePermit}>{permit}</li>
                            )
                        })}
                        </ul>
                    </div>
                    <div className="buttonWrapperUserClient">
                        <button onClick={AddPermission} className="BottomFormMokedWorkersButton enterCRUDButton">הוספת טיב תקלה</button>
                        <input type="submit" className="BottomFormMokedWorkersButton enterCRUDButton" value="רשום תקלה" />
                    </div>
                </form>
            </div>
    )
    
    }

const EditSiteMalfunctionCRUD = ({ setMalfunctionTypeUpdater, malfunctionType }) => {

    const dispatch = useDispatch();

    const [ chosenType, setChosenType ] = useState("")
    
    const [ malfunctionTypes, setMalfunctionTypes ] = useState({
        malfunctionWith: "",
        whatIsWrong: []
    })
    
    let chosenMalfunctionType = malfunctionType.find(type => type.malfunctionWith === chosenType)

    useEffect(() => {

        if(!chosenMalfunctionType) return

            setMalfunctionTypes({
                malfunctionWith: chosenMalfunctionType.malfunctionWith,
                whatIsWrong:[...chosenMalfunctionType.whatIsWrong]
            })
        
    }, [chosenMalfunctionType])
    
    const [ wrongValue, setWrongValue ] = useState("")
    
    const AddPermission = (e) => {
      e.preventDefault();
    
      if(wrongValue){

        setMalfunctionTypes(prev => ({
            ...prev,
            whatIsWrong:[...prev.whatIsWrong, wrongValue]
          }))
      }
    
      setWrongValue("")
    }
    
    
    const handleSubmit = (e) => {
      e.preventDefault()
    
      dispatch(UpdateMalfunctionType( chosenMalfunctionType._id, malfunctionTypes))

      Clear()

    }

    const DeletePermit = (e) => {
        e.preventDefault()

        const permitName = e.target.getAttribute("name")

        setMalfunctionTypes(prev => ({
            ...prev,
            whatIsWrong:[...prev.whatIsWrong.filter(permit => permit !== permitName)]
          }))

    }

    const Clear = () => {

        setMalfunctionTypes({
            malfunctionWith: "",
            whatIsWrong: []
        })

        setChosenType("")
        setWrongValue("")
        setMalfunctionTypeUpdater(prev => !prev)
    }
    
    return(
            <div className="clientUserFormContainer">
                <div className="EditMokedWorkerCRUDTop">
                    <MalfunctionGroup malfunctionType={malfunctionType} chosenType={chosenType} setChosenType={setChosenType} />
                </div>
                    <form className="mokedWorkerForm" onSubmit= {handleSubmit}>
                    <div className="clientUserFormContainerTop">
                    <input type="text" className="mokedWorkerInput" value={malfunctionTypes.malfunctionWith} placeholder="סוג תקלה" onChange={(e) => setMalfunctionTypes({ ...malfunctionType, malfunctionWith: e.target.value })}/>
                        <input type="text" className="mokedWorkerInput" value={wrongValue} placeholder="טיב תקלה" onChange={(e) => setWrongValue( e.target.value )}/>
                    </div>
                    <div className="malfunctionTypeContainer">
                        <ul className="malfunctionTypeContainerList">
                        {malfunctionTypes.whatIsWrong && malfunctionTypes.whatIsWrong.map((permit, index) => {
                            return(
                            <li key={index} className="malfunctionItem" name={permit} onClick={DeletePermit}>{permit}</li>
                            )
                        })}
                        </ul>
                    </div>
                    <div className="buttonWrapperUserClient">
                        <button onClick={AddPermission} className="BottomFormMokedWorkersButton enterCRUDButton">הוספת טיב תקלה</button>
                        <input type="submit" className="BottomFormMokedWorkersButton editCRUDButton" value="ערוך תקלה" />
                    </div>
                </form>
            </div>
    )
}

const DeleteSiteMalfunctionCRUD = ({ setMalfunctionTypeUpdater, malfunctionType }) => {
    
    const dispatch = useDispatch();

    const [ chosenType, setChosenType ] = useState("")
    
    let chosenMalfunctionType = malfunctionType.find(type => type.malfunctionWith === chosenType)    

    const handleSubmit = (e) => {
      e.preventDefault()
    
      dispatch(DeleteMalfunctionType(chosenMalfunctionType._id))

      Clear()

    }

    const Clear = () => {

        setChosenType("")
        setMalfunctionTypeUpdater(prev => !prev)
    }
    
    return(
            <div className="clientUserFormContainer">
                <div className="EditMokedWorkerCRUDTop">
                    <MalfunctionGroup malfunctionType={malfunctionType} chosenType={chosenType} setChosenType={setChosenType} />
                </div>
                    <form className="mokedWorkerForm" onSubmit= {handleSubmit}>
                    <div className="buttonWrapperUserClient">
                        <input type="submit" className="BottomFormMokedWorkersButton deleteCRUDButton" value="מחק תקלה" />
                    </div>
                </form>
            </div>
    )
}