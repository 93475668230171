import React from 'react'

export default function FarmMalfunction(props) {

    const { chosenSiteInformation: information } = props

    const urgent = information.malfunctions[0].urgent
    const notUrgent = information.malfunctions[0].notUrgent
    const emailList = information.malfunctions[0].emailList

  return (
    <div className="malfunctionContainer farmMalfunctionLeft" >
            <h4 className="malfunctionTitle">תקלה משביתה- מצלמות/גדר, או גם וגם לא פועלים</h4>
            <ul className="siteInformationList">
                {urgent.map((urgItem, index) => {
                    return(
                        <li className="urgentItem" key={index}>{urgItem}</li>
                    )
                })}
            </ul>
            <h4 className="malfunctionTitle">כל תקלה שאינה משביתה- מצלמה מטושטשת, ראיית לילה, אזור גדר שלא עובד</h4>
            <ul className="siteInformationList">
                {notUrgent.map((notUrgItem, index) => {
                    return(
                        <li className="urgentItem" key={index}>{notUrgItem}</li>
                    )
                })}
            </ul>
            <div className="malfunctionTitleContainer">
                <h4 className="malfunctionTitle">רשימת האימיילים הרלוונטיים לאתר</h4>
                <button className="copyBtn" onClick={() => {navigator.clipboard.writeText(emailList)}}>העתק</button>
            </div>
            <ul className="siteInformationList">
                {emailList.map((emailListItem, index) => {
                    return(
                        <li className="urgentItem" key={index}>{emailListItem}</li>
                    )
                })}
            </ul>
        </div>
  )
}
