
import { CREATE_NEW_MOKEDSCANS, FETCH_ALL_MOKEDSCANS, UPDATE_MOKEDSCANS, DELETE_MOKEDSCANS } from "../constants/actionTypes"

const mokedScansReducer = ( mokedScans = [], action ) => {

    switch (action.type) {

        case CREATE_NEW_MOKEDSCANS:
            return [...mokedScans, action.payload]
        
        case FETCH_ALL_MOKEDSCANS:
            return action.payload
                
        case UPDATE_MOKEDSCANS:
            return mokedScans.map((e) => e._id === action.payload._id ? action.payload : e)
        
        case DELETE_MOKEDSCANS:
            return mokedScans.filter((e) => e._id !== action.payload)
            
        default:
            return mokedScans

    }
}
        
export default mokedScansReducer
        