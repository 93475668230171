import "./finishedTransformsLeft.css"
import FinishedTransformsList from "./FinishedTransformsList/FinishedTransformsList"

export default function FinishedTransformsLeft({ SortedFinishedTransformsByDateArray }) {


    return (
        <div className="ClientEntriesLeft">
            <div className="ClientEntriesLeftWrapper">
               {SortedFinishedTransformsByDateArray && 
               <>
               {SortedFinishedTransformsByDateArray.map((FinishedTransforms, index) => {
                   return(
                    <FinishedTransformsList key={index} FinishedTransforms={FinishedTransforms}/>
                   )
               })}
                </> }
            </div>
        </div>
    )
}

