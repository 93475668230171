import "./checkList.css"
import { useDispatch, useSelector } from "react-redux"
import { useEffect } from "react"
import { FetchAllCheckListEvents } from "../../actions/CheckListEvent"

export default function CheckList() {

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(FetchAllCheckListEvents())
        
    }, [dispatch])

    const checkList = useSelector((state) => state.checkListEvent)

    if(checkList.length === 0){
        return <p>אין מידע</p>
    }
    const checkListEvent = checkList[0]
    const checkListEvent1 = checkList[1]


  return (
    <div className="CheckListContainer" >
        <div className="CheckListCannabisContainer">
        <h2>{checkListEvent.title}</h2>
            <h3>{checkListEvent.subTitle}</h3>
            <ol className="primaryList"  >
            {checkListEvent.orders.map(order => {
                return(
                    <>
                        <li className="checkListItem"
                            key={order.order} >

                            {order.order} 
                        </li>
                        <ol className="secondaryList"  >
                            {order.subOrders.length > 0 &&
                            order.subOrders.map(subOrder => {
                                return(
                                    <li className="checkListItem"
                                        key={subOrder.subOrder} >

                                        {subOrder.subOrder}
                                    </li>
                                    )
                                })
                            }
                        </ol>
                    </>
                )
            })}
            </ol>

        </div>
        <div className="CheckListSolarContainer">
        <h2>{checkListEvent1.title}</h2>
            <h3>{checkListEvent1.subTitle}</h3>
            <ol className="primaryList"  >
            {checkListEvent1.orders.map(order => {
                return(
                    <>
                        <li className="checkListItem"
                            key={order.order} >

                            {order.order} 
                        </li>
                        <ol className="secondaryList"  >
                            {order.subOrders.length > 0 &&
                            order.subOrders.map(subOrder => {
                                return(
                                    <li className="checkListItem"
                                        key={subOrder.subOrder} >

                                        {subOrder.subOrder}
                                    </li>
                                    )
                                })
                            }
                        </ol>
                    </>
                )
            })}
            </ol>
        </div>
    </div>
  )
}

