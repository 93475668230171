import useAutocomplete from "@material-ui/lab/useAutocomplete";

export default function CheckListList( { setChosenValue, chosenValue, list, placeHolder, order } ) {
 
    let CreatorsNameList

    if(order){
      CreatorsNameList = list.map(list => {return list.order})
    }else{
      CreatorsNameList = list.map(list => {return list.title})
    }

    const { getRootProps, getInputProps, getListboxProps, getOptionProps, groupedOptions} = useAutocomplete({
        value: chosenValue || null,
        onChange:(event, newValue) => {setChosenValue(newValue)},
        openOnFocus: true,
        matchFrom: "start",
        options: CreatorsNameList,
        getOptionLabel: (option) => option
        
      });      

      return (
        <div className="editMokedWorkerInputEmployList">
          <div  {...getRootProps()}>
            <div className="siteNameInput">
                <input 
                className="entryFormInput entryFormInputEmployList editMokedWorkerInputEmployList" 
                placeholder={placeHolder}
                {...getInputProps()} />
            
            {groupedOptions.length > 0 ? (
                <ul className="creatorNameList" {...getListboxProps()}>
                {groupedOptions.map((option, index) => (
                    <li className="siteNameListItem" {...getOptionProps({ option, index })}>{option}</li>
                ))}
                </ul>
            ) : null}
            </div>
          </div>
        </div>
      );
    }