import React, { useState } from "react";
import MalfunctionDateFilter from "../MalfunctionsComponent/MalfunctionsMain/malfunctionDatePicker/malfunctionDateFilter";

function FiltrerScouts(props) {
  const { scouts, setFilteredScouts } = props;

  const [pickedSite, setPickedSite] = useState("");
  const [dateFilterOpen, setDatePickerOpen] = useState(false);
  let date = new Date();
  date.setMonth(date.getMonth() - 1);
  const [startDate, setStartDate] = useState(date);
  const [endDate, setEndDate] = useState(new Date());
  const [errors, setErrors] = useState({ 1: 1 });

  const siteChangeHandler = (value) => {
    if (!value) {
      setFilteredScouts(scouts);
      setPickedSite(value);
      return;
    }
    setPickedSite(value);
    const filteredScouts = scouts.filter((scout) => scout.siteName === value);
    setFilteredScouts(filteredScouts);
  };

  const filterByStartDate = (startDate) => {
    const startDateValue = new Date(new Date(startDate).setHours(0, 0, 0, 0));

    setStartDate(startDateValue);

    setFilteredScouts(
      scouts.filter((entry) => new Date(entry.date) - startDateValue >= 0)
    );
  };

  const filterByEndDate = (endDate) => {
    const endDateValue = new Date(new Date(endDate).setHours(23, 59, 59, 999));

    setEndDate(endDateValue);

    setFilteredScouts(
      scouts.filter((entry) => endDateValue - new Date(entry.date) >= 0)
    );
  };

  return (
    <div className="FiltrerScoutsContainer">
      <input
        className="groupMalfunctionListInput"
        type="text"
        list="siteSuggestion"
        size="50px"
        placeholder="חפש אתר"
        value={pickedSite}
        onChange={(e) => siteChangeHandler(e.target.value)}
      />
      <datalist id="siteSuggestion">
        {scouts &&
          scouts.map((site, index) => {
            return <option key={index}>{site.siteName}</option>;
          })}
      </datalist>
      <button
        className="scoutFilterBtn"
        onClick={() => setDatePickerOpen(!dateFilterOpen)}
      >
        סנן לפי תאריך
      </button>
      {dateFilterOpen && (
        <div className="scoutFilterDatePickerContainer">
          <MalfunctionDateFilter
            startDate={startDate}
            setStartDate={filterByStartDate}
            endDate={endDate}
            setEndDate={filterByEndDate}
            errors={errors}
            setErrors={setErrors}
          />
        </div>
      )}
    </div>
  );
}
export default FiltrerScouts;
