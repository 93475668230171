import "./finishedTransformsList.css"

import { useState } from "react"

import { ReactComponent as Document } from "../../../SVG/documentSVG.svg"
import FinishedTransformsFormFile from "./FinishedTransformsFormFile/FinishedTransformsFormFile"

export default function FinishedTransformsList({ FinishedTransforms }) {

    const [ transformsDate, transformsData ] = FinishedTransforms
    const [ openTransformsFile, setOpenTransformsFile ] = useState(false)
    const [  chosenTransformsFile, setChosenTransformsFile ] = useState("")

    return (
        <div className="finishedTransformsListContainer">
            <h3 className="transformsDateTitle">{transformsDate.slice(0, 6)}</h3>
            <div className="finishedTransformsListWrapper">
                {transformsData.map((data, index) => {
                    return(
                    <ul key={index} className="finishedTransformsList">
                        <li className="finishedTransformsListItem">
                            <div className="finishedTransformsListItemContainer">
                                <div className="finishedTransformsListItemRight">
                                    <p className="driverName">{data.driver}</p>
                                    <p className="endTransformsHour">תחילת שינוע: {data.created_At.slice(16, 21)}</p>
                                    <p className="endTransformsHour">סיום שינוע: {data.transformsEndHour.slice(16, 21)}</p>
                                </div>
                                <div className="finishedTransformsListItemLeft">
                                    <button className="openTransformsFormBtn" onClick={() =>{
                                         setOpenTransformsFile(true)
                                         setChosenTransformsFile(data._id)
                                        }}>
                                    הצג טופס שינוע
                                    </button>
                                    <Document className="transformIcon"/>
                                </div>
                            </div>
                        </li>
                   </ul>
                    )
                })}
            </div>
            {openTransformsFile && <FinishedTransformsFormFile setOpenTransformsFile={setOpenTransformsFile} transformsData={transformsData}
             chosenTransformsFile={chosenTransformsFile} setChosenTransformsFile={setChosenTransformsFile} />}
        </div>

    )
}