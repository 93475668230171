import axios from "axios";

// const API = axios.create({ baseUrl: "http://localhost:5001" });

const API = axios.create({ baseUrl: "http://206.81.30.115" });

API.interceptors.request.use((req) => {
  if (localStorage.getItem("profile")) {
    req.headers.authorization = `Bearer ${
      JSON.parse(localStorage.getItem("profile")).token
    }`;
  }

  return req;
});

export const fetchSiteInformation = (id) => API.get(`/siteInformation/${id}`);
export const createSiteInformation = (newSiteInformation) =>
  API.post("/siteInformation", newSiteInformation);
export const updateSiteInformation = (id, updatedSiteInformation) =>
  API.patch(`/siteInformation/${id}`, updatedSiteInformation);
export const deleteSiteInformation = (id) =>
  API.delete(`/siteInformation/${id}`);

export const createActiveEntry = (newActiveEntry) =>
  API.post("/activeEntries", newActiveEntry);
export const fetchAllActiveEntries = () => API.get("/activeEntries");
export const updateActiveEntry = (id, updatedEntry) =>
  API.patch(`/activeEntries/${id}`, updatedEntry);
export const deleteActiveEntry = (id) => API.delete(`/activeEntries/${id}`);

export const createMalfunction = (newMalfunction) =>
  API.post("/malfunction", newMalfunction);
export const fetchAllMalfunctions = (userName, isMoked) =>
  API.get(`/malfunction/${userName}/${isMoked}`);
export const updateMalfunction = (id, updatedMalfunction) =>
  API.patch(`/malfunction/${id}`, updatedMalfunction);
export const deleteMalfunction = (id) => API.delete(`/malfunction/${id}`);

export const createEntry = (id, activeEntry) =>
  API.post(`/entries/${id}`, activeEntry);
export const fetchClientEntry = (userName, isMoked) =>
  API.get(`/entries/${userName}/${isMoked}`);

export const createMalfunctionArchive = (id, malfunction) =>
  API.post(`/malfunctionArchive/${id}`, malfunction);
export const fetchMalfunctionArchive = (userName, isMoked) =>
  API.get(`/malfunctionArchive/${userName}/${isMoked}`);

export const signUp = (connectData) => API.post("/auth/signUp", connectData);
export const signIn = (connectData) => API.post("/auth/signIn", connectData);
export const editSignUp = (id, connectData) =>
  API.post(`/auth/editSignUp/${id}`, connectData);

export const fetchAllUserClient = () => API.get("/users");
export const updateUserClient = (id, updatedUser) =>
  API.patch(`/users/${id}`, updatedUser);
export const deleteUserClient = (id) => API.delete(`/users/${id}`);

export const createChatMessage = (newChatMessage) =>
  API.post("/chatMessage", newChatMessage);
export const fetchAllChatMessage = () => API.get("/chatMessage");
export const updateChatMessage = (id, updatedChatMessage) =>
  API.patch(`/chatMessage/${id}`, updatedChatMessage);
export const deleteChatMessage = (id) => API.delete(`/chatMessage/${id}`);

export const createShiftPage = (newShiftPage) =>
  API.post("/shiftPage", newShiftPage);
export const fetchAllShiftPage = () => API.get("/shiftPage");
export const updateShiftPage = (id, updatedShiftPage) =>
  API.patch(`/shiftPage/${id}`, updatedShiftPage);
export const deleteShiftPage = (id) => API.delete(`/shiftPage/${id}`);

export const createTransforms = (newTransforms) =>
  API.post("/activeTransforms", newTransforms);
export const fetchAllTransforms = () => API.get("/activeTransforms");
export const updateTransforms = (id, updatedTransforms) =>
  API.patch(`/activeTransforms/${id}`, updatedTransforms);
export const deleteTransforms = (id) => API.delete(`/activeTransforms/${id}`);

export const createFinishedTransforms = (id, activeTransforms) =>
  API.post(`/finishedTransforms/${id}`, activeTransforms);
export const fetchFinishedTransforms = () => API.get(`/finishedTransforms`);

export const createMokedWorker = (newMokedWorker) =>
  API.post("/mokedWorkers", newMokedWorker);
export const fetchAllMokedWorkers = () => API.get("/mokedWorkers");
export const updateMokedWorker = (id, updatedMokedWorker) =>
  API.patch(`/mokedWorkers/${id}`, updatedMokedWorker);
export const deleteMokedWorker = (id) => API.delete(`/mokedWorkers/${id}`);

export const createMalfunctionType = (newMalfunctionType) =>
  API.post("/malfunctionType", newMalfunctionType);
export const fetchAllMalfunctionTypes = () => API.get("/malfunctionType");
export const updateMalfunctionType = (id, updatedMalfunctionType) =>
  API.patch(`/malfunctionType/${id}`, updatedMalfunctionType);
export const deleteMalfunctionType = (id) =>
  API.delete(`/malfunctionType/${id}`);

export const createAccount = (accountData) => API.post("/phone", accountData);

export const createPopupMessage = (newPopupMessage) =>
  API.post("/popupMessage", newPopupMessage);
export const fetchAllPopupMessages = () => API.get("/popupMessage");
export const updatePopupMessage = (id, updatedPopupMessage) =>
  API.patch(`/popupMessage/${id}`, updatedPopupMessage);
export const deletePopupMessage = (id) => API.delete(`/popupMessage/${id}`);

export const createMokedScans = (newMokedScans) =>
  API.post("/mokedScans", newMokedScans);
export const fetchAllMokedScans = () => API.get("/mokedScans");
export const updateMokedScans = (id, updatedMokedScans) =>
  API.patch(`/mokedScans/${id}`, updatedMokedScans);
export const deleteMokedScans = (id) => API.delete(`/mokedScans/${id}`);

export const createMokedScansSchema = (newMokedScansSchema) =>
  API.post("/mokedScansSchema", newMokedScansSchema);
export const fetchAllMokedScansSchemas = () => API.get("/mokedScansSchema");
export const updateMokedScansSchema = (id, updatedMokedScansSchema) =>
  API.patch(`/mokedScansSchema/${id}`, updatedMokedScansSchema);
export const deleteMokedScansSchema = (id) =>
  API.delete(`/mokedScansSchema/${id}`);

export const createShiftPageType = (newShiftPageType) =>
  API.post("/shiftPageType", newShiftPageType);
export const fetchAllShiftPageTypes = () => API.get("/shiftPageType");
export const updateShiftPageType = (id, updatedShiftPageType) =>
  API.patch(`/shiftPageType/${id}`, updatedShiftPageType);
export const deleteShiftPageType = (id) => API.delete(`/shiftPageType/${id}`);

export const createImageList = (newImageList) =>
  API.post("/ImageList", newImageList);
export const fetchAllImageLists = () => API.get("/ImageList");
export const updateImageList = (id, updatedImageList) =>
  API.patch(`/ImageList/${id}`, updatedImageList);
export const deleteImageList = (id) => API.delete(`/ImageList/${id}`);

export const createCheckListEvent = (newCheckListEvent) =>
  API.post("/CheckListEvent", newCheckListEvent);
export const fetchAllCheckListEvents = () => API.get("/CheckListEvent");
export const updateCheckListEvent = (id, updatedCheckListEvent) =>
  API.patch(`/CheckListEvent/${id}`, updatedCheckListEvent);
export const deleteCheckListEvent = (id) => API.delete(`/CheckListEvent/${id}`);

export const createFarmsInformation = (newFarmsInformation) =>
  API.post("/FarmsInformation", newFarmsInformation);
export const fetchAllFarmsInformations = () => API.get("/FarmsInformation");
export const updateFarmsInformation = (id, updatedFarmsInformation) =>
  API.patch(`/FarmsInformation/${id}`, updatedFarmsInformation);
export const deleteFarmsInformation = (id) =>
  API.delete(`/FarmsInformation/${id}`);

export const createOtherSiteInformation = (newOtherSiteInformation) =>
  API.post("/OtherSiteInformation", newOtherSiteInformation);
export const fetchAllOtherSiteInformations = () =>
  API.get("/OtherSiteInformation");
export const updateOtherSiteInformation = (id, updatedOtherSiteInformation) =>
  API.patch(`/OtherSiteInformation/${id}`, updatedOtherSiteInformation);
export const deleteOtherSiteInformation = (id) =>
  API.delete(`/OtherSiteInformation/${id}`);

export const createScout = (newScout) => API.post("/Scout", newScout);
export const fetchAllScouts = () => API.get("/Scout");
export const updateScout = (id, updatedScout) =>
  API.patch(`/Scout/${id}`, updatedScout);
export const deleteScout = (id) => API.delete(`/Scout/${id}`);
