
import { CREATE_NEW_OTHERSITEINFORMATION, FETCH_ALL_OTHERSITEINFORMATIONS, UPDATE_OTHERSITEINFORMATION, DELETE_OTHERSITEINFORMATION } from "../constants/actionTypes"

import * as api from "../api"
        
export const CreateOtherSiteInformation = (newOtherSiteInformation) => async (dispatch) => {
        
    try {
        
        const { data } = await api.createOtherSiteInformation(newOtherSiteInformation)
        
        dispatch({ type: CREATE_NEW_OTHERSITEINFORMATION, payload: data })
            
    } catch (error) {
        
        console.log(error)
            
    }
}
        
export const FetchAllOtherSiteInformations = () => async (dispatch) => {

    try {
        
        const { data } = await api.fetchAllOtherSiteInformations()
        
        dispatch({ type: FETCH_ALL_OTHERSITEINFORMATIONS, payload: data })
            
    } catch (error) {
        
        console.log(error)
            
    }
}
        
export const UpdateOtherSiteInformation = (id, updatedOtherSiteInformation) => async (dispatch) => {

    try {
        
        const { data } = await api.updateOtherSiteInformation(id, updatedOtherSiteInformation)
        
        dispatch({ type: UPDATE_OTHERSITEINFORMATION, payload: data })
            
    } catch (error) {
        
        console.log(error)
            
    }

}
        
export const DeleteOtherSiteInformation = (id) => async (dispatch) => {

    try {
        
        await api.deleteOtherSiteInformation(id)
        
        dispatch({ type: DELETE_OTHERSITEINFORMATION, payload: id})
            
    } catch (error) {
        
        console.log(error)
            
    }

}
        