import "./finishedTransformsForms.css"

import { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { FetchFinishedTransforms } from "../../actions/finishedTransforms"

import FinishedTransformsRight from "../../components/FinishedTransformsComponents/FinishedTransformsRight/FinishedTransformsRight"
import FinishedTransformsLeft from "../../components/FinishedTransformsComponents/FinishedTransformsLeft/FinishedTransformsLeft"

export default function FinishedTransformsForms() {

    const dispatch = useDispatch()

    const [ chosenDriverName, setChosenDriverName ] = useState("הצג הכל")
    let date = new Date()
    date.setMonth(date.getMonth()-1)
    const [ startDate, setStartDate ] = useState(date)
    const [ endDate, setEndDate ] = useState(new Date())
    const [ errors, setErrors ] = useState({ 1:1 })


    useEffect(() => {

        dispatch(FetchFinishedTransforms())

    }, [dispatch])

    const finishedTransformsForms = useSelector((state) => state.finishedTransforms)

    const sortedDriversNameArray = finishedTransformsForms.reduce((r, a) => {
        r[a.driver] = r[a.driver] || []
        r[a.driver].push(a)
        return r
    }, Object.create(null))

    const SortedDriversNameArray = Object.entries(sortedDriversNameArray)

    const driversNameArray = SortedDriversNameArray.map((driver) => {return driver[0]})

    driversNameArray.unshift("הצג הכל")

    const filteredFinishedTransformsByDriver = chosenDriverName !== "הצג הכל" ? finishedTransformsForms.filter(form => {return form.driver === chosenDriverName}) : finishedTransformsForms

    let filteredFinishedTransformsDateSorted = null

    if(filteredFinishedTransformsByDriver){

        let EndDatePlus =  new Date(endDate)

        EndDatePlus.setDate(EndDatePlus.getDate()+0.5)

        let startDateSorted = filteredFinishedTransformsByDriver.filter((form) => new Date(form.created_At) - startDate >= 0)

        filteredFinishedTransformsDateSorted = startDateSorted.filter((form) => EndDatePlus - new Date(form.created_At) >= 0)

    }

    const sortedFinishedTransformsByDateArray = filteredFinishedTransformsDateSorted.reduce((r, a) => {
        r[a.created_At.slice(4,15)] = r[a.created_At.slice(4,15)] || []
        r[a.created_At.slice(4,15)].push(a)
        return r
    }, Object.create(null))

    const SortedFinishedTransformsByDateArray = Object.entries(sortedFinishedTransformsByDateArray)

    return (
        <>
            <div className="FinishedTransformsFormsContainer">

                    <FinishedTransformsRight driversNameArray={driversNameArray} chosenDriverName={chosenDriverName} setChosenDriverName={setChosenDriverName} 
                    filteredFinishedTransformsDateSorted={filteredFinishedTransformsDateSorted} filteredFinishedTransformsByDriver={filteredFinishedTransformsByDriver}
                    startDate={startDate} setStartDate={setStartDate} 
                    endDate={endDate} setEndDate={setEndDate} 
                    errors={errors} setErrors={setErrors} />

                    <FinishedTransformsLeft SortedFinishedTransformsByDateArray={SortedFinishedTransformsByDateArray} />

            </div>
        </>
    )
}
