import useAutocomplete from "@material-ui/lab/useAutocomplete";

export default function CreatorName( { siteNameValue, setSiteNameValue, sitesInformationName } ) {

    const { getRootProps, getInputProps, getListboxProps, getOptionProps, groupedOptions} = useAutocomplete({
        value: siteNameValue || null,
        onChange:(event, newValue) => {setSiteNameValue(newValue)},
        openOnFocus: true,
        matchFrom: "start",
        options: sitesInformationName,
        getOptionLabel: (option) => option
        
      });      

      return (
        <div className="editMokedWorkerInputEmployList">
          <div  {...getRootProps()}>
            <div className="siteNameInput">
                <input 
                className="entryFormInput entryFormInputEmployList editMokedWorkerInputEmployList" 
                placeholder="שם האתר שברצונך לערוך"
                {...getInputProps()} />
            
            {groupedOptions.length > 0 ? (
                <ul className="creatorNameList" {...getListboxProps()}>
                {groupedOptions.map((option, index) => (
                    <li className="siteNameListItem" {...getOptionProps({ option, index })}>{option}</li>
                ))}
                </ul>
            ) : null}
            </div>
          </div>
        </div>
      );
    }