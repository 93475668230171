import TopbarDynamic from "./components/Topbar/TopBarDynamic";
import DataProvider from "./context/DataContext";
import Main from "./Main";

function App() {
  return (
    <div>
      <TopbarDynamic />
      <DataProvider>
        <Main />
      </DataProvider>
    </div>
  );
}

export default App;
