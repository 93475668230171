import "./chosenMokedType.css";
import useAutocomplete from "@material-ui/lab/useAutocomplete";

export default function SiteNameInput({ chosenMokedType, setChosenMokedType }) {
  const CreatorsNameList = ["כל הכניסות", "מוקד1", "מוקד2"];

  const {
    getRootProps,
    getInputProps,
    getListboxProps,
    getOptionProps,
    groupedOptions,
  } = useAutocomplete({
    value: chosenMokedType || null,
    onChange: (event, newValue) => {
      setChosenMokedType(newValue);
    },
    openOnFocus: true,
    matchFrom: "start",
    options: CreatorsNameList,
    getOptionLabel: (option) => option,
  });

  return (
    <div>
      <div {...getRootProps()}>
        <div className="siteNameInput">
          <input
            className="entryFormInput entryFormInputEmployList"
            {...getInputProps()}
          />

          {groupedOptions.length > 0 ? (
            <ul className="creatorNameList" {...getListboxProps()}>
              {groupedOptions.map((option, index) => (
                <li
                  className="siteNameListItem"
                  {...getOptionProps({ option, index })}
                >
                  {option}
                </li>
              ))}
            </ul>
          ) : null}
        </div>
      </div>
    </div>
  );
}
