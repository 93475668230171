import "./transformsFormsLeft.css"

import { useState, useEffect, useRef } from "react"
import { useDispatch } from "react-redux"

import { confirmAlert } from "react-confirm-alert"
import "react-confirm-alert/src/react-confirm-alert.css"

import { UpdateTransforms, DeleteTransforms } from "../../../actions/transforms"

import EndTransforms from "./EndTransforms/EndTransforms"

export default function TransformsFormsLeft({ moked, chosenTransforms, transformId, setGetTransformsUpdater, setTransformId, isAlarmOpen, setIsAlarmOpen, setTypeAlarmOpen, typeAlarmOpen }) {

    const dispatch = useDispatch()

    const [ wayData, setWayData] = useState("")

    const [ isOpenEndTransform, setIsOpenEndTransform ] = useState(false)

    const [ update_Hour, setUpdate_Hour] = useState("")

    const [ objectData, setObjectData ] = useState({
        way_Data: "",
        update_Hour: ""
    })

    const [ editWayData, setEditWayData] = useState("")

    const [ editTransformsLine, setEditTransformsLine ] = useState({})

    const [ editObjectData, setEditObjectData ] = useState({
        way_Data: ""
    })

    const editInput = useRef()

    let now = new Date()

    const options = {
        weekday: "short",
        year: "numeric",
        month: "short",
        day: "2-digit",
        timeZone: 'Asia/Jerusalem',
        hour12: false,
        hour: '2-digit',
        minute:'2-digit',
        second: '2-digit' 
    }

    let localeNow = now.toLocaleString('en-US', options).replace(/,/g,"")

    let ArrayOfObjectData = chosenTransforms.data || []

    useEffect(() => {

        if(Object.keys(editTransformsLine).length > 0){

            setEditWayData(editTransformsLine.way_Data)

        }

    }, [editTransformsLine])

    useEffect(() => {

        setEditObjectData({
            way_Data: editWayData
        })

    }, [editWayData])

    useEffect(() => {

        setObjectData({
            way_Data: wayData,
            update_Hour: update_Hour
        })

    }, [wayData, update_Hour])

    const AddTransformsData = (e) => {

        e.preventDefault()

        if(Object.keys(editTransformsLine).length > 0){

            if(editObjectData.way_Data){
                ArrayOfObjectData.filter(objData => {
                    if(objData._id === editTransformsLine._id){
                        objData.way_Data = editObjectData.way_Data
                    }

                    return objData
                })
            }
            } else {

                if(objectData.way_Data){
                    ArrayOfObjectData.push(objectData)
                }    
            }

        chosenTransforms.data = [...ArrayOfObjectData]

        dispatch(UpdateTransforms(transformId, chosenTransforms))

        setWayData("")

        setEditTransformsLine({})

        setGetTransformsUpdater(false)

    }

    return (
            <div className="TransformsFormsLeftContainer">
                <div className="TransformsFormsLeftTop">
                    <p className="dateTransforms">{chosenTransforms.created_At.slice(4,10)+" - "+chosenTransforms.created_At.slice(16,21)}</p>
                    {moked && 
                    <button className="deleteBtn" onClick={() => {

                        confirmAlert({
                            title: <p className="approveTitle">אשר לביצוע הפעולה</p>,
                            message: <p className="approveMessage">האם אתה בטוח שאתה מעוניין למחוק את הטופס?</p>,
                            buttons: [
                            {
                                label: <p>בטוח</p>,
                                onClick: () => {
                                    setGetTransformsUpdater(false)
                                    setTransformId("")
                                    setTypeAlarmOpen("deleteAlarm")
                                    setIsAlarmOpen(true)
                                    dispatch(DeleteTransforms(transformId))
                                }
                            },
                            {
                                label: <p>בטל</p>
                            }
                            ]
                        })

                    }}>מחק טופס שינוע</button>
                    }
                </div>
                <div className="TransformsFormsLeftTopUnder">
                    <h2 className="TransformsInformation"> נהג שינוע - {chosenTransforms.driver}</h2>
                    <h3 className="TransformsInformation">מאבטחי שינוע:</h3>
                    <ul className="activeEntryInfoList transformsInfoList">
                        {chosenTransforms.guards.map((guard) => {
                            return(
                            <li key={guard._id} className="activeEntryInfoItem guardsNameInformation">{guard.guards_name}</li>
                            )
                        })}

                    </ul>
                    <div className="transformsHrContainer">
                         <hr className="transformsHr" />
                    </div>
                </div>
                <div className="TransformsFormsLeftMiddle">
                    <ul className="transformDataList">
                        {Object.keys(chosenTransforms.data).length > 0 && chosenTransforms.data.map((transform) => {
                            return(
                                <div key={transform._id} className="transformsDataLine">
                                    <span className="transformDataDateItem">{transform.update_Hour.slice(16,21)}</span>
                                    <li className="transformDataItem" onClick={(e) => {
                                        setEditTransformsLine(transform)
                                        }} >{transform.way_Data}</li>
                                </div>
                            )
                        })}
                    </ul>
                </div>
                {moked &&
                <>
                 <div className="TransformsFormsLeftBottom">
                    {Object.keys(editTransformsLine).length === 0 ? 
                        <form className="transformsDataForm" onSubmit={AddTransformsData}>
                            <input 
                            type="text" 
                            className="transformsDataInput" 
                            value={wayData} 
                            onChange={e => {
                                setWayData(e.target.value) 
                                setUpdate_Hour(localeNow)}} 
                                />
                            <button className="transformsDataBtn" type="submit">הכנס</button>
                        </form> : 
                        <form className="transformsDataForm" onSubmit={AddTransformsData}>
                            <input 
                            type="text" 
                            className="transformsDataInput" 
                            value={editWayData} 
                            onChange={e => {
                                setEditWayData(e.target.value) 
                            }} 
                            ref={editInput}
                            />
                            <button className="transformsDataBtn" type="submit">הכנס</button>
                        </form>}
                </div>
                <div className="TransformsFormsLeftFooter">
                    <button className="endTransformBtn" onClick={() => {
                        chosenTransforms.transformsEndHour = localeNow
                        setIsOpenEndTransform(true)
                    }
                    } >סיום שינוע</button>
                    <EndTransforms setIsOpenEndTransform={setIsOpenEndTransform} chosenTransforms={chosenTransforms} isOpenEndTransform={isOpenEndTransform}
                     transformId={transformId} setTransformId={setTransformId} setGetTransformsUpdater={setGetTransformsUpdater}
                     isAlarmOpen={isAlarmOpen} setIsAlarmOpen={setIsAlarmOpen} setTypeAlarmOpen={setTypeAlarmOpen} typeAlarmOpen={typeAlarmOpen} />
                </div>
                </>
                }
        </div>
    )
}
