
import { CREATE_NEW_POPUPMESSAGE, FETCH_ALL_POPUPMESSAGES, UPDATE_POPUPMESSAGE, DELETE_POPUPMESSAGE } from "../constants/actionTypes"

const popupMessageReducer = ( popupMessage = [], action ) => {

    switch (action.type) {

        case CREATE_NEW_POPUPMESSAGE:
            return [...popupMessage, action.payload]
        
        case FETCH_ALL_POPUPMESSAGES:
            return action.payload
                
        case UPDATE_POPUPMESSAGE:
            return popupMessage.map((e) => e._id === action.payload._id ? action.payload : e)
        
        case DELETE_POPUPMESSAGE:
            return popupMessage.filter((e) => e._id !== action.payload)
            
        default:
            return popupMessage

    }
}
        
export default popupMessageReducer
        