import { useRef } from "react"
import { PersonAdd, NavigateBefore } from "@material-ui/icons"

export default function EmployList({guardsArray, setGuardsArray}) {

    const guardName = useRef("")

    const ClickHandler = (e) => {

        e.preventDefault()
        
            if(guardName.current.value !== ""){
                setGuardsArray(
                [...guardsArray,
                    {guards_name: guardName.current.value,
                    guards_Hours: "" }])
            }

        guardName.current.value = ""
    }

    const deleteEmploy = (e) => {

        const guardName = e.target.getAttribute("name")

        setGuardsArray(guardsArray.filter(guard => guard.guards_name !== guardName))
    }

    return (
        <>
            <ul className="employListItem">
                {guardsArray.map((guard, index) => {
                    return (
                    <li key={index} name={guard.guards_name} className="employItem" onClick={deleteEmploy}>
                        <NavigateBefore className="employItemIcon"/>
                        {guard.guards_name}
                    </li>
                    )
                })}
            </ul>
            <div className="employList">
                <input type="text" placeholder="הכנס את שם העובד" 
                className="entryFormInput entryFormInputEmployList" 
                ref = {guardName}
                />
                <button onClick={ClickHandler} className="personAddButton"><PersonAdd className="personAddIcon"/></button>
            </div>
         </>
    )
}
