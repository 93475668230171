import { CREATE_NEW_TRANSFORMS, FETCH_ALL_TRANSFORMS, UPDATE_TRANSFORMS, DELETE_TRANSFORMS } from "../constants/actionTypes"

import * as api from "../api"

export const CreateTransforms = (newTransforms) => async (dispatch) => {

  try {

    const { data } = await api.createTransforms(newTransforms)

    dispatch({ type: CREATE_NEW_TRANSFORMS, payload: data })
    
  } catch (error) {

    console.log(error)
    
  }
}

export const FetchAllTransforms = () => async (dispatch) => {
  try {

    const { data } = await api.fetchAllTransforms()

    dispatch({ type: FETCH_ALL_TRANSFORMS, payload: data })
    
  } catch (error) {

    console.log(error)
    
  }
}

export const UpdateTransforms = (id, updatedTransforms) => async (dispatch) => {
  try {

    const { data } = await api.updateTransforms(id, updatedTransforms)

    dispatch({ type: UPDATE_TRANSFORMS, payload: data })
    
  } catch (error) {

    console.log(error)
    
  }
}

export const DeleteTransforms = (id) => async (dispatch) => {
  try {

    await api.deleteTransforms(id)

    dispatch({ type: DELETE_TRANSFORMS, payload: id})
    
  } catch (error) {

    console.log(error)
    
  }
}