import "./rightBar.css"

import { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"


import ShiftPage from "./shiftPage/ShiftPage"
import ShiftChat from "./ShiftChat/ShiftChat"

import { DeleteChatMessage } from "../../actions/chatMessage"


export default function RightBar({ socket, user, setChatMessageUpdater, shiftPageType }) {

    const dispatch = useDispatch()

    const [ openShiftPageWindow, setOpenShiftPageWindow ] = useState(false)

    const Messages = useSelector((state) => state.chatMessage)

    useEffect(() => {

        if(Messages.length > 25){

            const MessagesId = Messages[0]._id

            dispatch(DeleteChatMessage(MessagesId))
        }

    }, [Messages, dispatch])

    return (
        <div className="rightBar">
            <div className="rightBarContainer" >
                <button className="openShiftPage" onClick={() => setOpenShiftPageWindow(true)} >פתח דוחות חפיפת משמרת</button>
                {openShiftPageWindow && <ShiftPage shiftPageType={shiftPageType} setOpenShiftPageWindow={setOpenShiftPageWindow} />}
            </div>
            <div className="RightBarWrapper">
                <ShiftChat user={user} Messages={Messages} setChatMessageUpdater={setChatMessageUpdater} socket={socket} />
            </div>
        </div>
    )
}
