
import { CREATE_NEW_IMAGELIST, FETCH_ALL_IMAGELISTS, UPDATE_IMAGELIST, DELETE_IMAGELIST } from "../constants/actionTypes"

const ImageListReducer = ( ImageList = [], action ) => {

    switch (action.type) {

        case CREATE_NEW_IMAGELIST:
            return [...ImageList, action.payload]
        
        case FETCH_ALL_IMAGELISTS:
            return action.payload
                
        case UPDATE_IMAGELIST:
            return ImageList.map((e) => e._id === action.payload._id ? action.payload : e)
        
        case DELETE_IMAGELIST:
            return ImageList.filter((e) => e._id !== action.payload)
            
        default:
            return ImageList

    }
}
        
export default ImageListReducer
        