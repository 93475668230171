import "./auth.css";

import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import MokedSignIn from "./AuthComponents/MokedSignIn";
import ClientSignIn from "./AuthComponents/ClientSignIn";

const AuthConnect = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const user = JSON.parse(localStorage.getItem("profile"));

  const [isMokedUser, setIsMokedUser] = useState(false);

  const logoutHandler = (e) => {
    e.preventDefault();

    dispatch({ type: "LOGOUT" });

    history.push("/");
  };

  const switchLoginModeButtonText = isMokedUser ? "לקוח חברה" : "משתמש מוקד";

  return (
    <div>
      {!user && (
        <button
          className="signInButton fadeIn second"
          onClick={() => setIsMokedUser((prev) => !prev)}
        >
          {switchLoginModeButtonText}
        </button>
      )}
      {!user ? (
        isMokedUser ? (
          <MokedSignIn />
        ) : (
          <ClientSignIn />
        )
      ) : (
        <div className="wrapper fadeInDown">
          <div className="fadeIn first">
            <h1>הינך מחובר למערכת</h1>
          </div>
          <div>
            <button
              type="submit"
              className="input fadeIn second"
              onClick={logoutHandler}
            >
              התנתק
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default AuthConnect;
