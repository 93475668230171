import React from 'react'
import { CreateNewDay } from './CreateNewDay'

export default function MokedScansTop({timeOfDay, setTimeOfDay, mokedSize, setMokedSize, mokedType, setMokedType, mokedScansSchemaData, currentDate}) {
  
    const mokedScansSchemaSize = mokedScansSchemaData.filter(schema => schema.mokedSize === mokedSize)

    return (
    <div className="mokedScansTopContainer">
        <span className="malfunctionGroupTitle">סריקות מוקד  {currentDate?.slice(4, 10)}</span> 
        <div className='mokedScansSelectContainer'>
            <CreateNewDay/>
        </div>
        <div className='mokedScansSelectContainer'>
            <select className="mokedScansSelect" value={mokedSize} onChange={(e) => setMokedSize(e.target.value)}>
                <option value="" disabled hidden>בחר מוקד</option>
                <option>מוקד גדול</option>
                <option>מוקד קטן</option>
            </select>
            <select className="mokedScansSelect" value={mokedType} onChange={(e) => setMokedType(e.target.value)}>
                <option value="" disabled hidden>בחר עמדה</option>
                <option>אשכול</option>
                <option>נתיבות</option>
            </select>
            <select className="mokedScansSelect" value={timeOfDay} onChange={(e) => setTimeOfDay(e.target.value)}>
                <option value="" disabled hidden>בחר משמרת</option>
                {mokedScansSchemaSize?.map((shiftData, index) => {
                    return <option key={index}>{shiftData.timeOfDay}</option>
                })}
            </select>
        </div>
    </div>
  )
}
