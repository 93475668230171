import { CREATE_NEW_TRANSFORMS, FETCH_ALL_TRANSFORMS, UPDATE_TRANSFORMS, DELETE_TRANSFORMS } from "../constants/actionTypes"

const transformsReducer = ( transforms = [], action ) => {
    switch (action.type) {
        case CREATE_NEW_TRANSFORMS:
            return [...transforms, action.payload]

        case FETCH_ALL_TRANSFORMS:
            return action.payload
        
        case UPDATE_TRANSFORMS:
            return transforms.map((transform) => transform._id === action.payload._id ? action.payload : transform)

        case DELETE_TRANSFORMS:
            return transforms.filter((transform) => transform._id !== action.payload)
    
        default:
            return transforms
    }
}

export default transformsReducer