import './shiftPage.css'
import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FetchAllShiftPage } from '../../../actions/shiftPage'

import { Close } from "@material-ui/icons"
import ShiftPageSection from './shiftPageSection/ShiftPageSection'


export default function ShiftPage({ setOpenShiftPageWindow, shiftPageType }) {

    const dispatch = useDispatch()

    const [ isBigMoked, setIsBigMoked ] = useState(true)

    useEffect(() => {

        dispatch(FetchAllShiftPage())

    }, [dispatch])

    const shiftData = useSelector(store => store.shiftPage)

    const filterShiftData = shiftData.filter(data => data.mokedType === isBigMoked)
    
  return (
    <div className="pageShift fadeInDown"> 
    <div className='pageShiftOuter' >
        <div onClick={() => setIsBigMoked(true)} className={!isBigMoked ? 'outerShiftButtons outerShiftButtonsPressed' : 'outerShiftButtons' }><h4>מוקד גדול</h4></div>
        <div onClick={() => setIsBigMoked(false)} className={isBigMoked ? 'outerShiftButtons outerShiftButtonsPressed' : 'outerShiftButtons' } ><h4>מוקד קטן</h4></div>
    </div>
    <div className="pageShiftInner fadeIn">
        <button className="endTransformsInnerExit" onClick={(e) => {
            setOpenShiftPageWindow(false) 
            }}>
            <Close/>
        </button>
        <div className="pageShiftContainer">
            {shiftPageType.map(type => {
                return <ShiftPageSection key={type._id} type={type.type} isBigMoked={isBigMoked} filterShiftData={filterShiftData} />

            })}
        </div>
    </div>
    </div>
  )
}
