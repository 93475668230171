import "./popUpMessages.css"

import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { CreatePopupMessage, UpdatePopupMessage, DeletePopupMessage } from "../../../actions/popupMessage";
import SiteNameInput from "../../MalfunctionsComponent/MalfunctionsMain/MalfunctionWindow/SiteNameInput/SiteNameInput";

const options = {
    weekday: "short",
    year: "numeric",
    month: "short",
    day: "2-digit",
    timeZone: 'Asia/Jerusalem',
    hour12: false,
    hour: '2-digit',
    minute:'2-digit',
    second: '2-digit' 
}

const PopupMessages = ({ chosenCURDType, fetchMokedWorkers, setMokedWorkerUpdater }) => {

const [ messageValue, setMessageValue ] = useState("")

const [message, setMessage] = useState({
  value: "",
  date:"",
  dayRepeat: [],
  hourRepeat: ''
});
const [chosenTime, setChosenTime] = useState('')

return(
  <>
    { chosenCURDType && <div className="mokedWorker">
      <div className="mokedWorkerWrapperUserClient">
        <div className="mokedWorkerRight">
          <h1 className="mokedWorkerTitle">{chosenCURDType}</h1>
        </div>
          <div className="mokedWorkerLeft">
            {chosenCURDType === "רישום" ?
            <CreatePopupMessages message={message} setMessage={setMessage} options={options} setMokedWorkerUpdater={setMokedWorkerUpdater} chosenTime={chosenTime} setChosenTime={setChosenTime} />
            : chosenCURDType === "עריכה" ?
            <EditPopupMessages fetchMokedWorkers={fetchMokedWorkers} messageValue={messageValue} setMessageValue={setMessageValue}
             message={message} setMessage={setMessage} setMokedWorkerUpdater={setMokedWorkerUpdater} />
             :
             <DeletePopupMessages fetchMokedWorkers={fetchMokedWorkers} messageValue={messageValue} setMessageValue={setMessageValue}
             message={message} setMessage={setMessage} setMokedWorkerUpdater={setMokedWorkerUpdater} />
            }
          </div>
        </div>
      </div>}
      </>
      )
}

export default PopupMessages;


const CreatePopupMessages = ({ message, setMessage, setMokedWorkerUpdater, chosenTime, setChosenTime }) => {

    const [hourRepeat, setHourRepeat] = useState('')
    const repeatHoursOptions = ['כל שעה', 'כל שעתיים', 'כל ארבע שעות', 'כל שמונה שעות']
    const [dayRepeat, setDayRepeat] = useState('')
    const repeatDaysOptions = ['ראשון', 'שני', 'שלישי', 'רביעי', 'חמישי', 'שישי', 'שבת',]

  const dispatch = useDispatch();

  useEffect(() => {

      let now = new Date(chosenTime);
    
      const formattedNow = now.toLocaleString('en-US', options).replace(/,/g,"")

      setMessage(prev => ({
        ...prev,
        date: formattedNow,
        hourRepeat: hourRepeat,
    }))

  }, [chosenTime, setMessage, hourRepeat])

  const AddPermission = (e) => {
    e.preventDefault();

    if(dayRepeat){

        setMessage(prev => ({
          ...prev,
          dayRepeat:[...prev.dayRepeat, dayRepeat]
        }))
    }
  
    setDayRepeat("")
  }

  const handleSubmit = (e) => {
    e.preventDefault()

    if(message.value && message.date) {

      dispatch(CreatePopupMessage(message))
  
      Clear()
    }

  }

  const DeletePermit = (e) => {
    e.preventDefault()

    const dayName = e.target.getAttribute("name")

    setMessage(prev => ({
        ...prev,
        dayRepeat:[...prev.dayRepeat.filter(day => day !== dayName)]
      }))

}
  
  const Clear = () => {
  
    setMessage({
        value: "",
        date:"",
        dayRepeat: [],
        hourRepeat: ''
    })

    setChosenTime('')

    setHourRepeat('')

    setDayRepeat('')

    setMokedWorkerUpdater(prev => !prev)
  
  }

  return(
    <div className="clientUserFormContainer">
        <form className="mokedWorkerForm" onSubmit= {handleSubmit}>
            <input type="text" id="login" className="mokedWorkerInput" name="login" value={message.value} placeholder="הודעת ההתראה" onChange={(e) => setMessage({ ...message, value: e.target.value })}/>
            <h4>יש לבחור שעה עגולה</h4>
            <input type="dateTime-local" className="mokedWorkerInput" value={chosenTime}
                onChange={(e) => setChosenTime(e.target.value )} />
                <div className="inputSectionPopUpMessages" >
                    <h4 className="popupMainTitle" >בחירת ימים בהם ההתראה תעבוד (אופציונלי)</h4>
                    <SiteNameInput  wantedValue={dayRepeat} setWantedValue={setDayRepeat} searchGroup={repeatDaysOptions} placeHolder='יום הפעלת ההתראה'/>
                    <div className="popupFormContainerPermission">
                        <ul className="clientUserFormPermissionList">
                            {message.dayRepeat && message.dayRepeat.map((day, index) => {
                                return(
                                    <li key={index} className="permitItem" name={day} onClick={DeletePermit}>{day}</li>
                                )
                            })}
                        </ul>
                    </div>
                    <h4 className="popupMainTitle" >כל כמה זמן ההתראה תחזור על עצמה (אם לא יבחר יום ולא חזרתיות ההתראה תופיע באופן חד פעמי)</h4>
                    <SiteNameInput wantedValue={hourRepeat} setWantedValue={setHourRepeat} searchGroup={repeatHoursOptions} placeHolder='חזרתיות ההתראה' />
                </div>
            <div className="BottomFormMokedWorkers">
                <div className="BottomFormMokedWorkers">
                <button onClick={AddPermission} className="BottomFormMokedWorkersButton enterCRUDButton">הוספת יום</button>

                <input type="submit" className="BottomFormMokedWorkersButton enterCRUDButton" value="הכנס התראה" />
                </div>
            </div>
        </form>
    </div>
  )
}

const EditPopupMessages = ({ fetchMokedWorkers, messageValue, setMessageValue, message, setMessage, setMokedWorkerUpdater }) => {

  const dispatch = useDispatch();

  const chosenMokedWorker = fetchMokedWorkers.find(worker => worker.userName === messageValue)
    
  const handleSubmit = (e) => {
    e.preventDefault()

    if(message.userName && chosenMokedWorker) {
  
    dispatch( UpdatePopupMessage( chosenMokedWorker._id, message ))
  
    Clear()

    }
  }
  
  const Clear = () => {
  
    setMessage({
      userName: "",
      isAhmash:false
    })
    
    setMokedWorkerUpdater(prev => !prev)
    setMessageValue("")
  }

  return(
    <>
      <div className="EditMokedWorkerCRUDTop">
      </div>
      <form className="mokedWorkerForm" onSubmit= {handleSubmit}>
        <input type="text" id="login" className="mokedWorkerInput" name="login" value={message.userName} placeholder="שם העובד" onChange={(e) => setMessage({ ...message, userName: e.target.value })}/>
        <div className="BottomFormMokedWorkers">
          <div className="BottomFormMokedWorkersAhmash">
            <p className="ahmashTitle">האם העובד הוא אחמ"ש?</p>
            <input type="checkbox" className="transformsFormCheck" onChange={(e) => setMessage((prev) => ({...prev, isAhmash: !message.isAhmash}))} />
          </div>
          <div className="BottomFormMokedWorkers">
            <input type="submit" className="BottomFormMokedWorkersButton editCRUDButton" value="ערוך עובד" />
          </div>
        </div>
      </form>   
   </>
  )
}

const DeletePopupMessages = ({ fetchMokedWorkers, messageValue, setMessageValue, message, setMessage, setMokedWorkerUpdater }) => {

  const dispatch = useDispatch();

  const chosenMokedWorker = fetchMokedWorkers.find(worker => worker.userName === messageValue)
    
  const handleSubmit = (e) => {
    e.preventDefault()

    if(chosenMokedWorker) {
  
    dispatch( DeletePopupMessage( chosenMokedWorker._id ))
  
    Clear()

    }
  }
  
  const Clear = () => {
    
    setMokedWorkerUpdater(prev => !prev)
    setMessageValue("")
  }

  return(
    <>
      <div className="EditMokedWorkerCRUDTop">
      </div>
      <form className="mokedWorkerForm" onSubmit= {handleSubmit}>
        <div className="BottomFormMokedWorkers">
          <div className="BottomFormMokedWorkers">
            <input type="submit" className="BottomFormMokedWorkersButton deleteCRUDButton" value="מחק עובד" />
          </div>
        </div>
      </form>   
   </>
  )
}