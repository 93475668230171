
import { CREATE_NEW_IMAGELIST, FETCH_ALL_IMAGELISTS, UPDATE_IMAGELIST, DELETE_IMAGELIST } from "../constants/actionTypes"

import * as api from "../api"
        
export const CreateImageList = (newImageList) => async (dispatch) => {
        
    try {
        
        const { data } = await api.createImageList(newImageList)
        
        dispatch({ type: CREATE_NEW_IMAGELIST, payload: data })
            
    } catch (error) {
        
        console.log(error)
            
    }
}
        
export const FetchAllImageLists = () => async (dispatch) => {

    try {
        
        const { data } = await api.fetchAllImageLists()
        
        dispatch({ type: FETCH_ALL_IMAGELISTS, payload: data })
            
    } catch (error) {
        
        console.log(error)
            
    }
}
        
export const UpdateImageList = (id, updatedImageList) => async (dispatch) => {

    try {
        
        const { data } = await api.updateImageList(id, updatedImageList)
        
        dispatch({ type: UPDATE_IMAGELIST, payload: data })
            
    } catch (error) {
        
        console.log(error)
            
    }

}
        
export const DeleteImageList = (id) => async (dispatch) => {

    try {
        
        await api.deleteImageList(id)
        
        dispatch({ type: DELETE_IMAGELIST, payload: id})
            
    } catch (error) {
        
        console.log(error)
            
    }

}
        