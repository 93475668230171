import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import MokedScansMain from '../../components/MokedScans/MokedScansMain/MokedScansMain'
import MokedScansTop from '../../components/MokedScans/MokedScansTop/MokedScansTop'
import './mokedScans.css'

export default function MokedScans() {
    const [timeOfDay, setTimeOfDay] = useState('')
    const [mokedSize, setMokedSize] = useState('')
    const [mokedType, setMokedType] = useState('')
    const [mokedScanSites, setMokedScanSites] = useState({})
    const [mokedScansData, setMokedScansData] = useState({})

    const mokedScansSchemaData = useSelector((state) => state.mokedScans)
    
    useEffect(() => {
        if(!timeOfDay || !mokedSize || !mokedType) return
        const mokedSchemaSize = mokedScansSchemaData.filter(schema => schema.mokedSize === mokedSize)
        const mokedSchemaTimeOfDay = mokedSchemaSize.find(schema => schema.timeOfDay === timeOfDay)
        const mokedSchemaType = mokedSchemaTimeOfDay.sitesToScan.filter(schema => schema.mokedType === mokedType)

        setMokedScanSites({
            ...mokedSchemaTimeOfDay,
            sitesToScan: [...mokedSchemaType],
        })
        setMokedScansData(mokedSchemaTimeOfDay)
        
    }, [timeOfDay, mokedSize, mokedType, mokedScansSchemaData])

    const mokedScansTopAttribute = {
        timeOfDay,
        setTimeOfDay,
        mokedType,
        setMokedType,
        mokedSize, 
        setMokedSize,
        mokedScansSchemaData
    }

  return (
    <div className='mokedScansContainer' >
        <MokedScansTop {...mokedScansTopAttribute} currentDate={mokedScanSites.currentDate}  />
        <MokedScansMain mokedScanSites={mokedScanSites} mokedScansSchemaData={mokedScansData} />
    </div>
  )
}
