import { CREATE_NEW_ACTIVE_ENTRY, FETCH_ALL_ACTIVE_ENTRIES, UPDATE_ACTIVE_ENTRY, DELETE_ACTIVE_ENTRY } from "../constants/actionTypes"

import * as api from "../api"

export const CreateActiveEntry = (newActiveEntry) => async (dispatch) => {

  try {

    const { data } = await api.createActiveEntry(newActiveEntry)

    dispatch({ type: CREATE_NEW_ACTIVE_ENTRY, payload: data })
    
  } catch (error) {

    console.log(error)
    
  }
}

export const FetchAllActiveEntries = () => async (dispatch) => {
  try {

    const { data } = await api.fetchAllActiveEntries()

    dispatch({ type: FETCH_ALL_ACTIVE_ENTRIES, payload: data })
    
  } catch (error) {

    console.log(error)
    
  }
}

export const UpdateActiveEntry = (id, updatedEntry) => async (dispatch) => {
  try {

    const { data } = await api.updateActiveEntry(id, updatedEntry)

    dispatch({ type: UPDATE_ACTIVE_ENTRY, payload: data })
    
  } catch (error) {

    console.log(error)
    
  }
}

export const DeleteActiveEntry = (id) => async (dispatch) => {
  try {

    await api.deleteActiveEntry(id)

    dispatch({ type: DELETE_ACTIVE_ENTRY, payload: id})
    
  } catch (error) {

    console.log(error)
    
  }
}