import EntryForm from "../EntryForm/EntryForm";

import { useEffect, useRef, useState } from "react";
import ActiveEntryItem from "./ActiveEntriesItem/ActiveEntriesItem";
import ChosenMokedType from "./ChosenMokedType/ChosenMokedType";
import "./feed.css";

export default function Feed({
  sitesInformation,
  siteNameValue,
  setSiteNameValue,
  fetchActiveEntries,
  setPickedSite,
  activeEntryEditId,
  setActiveEntryEditId,
  setGetActiveEntriesUpdater,
  mokedWorkersName,
}) {
  const chosenMokedType = useRef("כל הכניסות");
  const [activeEntries, setActiveEntries] = useState([]);

  const mokedTypes = {
    "כל הכניסות": "כל הכניסות",
    מוקד1: "אשכול",
    מוקד2: "נתיבות",
  };

  const handleActiveEntriesType = (type) => {
    chosenMokedType.current = type;

    const mokedType = mokedTypes[type];

    if (mokedType === "כל הכניסות") {
      setActiveEntries(fetchActiveEntries);
    } else {
      const entries = fetchActiveEntries.filter((entry) => {
        return entry[1][0]?.mokedType === mokedType;
      });

      setActiveEntries(entries);
    }
  };

  useEffect(() => {
    setActiveEntries(fetchActiveEntries);
  }, [fetchActiveEntries]);

  return (
    <div className="feed">
      <div className="feedWrapper">
        <EntryForm
          sitesInformation={sitesInformation}
          siteNameValue={siteNameValue}
          mokedWorkersName={mokedWorkersName}
          setSiteNameValue={setSiteNameValue}
          setPickedSite={setPickedSite}
          activeEntryEditId={activeEntryEditId}
          setActiveEntryEditId={setActiveEntryEditId}
          setGetActiveEntriesUpdater={setGetActiveEntriesUpdater}
        />
        <ChosenMokedType
          chosenMokedType={chosenMokedType.current}
          setChosenMokedType={handleActiveEntriesType}
        />
        <div className="activeEntryListItem">
          {activeEntries.map((activeEntry, index) => {
            return (
              <ActiveEntryItem
                key={index}
                activeEntry={activeEntry}
                setPickedSite={setPickedSite}
                setSiteNameValue={setSiteNameValue}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
}
