import "./guardsWorkTime.css"

import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"

export default function GuardsWorkTime({ startDate, setStartDate, endDate, setEndDate, setGuardsHours }) {

    return (

        <div className="EntryHistoryDatePicker guardsDatePicker">
            <div className="startDatePicker">
                <p className="startDatePickerTitle">בחר שעת התחלה</p>
                <DatePicker className="datePickerItem"
                selected={startDate} 
                showTimeSelect showTimeSelectOnly timeCaption="שעה:" timeIntervals={15} timeFormat="HH:mm" dateFormat="H:mm" 
                onChange={(date) => setStartDate(date)} />
             </div>
             <div className="endDatePicker">
                <p className="startDatePickerTitle">בחר שעת סיום</p>
                <DatePicker className="datePickerItem" 
                selected={endDate} 
                showTimeSelect showTimeSelectOnly timeCaption="שעה:" timeIntervals={15} timeFormat="HH:mm" dateFormat="H:mm"
                onChange={(date) => setEndDate(date)}/>
             </div>
        </div>
    )
}
