import { CREATE_MOKED_WORKER, FETCH_MOKED_WORKERS, UPDATE_MOKED_WORKER, DELETE_MOKED_WORKER } from "../constants/actionTypes"

import * as api from "../api"

export const CreateMokedWorker = (newMokedWorker) => async (dispatch) => {

  try {

    const { data } = await api.createMokedWorker(newMokedWorker)

    dispatch({ type: CREATE_MOKED_WORKER, payload: data })
    
  } catch (error) {

    console.log(error)
    
  }
}

export const FetchAllMokedWorkers = () => async (dispatch) => {
  try {

    const { data } = await api.fetchAllMokedWorkers()

    dispatch({ type: FETCH_MOKED_WORKERS, payload: data })
    
  } catch (error) {

    console.log(error)
    
  }
}

export const UpdateMokedWorker = (id, updatedMokedWorker) => async (dispatch) => {
  try {

    const { data } = await api.updateMokedWorker(id, updatedMokedWorker)

    dispatch({ type: UPDATE_MOKED_WORKER, payload: data })
    
  } catch (error) {

    console.log(error)
    
  }
}

export const DeleteMokedWorker = (id) => async (dispatch) => {
  try {

    await api.deleteMokedWorker(id)

    dispatch({ type: DELETE_MOKED_WORKER, payload: id})
    
  } catch (error) {

    console.log(error)
    
  }
}