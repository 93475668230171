import "./managerSiteMalfunction.css";

import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";

import { UpdateSiteInformation } from "../../../actions/siteInformation";
import ManagerSiteInformationName from "./ManagerSiteInformationName/ManagerSiteInformationName";
import ManagerUrgentList from "./ManagerUrgentList/ManagerUrgentList";
import ManagerNotUrgentList from "./ManagerNotUrgentList/ManagerNotUrgentList";
import ManagerEmailListList from "./ManagerEmailListList/ManagerEmailListList";
import ManagerGroupNotes from "./ManagerGroupNotes/ManagerGroupNotes";

const initSiteInformationData = {
  client: [],
  siteName: "",
  manbat: "",
  system: "",
  maintenance: [],
  canEnter: [],
  canApprove: [],
  patrol: [],
  frequency: "",
  gate: "",
  alarm: "",
  sideNote: [],
  mokedType: "",
  malfunctions: [
    {
      urgent: [],
      notUrgent: [],
      emailList: [],
      malfunctionGroup: "",
      malfunctionGroupNotes: [],
    },
  ],
  technology: "",
  location: "",
  type: "",
};

export default function ManagerSiteMalfunction({
  chosenCURDType,
  setSiteInfoUpdater,
  sitesInformation,
  sitesInformationName,
}) {
  const [siteNameValue, setSiteNameValue] = useState("");

  return (
    <>
      {chosenCURDType && (
        <div className="siteInformationSection">
          <div className="siteInformationWrapper">
            <div className="mokedWorkerRight">
              <h1 className="mokedWorkerTitle">{chosenCURDType}</h1>
            </div>
            <div className="siteInformationContainer">
              {chosenCURDType === "רישום" ? (
                <CreateSiteMalfunctionCRUD />
              ) : chosenCURDType === "עריכה" ? (
                <EditSiteMalfunctionCRUD
                  sitesInformation={sitesInformation}
                  setSiteInfoUpdater={setSiteInfoUpdater}
                  siteNameValue={siteNameValue}
                  setSiteNameValue={setSiteNameValue}
                  sitesInformationName={sitesInformationName}
                />
              ) : (
                <DeleteSiteMalfunctionCRUD />
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

const CreateSiteMalfunctionCRUD = () => {
  return (
    <div>
      <form className="mokedWorkerForm">
        <div className="siteInformationContainerTop">
          <h1>באזור זה ניתן רק לערוך</h1>
        </div>
        <div className="buttonWrapperUserClient"></div>
      </form>
    </div>
  );
};

const EditSiteMalfunctionCRUD = ({
  sitesInformationName,
  siteNameValue,
  setSiteNameValue,
  sitesInformation,
  setSiteInfoUpdater,
}) => {
  const dispatch = useDispatch();

  const chosenSiteInformation = sitesInformation.find(
    (site) => site.siteName === siteNameValue
  );

  const [urgent, setUrgent] = useState([]);
  const [notUrgent, setNotUrgent] = useState([]);
  const [emailList, setEmailList] = useState([]);
  const [malfunctionGroupNotes, setMalfunctionGroupNotes] = useState([]);
  const [malfunctions, setMalfunctions] = useState({
    urgent: [],
    notUrgent: [],
    emailList: [],
    malfunctionGroup: "",
    malfunctionGroupNotes: [],
  });

  const [siteInformationData, setSiteInformationData] = useState(
    initSiteInformationData
  );

  useEffect(() => {
    if (chosenSiteInformation) {
      setSiteInformationData(chosenSiteInformation);

      if (chosenSiteInformation.malfunctions[0]) {
        setUrgent(chosenSiteInformation.malfunctions[0].urgent);
        setNotUrgent(chosenSiteInformation.malfunctions[0].notUrgent);
        setEmailList(chosenSiteInformation.malfunctions[0].emailList);
        setMalfunctionGroupNotes(
          chosenSiteInformation.malfunctions[0].malfunctionGroupNotes
        );
        setMalfunctions((prev) => ({
          ...prev,
          malfunctionGroup:
            chosenSiteInformation.malfunctions[0].malfunctionGroup,
        }));
      }
    }
  }, [chosenSiteInformation]);

  useEffect(() => {
    setMalfunctions((prev) => ({
      ...prev,
      urgent: urgent,
      notUrgent: notUrgent,
      emailList: emailList,
      malfunctionGroupNotes: malfunctionGroupNotes,
    }));
  }, [urgent, notUrgent, emailList, malfunctionGroupNotes]);

  useEffect(() => {
    setSiteInformationData((prev) => ({
      ...prev,
      malfunctions: malfunctions,
    }));
  }, [malfunctions]);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (chosenSiteInformation) {
      dispatch(
        UpdateSiteInformation(chosenSiteInformation._id, siteInformationData)
      );

      Clear();
    }
  };

  const Clear = () => {
    setSiteInformationData(initSiteInformationData);

    setUrgent([]);
    setNotUrgent([]);
    setEmailList([]);
    setMalfunctionGroupNotes([]);

    setMalfunctions((prev) => ({
      urgent: [],
      notUrgent: [],
      emailList: [],
      malfunctionGroup: "",
      malfunctionGroupNotes: [],
    }));

    setSiteNameValue("");

    setSiteInfoUpdater((prev) => !prev);
  };

  return (
    <div>
      <form className="mokedWorkerForm" onSubmit={handleSubmit}>
        <div className="siteInformationContainerTop">
          <ManagerSiteInformationName
            sitesInformationName={sitesInformationName}
            siteNameValue={siteNameValue}
            setSiteNameValue={setSiteNameValue}
          />
          <ManagerUrgentList urgent={urgent} setUrgent={setUrgent} />
          <ManagerNotUrgentList
            notUrgent={notUrgent}
            setNotUrgent={setNotUrgent}
          />
          <ManagerEmailListList
            emailList={emailList}
            setEmailList={setEmailList}
          />
          <input
            type="text"
            placeholder="קבוצת דו''ח התקלות"
            className="mokedWorkerInput"
            value={malfunctions.malfunctionGroup}
            onChange={(e) =>
              setMalfunctions({
                ...malfunctions,
                malfunctionGroup: e.target.value,
              })
            }
          />
          <ManagerGroupNotes
            malfunctionGroupNotes={malfunctionGroupNotes}
            setMalfunctionGroupNotes={setMalfunctionGroupNotes}
          />
        </div>
        <div className="buttonWrapperUserClient">
          <input
            type="submit"
            className="BottomFormMokedWorkersButton editCRUDButton"
            value="ערוך אתר"
          />
        </div>
      </form>
    </div>
  );
};

const DeleteSiteMalfunctionCRUD = () => {
  return (
    <div className="clientUserFormContainer">
      <div className="EditMokedWorkerCRUDTop">
        <h1>באזור זה ניתן רק לערוך</h1>
      </div>
    </div>
  );
};
