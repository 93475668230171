import { FETCH_USER_CLIENTS, UPDATE_USER_CLIENT, DELETE_USER_CLIENT } from "../constants/actionTypes"

import * as api from "../api"

export const FetchAllUserClient = () => async (dispatch) => {
  try {

    const { data } = await api.fetchAllUserClient()

    dispatch({ type: FETCH_USER_CLIENTS, payload: data })
    
  } catch (error) {

    console.log(error)
    
  }
}

export const UpdateUserClient = (id, updatedUser) => async (dispatch) => {
  try {

    const { data } = await api.updateUserClient(id, updatedUser)

    dispatch({ type: UPDATE_USER_CLIENT, payload: data })
    
  } catch (error) {

    console.log(error)
    
  }
}

export const DeleteUserClient = (id) => async (dispatch) => {
  try {

    await api.deleteUserClient(id)

    dispatch({ type: DELETE_USER_CLIENT, payload: id})
    
  } catch (error) {

    console.log(error)
    
  }
}