import { useRef } from "react";
import { NavigateBefore } from "@material-ui/icons";

export default function ManagerSideNoteList({
  sideNoteArray,
  setSideNoteArray,
  activeEntryEditId,
  placeholder,
}) {
  const sideNoteName = useRef("");

  const ClickHandler = (e) => {
    e.preventDefault();

    if (activeEntryEditId) {
      if (sideNoteName.current.value !== "") {
        setSideNoteArray([sideNoteName.current.value]);
      }
    } else {
      if (sideNoteName.current.value !== "") {
        setSideNoteArray([...sideNoteArray, sideNoteName.current.value]);
      }
    }

    sideNoteName.current.value = "";
  };

  const deleteEmploy = (e) => {
    const sideNoteName = e.target.getAttribute("name");

    setSideNoteArray(sideNoteArray.filter((employ) => employ !== sideNoteName));
  };

  return (
    <>
      <div className="employList siteInfoList">
        <input
          type="text"
          placeholder={placeholder || "הכנס הערות לאתר (רב בחירה)"}
          className="mokedWorkerInput"
          ref={sideNoteName}
        />
        <button onClick={ClickHandler} className="personAddButton AddIconInfo">
          הוסף
        </button>
      </div>
      <ul className="employListItem siteInfoListList">
        {sideNoteArray.map((employ, index) => {
          return (
            <li
              key={index}
              name={employ}
              className="employItem siteInfoListItem"
              onClick={deleteEmploy}
            >
              <NavigateBefore className="employItemIcon" />
              {employ}
            </li>
          );
        })}
      </ul>
    </>
  );
}
