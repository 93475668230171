import "./malfunctionsRight.css";

import { useState } from "react";

export default function MalfunctionsRight({
  sitesInformation,
  chosenGroup,
  setChosenGroup,
}) {
  const [oneChosenGroup, setOneChosenGroup] = useState("");

  let sitesInformationFiltered;

  let sortedSitesInformation;

  let fetchMalfunctions;

  let filteredFetchMalfunctions;

  if (Object.keys(sitesInformation).length > 0) {
    sitesInformationFiltered = sitesInformation?.filter(
      (malfunction) => malfunction.malfunctions[0] !== undefined
    );

    sortedSitesInformation = sitesInformationFiltered.reduce((r, a) => {
      r[a.malfunctions[0].malfunctionGroup] =
        r[a.malfunctions[0].malfunctionGroup] || [];
      r[a.malfunctions[0].malfunctionGroup].push(a);
      return r;
    }, Object.create(null));

    fetchMalfunctions = Object.entries(sortedSitesInformation);

    filteredFetchMalfunctions = fetchMalfunctions?.filter(
      (malfunction) => malfunction[0] !== "undefined"
    );
  }

  return (
    <>
      {Object.keys(sitesInformation).length > 0 && (
        <div className="RightMalfunctions">
          <div className="malfunctionsWrapper">
            <input
              className="groupMalfunctionListInput"
              type="text"
              list="groupSuggestion"
              size="50px"
              placeholder="חפש קבוצה"
              value={oneChosenGroup}
              onChange={(e) => setOneChosenGroup(e.target.value)}
            />
            <datalist id="groupSuggestion">
              {filteredFetchMalfunctions.map((malfunction, index) => {
                return <option key={index}>{malfunction[0]}</option>;
              })}
            </datalist>

            {oneChosenGroup ? (
              <h3
                className="item"
                onClick={() => setChosenGroup(oneChosenGroup)}
              >
                {oneChosenGroup}
              </h3>
            ) : (
              filteredFetchMalfunctions.map((malfunction) => {
                const active = malfunction[0] === chosenGroup;
                return (
                  <h3
                    key={malfunction[1][0]._id}
                    className={`item ${active ? "activeItem" : ""}`}
                    onClick={() => setChosenGroup(malfunction[0])}
                  >
                    {malfunction[0]}
                  </h3>
                );
              })
            )}
          </div>
        </div>
      )}
    </>
  );
}
