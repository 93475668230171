import { WarningRounded } from "@material-ui/icons";
import React from "react";

export default function FarmInfo(props) {
  const { chosenSiteInformation: information } = props;

  return (
    <div className="farmInfoRight">
      <ul className="siteInformationList">
        <li className="siteInformationItem">
          שם החווה: {information.siteName}
        </li>
        <li className="siteInformationItem">מנב"ט: {information.manbat}</li>
        <li className="siteInformationItem">
          מנהל תחום קנאביס: {information.manager}
        </li>
        <li className="siteInformationItem">
          שירות טכנולוגי: {information.system}
        </li>
        <li className="siteInformationItem">
          באיזה אופן מחובר: {information.connected}
        </li>
        <li className="siteInformationItem">שערים: {information?.gate}</li>
        <li className="siteInformationItem">
          סיסמת לחצן מצוקה: {information?.password}
        </li>
        <li className="siteInformationItem">
          סיורים: {information.patrol?.join(", ")}
        </li>
        <li className="siteInformationItem">אבטחה: {information?.security}</li>
        <ul className="sideNoteList">
          <WarningRounded className="warningSignIcon" />
          {!information.sideNote ? null : Object.keys(information.sideNote)
              .length > 1 ? (
            information.sideNote.map((note, index) => {
              return (
                <li className="informationSideNote" key={index}>
                  {note}
                </li>
              );
            })
          ) : (
            <li className="informationSideNote">{information.sideNote}</li>
          )}
        </ul>
      </ul>
    </div>
  );
}
