import React from 'react'

export default function CheckListDisplay({ checkListEvent, setCheckListEvent }) {

    const deleteOrder = (order) => {
        setCheckListEvent(prev => ({
            ...prev,
            orders: prev.orders.filter(ord => ord.order !== order)
        }))
    }

    const deleteSubOrder = (order, subOrder) => {

        setCheckListEvent(prev => ({
            ...prev,
            orders: prev.orders.map(ord => {
                if(ord.order === order){
                    return{
                        order: ord.order,
                        subOrders: ord.subOrders.filter(sub => sub.subOrder !== subOrder)
                    }
                }else{
                    return ord
                }
            })
        }))
        
    }

  return (
    <div className="CheckListContainer overFlowCheckList" >
        <div className="CheckListCannabisContainer">
            <h2>{checkListEvent.title}</h2>
            <h3>{checkListEvent.subTitle}</h3>
            <ol className="primaryList"  >
            {checkListEvent.orders.map(order => {
                return(
                    <>
                        <li className="checkListItem checkListHover"
                            key={order.order} 
                            onClick={() => deleteOrder(order.order)} >

                            {order.order} 
                        </li>
                        <ol className="secondaryList"  >
                            {order.subOrders.length > 0 &&
                            order.subOrders.map(subOrder => {
                                return(
                                    <li className="checkListItem checkListHover"
                                        key={subOrder.subOrder} 
                                        onClick={() => deleteSubOrder(order.order, subOrder.subOrder)}>

                                        {subOrder.subOrder}
                                    </li>
                                    )
                                })
                            }
                        </ol>
                    </>
                )
            })}
            </ol>
        </div>
    </div>
  )
}
