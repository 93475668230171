import { saveAs } from "file-saver";
import XLSX from "sheetjs-style";

export default function ScoutsExcelExport(props) {
  const { scouts, fileName } = props;

  const filteredScouts = scouts.map((scout) => {
    let formattedData = {};

    if (scout.type === "weeklyScout") {
      formattedData.מקטעי_גדר = scout.fenceParts;
    } else if (scout.type === "jumpScout") {
      formattedData.סיבת_הקפצה = scout.jumpReason;
    }

    formattedData = {
      ...formattedData,
      הערות: scout.sideNote,
      שם_הסייר: scout.scouter,
      שעה: scout.hour,
      תאריך: scout.date,
      שם_האתר: scout.siteName,
      שם_המוקדן: scout.mokedWorker,
    };

    return formattedData;
  });

  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const exportToCSV = async () => {
    try {
      const wb = XLSX.utils.book_new(); // Create a new workbook

      const ws = XLSX.utils.json_to_sheet(filteredScouts); // Create a worksheet from the data array
      const sheetName = "Scouts"; // Generate a name for the sheet
      ws["!cols"] = filteredScouts[0]
        ? Object.keys(filteredScouts[0]).map((key) => {
            const maxLength = filteredScouts.reduce(
              (max, item) => Math.max(max, (item[key] || "").length),
              0
            );
            return { wch: maxLength < 15 ? 15 : maxLength };
          })
        : [];
      Object.keys(ws).forEach((cell) => {
        if (cell[0] !== "!") {
          // Avoid special keys like '!cols', '!ref', etc.
          ws[cell].s = {
            alignment: { horizontal: "right" },
          };
        }
      });
      const header_cells = Object.keys(filteredScouts[0]).map(
        (key, index) => String.fromCharCode(65 + index) + "1"
      );
      header_cells.forEach((cell) => {
        ws[cell].s = { fill: { fgColor: { rgb: "D3D3D3" } } };
      });
      XLSX.utils.book_append_sheet(wb, ws, sheetName); // Append the worksheet to the workbook

      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });
      saveAs(data, "דו״ח סיירים " + fileName + fileExtension);
    } catch (error) {
      console.error("Error exporting file:", error);
      alert(
        "אירעה שגיאה ביצוא הקובץ, אנא נסה שוב מאוחר יותר. אם הבעיה נמשכת, צור קשר עם מנהל המערכת."
      );
    }
  };

  return (
    <button className="malfunctionGroupExport" onClick={() => exportToCSV()}>
      Exl
    </button>
  );
}
