import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";

function useAuth() {
  const location = useLocation();
  const prevUserRef = useRef(null);
  const [user, setUser] = useState(() =>
    JSON.parse(localStorage.getItem("profile"))
  );

  let moked = "";
  let client = "";

  if (user) {
    if (user.result.userName === "מנהל" || user.result?.isMoked) {
      moked = user.result.userName;
      client = null;
    } else {
      client = user.result;
      moked = null;
    }
  }

  useEffect(() => {
    const currentUser = JSON.parse(localStorage.getItem("profile"));
    if (JSON.stringify(prevUserRef.current) !== JSON.stringify(currentUser)) {
      setUser(currentUser);
      prevUserRef.current = currentUser;
    }
  }, [location]);

  return { user, moked, client };
}

export default useAuth;
