import React from "react";
import { useSelector } from "react-redux";

function useSelectors() {
  const mokedWorkersName = useSelector((state) => state.mokedWorkers);

  const malfunctionType = useSelector((state) => state.malfunctionType);

  const sitesInformation = useSelector((state) => state.siteInformation);

  const farmsInformation = useSelector((state) => state.farmInformation);

  const otherSiteInformation = useSelector(
    (state) => state.otherSiteInformation
  );

  const shiftPageType = useSelector((state) => state.ShiftPageType);

  const imageList = useSelector((state) => state.imageList);

  const scoutInfo = useSelector((state) => state.scout);

  return {
    mokedWorkersName,
    malfunctionType,
    sitesInformation,
    farmsInformation,
    otherSiteInformation,
    shiftPageType,
    imageList,
    scoutInfo,
  };
}

export default useSelectors;
