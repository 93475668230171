import { CREATE_MOKED_WORKER, FETCH_MOKED_WORKERS, UPDATE_MOKED_WORKER, DELETE_MOKED_WORKER } from "../constants/actionTypes"

const mokedWorkersReducer = ( mokedWorkers = [], action ) => {
    switch (action.type) {
        case CREATE_MOKED_WORKER:
            return [...mokedWorkers, action.payload]

        case FETCH_MOKED_WORKERS:
            return action.payload
        
        case UPDATE_MOKED_WORKER:
            return mokedWorkers.map((worker) => worker._id === action.payload._id ? action.payload : worker)

        case DELETE_MOKED_WORKER:
            return mokedWorkers.filter((worker) => worker._id !== action.payload)
    
        default:
            return mokedWorkers
    }
}

export default mokedWorkersReducer