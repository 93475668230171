import "./employList.css"
import { useRef } from "react"
import { PersonAdd, NavigateBefore } from "@material-ui/icons"

export default function EmployList({employArray, setEmployArray, activeEntryEditId}) {

    const employName = useRef("")

    const ClickHandler = (e) => {

        e.preventDefault()

        if(activeEntryEditId){

            if(employName.current.value !== ""){
                setEmployArray([employName.current.value])
            }

        } else {
        
            if(employName.current.value !== ""){
                setEmployArray([...employArray, employName.current.value])
            }

        }

        employName.current.value = ""
    }


    const deleteEmploy = (e) => {

        const employName = e.target.getAttribute("name")

        setEmployArray(employArray.filter(employ => employ !== employName))
    }

    return (
        <>
            <ul className="employListItem">
                {employArray.map((employ, index) => {
                    return (
                    <li key={index} name={employ} className="employItem" onClick={deleteEmploy}>
                        <NavigateBefore className="employItemIcon"/>
                        {employ}
                    </li>
                    )
                })}
            </ul>
            <div className="employList">
                <input type="text" placeholder="הכנס את שם העובד" 
                className="entryFormInput entryFormInputEmployList" 
                ref = {employName}
                />
                <button onClick={ClickHandler} className="personAddButton"><PersonAdd className="personAddIcon"/></button>
            </div>
         </>
    )
}
