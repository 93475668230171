import React from 'react'
import { Close } from "@material-ui/icons"

export default function ImageDisplay({setOpenPic, image}) {

  const stopPropagation = (event) => {
    event.stopPropagation();
}
  return (
    <div className="FinishedTransformsFormFile fadeInDown" onClick={() => setOpenPic()}> 
    <div className="FinishedTransformsFormFileInner fadeIn" onClick={(e) => stopPropagation(e)}>
        <button className="endTransformsInnerExit" onClick={() => setOpenPic()}>
            <Close/>
            </button>
        <div className="endTransformsContainer">
        <img key={image._id} className='imageListImage' src={`data:image/jpeg;base64,${image.base64}`} alt={image.imageName} />
        </div>
    </div>
    </div>
  )
}
