import "./transformsForms.css"

import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import { FetchAllTransforms } from "../../actions/transforms"

import TransformsFormsRight from "../../components/TransformsFormsComponents/TransformsFormsRight/TransformsFormsRight"
import TransformsFormsLeft from "../../components/TransformsFormsComponents/TransformsFormsLeft/TransformsFormsLeft"

export default function TransformsForms({ mokedWorkersName, socket, moked }) {

    const dispatch = useDispatch()

    const [ transformId, setTransformId ] = useState("")
    const [ transformsFormIdToEdit, setTransformsFormIdToEdit ] = useState("")
    const [ getTransformsUpdater, setGetTransformsUpdater] = useState(true)
    const [ isAlarmOpen, setIsAlarmOpen ] = useState(false)
    const [ typeAlarmOpen, setTypeAlarmOpen ] = useState(false)

      useEffect(() => {

        if(socket == null) return

        socket.emit("send-transforms-changes", getTransformsUpdater)

        socket.on("receive-transforms-changes", getTransformsUpdater => {

            dispatch(FetchAllTransforms())

        })

        setGetTransformsUpdater(true)

    }, [ socket, dispatch, getTransformsUpdater, transformId, transformsFormIdToEdit ])

    useEffect(() => {

        dispatch(FetchAllTransforms())

        setGetTransformsUpdater(true)

        setTypeAlarmOpen(false)

    }, [dispatch, getTransformsUpdater, transformId, transformsFormIdToEdit ])
    

    const FetchActiveTransforms = useSelector((state) => state.transforms)

    let chosenTransforms = []

    if(transformId){

        chosenTransforms = FetchActiveTransforms.filter(chosen => chosen._id === transformId)[0]

    }

    return (
        <>
            <div className="TransformsFormsContainer">
                    <TransformsFormsRight moked={moked} FetchActiveTransforms={FetchActiveTransforms} setTransformId={setTransformId} 
                    transformsFormIdToEdit={transformsFormIdToEdit} setTransformsFormIdToEdit={setTransformsFormIdToEdit}
                    isAlarmOpen={isAlarmOpen} setIsAlarmOpen={setIsAlarmOpen} setTypeAlarmOpen={setTypeAlarmOpen} mokedWorkersName={mokedWorkersName} />
                <div className="TransformsFormsLeft">
                {transformId && chosenTransforms && 
                    <TransformsFormsLeft moked={moked} chosenTransforms={chosenTransforms} transformId={transformId} 
                    setGetTransformsUpdater={setGetTransformsUpdater} setTransformId={setTransformId} isAlarmOpen={isAlarmOpen} setIsAlarmOpen={setIsAlarmOpen}
                    typeAlarmOpen={typeAlarmOpen} setTypeAlarmOpen={setTypeAlarmOpen} />
                }
                </div>
            </div>
        </>
    )
}
