
import { CREATE_NEW_POPUPMESSAGE, FETCH_ALL_POPUPMESSAGES, UPDATE_POPUPMESSAGE, DELETE_POPUPMESSAGE } from "../constants/actionTypes"

import * as api from "../api"
        
export const CreatePopupMessage = (newPopupMessage) => async (dispatch) => {
        
    try {
        
        const { data } = await api.createPopupMessage(newPopupMessage)
        
        dispatch({ type: CREATE_NEW_POPUPMESSAGE, payload: data })
            
    } catch (error) {
        
        console.log(error)
            
    }
}
        
export const FetchAllPopUPMessages = () => async (dispatch) => {

    try {
        
        const { data } = await api.fetchAllPopupMessages()
        
        dispatch({ type: FETCH_ALL_POPUPMESSAGES, payload: data })
            
    } catch (error) {
        
        console.log(error)
            
    }
}
        
export const UpdatePopupMessage = (id, updatedpopupMessage) => async (dispatch) => {

    try {
        
        const { data } = await api.updatePopupMessage(id, updatedpopupMessage)
        
        dispatch({ type: UPDATE_POPUPMESSAGE, payload: data })
            
    } catch (error) {
        
        console.log(error)
            
    }

}
        
export const DeletePopupMessage = (id) => async (dispatch) => {

    try {
        
        await api.deletePopupMessage(id)
        
        dispatch({ type: DELETE_POPUPMESSAGE, payload: id})
            
    } catch (error) {
        
        console.log(error)
            
    }

}
        