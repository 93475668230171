import "./entriesPage.css";

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { FetchAllActiveEntries } from "../../actions/activeEntries";

import RightBar from "../../components/RightBar/RightBar";
import Feed from "../../components/Feed/Feed";
import LeftBar from "../../components/LeftBar/LeftBar";

export default function EntriesPage({
  shiftPageType,
  mokedWorkersName,
  sitesInformation,
  socket,
  user,
  setChatMessageUpdater,
}) {
  const [siteNameValue, setSiteNameValue] = useState("");
  const [pickedSite, setPickedSite] = useState("");
  const [activeEntryEditId, setActiveEntryEditId] = useState("");
  const [getActiveEntriesUpdater, setGetActiveEntriesUpdater] = useState(true);

  const dispatch = useDispatch();

  useEffect(() => {
    if (socket == null) return;

    socket.emit("send-changes", getActiveEntriesUpdater);

    socket.on("receive-changes", (getActiveEntriesUpdater) => {
      dispatch(FetchAllActiveEntries());
    });

    setGetActiveEntriesUpdater(true);
  }, [socket, dispatch, activeEntryEditId, getActiveEntriesUpdater]);

  useEffect(() => {
    dispatch(FetchAllActiveEntries());

    setGetActiveEntriesUpdater(true);
  }, [dispatch, activeEntryEditId, getActiveEntriesUpdater]);

  const FetchActiveEntries = useSelector((state) => state.activeEntries);

  const sortedActiveEntries = FetchActiveEntries.reduce((r, a) => {
    r[a.siteName] = r[a.siteName] || [];
    r[a.siteName].push(a);
    return r;
  }, Object.create(null));

  const fetchActiveEntries = Object.entries(sortedActiveEntries);

  return (
    <>
      <div className="EntriesPageContainer">
        <RightBar
          shiftPageType={shiftPageType}
          socket={socket}
          user={user}
          setChatMessageUpdater={setChatMessageUpdater}
        />
        <Feed
          sitesInformation={sitesInformation}
          setSiteNameValue={setSiteNameValue}
          mokedWorkersName={mokedWorkersName}
          siteNameValue={siteNameValue}
          fetchActiveEntries={fetchActiveEntries}
          setPickedSite={setPickedSite}
          setGetActiveEntriesUpdater={setGetActiveEntriesUpdater}
          activeEntryEditId={activeEntryEditId}
          setActiveEntryEditId={setActiveEntryEditId}
        />
        <LeftBar
          sitesInformation={sitesInformation}
          setSiteNameValue={setSiteNameValue}
          siteNameValue={siteNameValue}
          setPickedSite={setPickedSite}
          fetchActiveEntries={fetchActiveEntries}
          pickedSite={pickedSite}
          activeEntryEditId={activeEntryEditId}
          setActiveEntryEditId={setActiveEntryEditId}
          setGetActiveEntriesUpdater={setGetActiveEntriesUpdater}
        />
      </div>
      <div className="backgroundImgMatara">
        <img
          src="/assets/MataraLogoForAssom.png"
          alt="MataraLogo"
          className="MataraLogoImg"
        />
      </div>
    </>
  );
}
