import './messageBox.css'
import { useCallback } from 'react'

export default function MessageBox({
 lastMessage,
 message,
 MyMessage = true,
 Follow = false,
}) {

  const setRef = useCallback(node => {
    if (node) {
      node.scrollIntoView({ smooth: true })
    }
  }, [])

  return (
    <div className="msg" ref={lastMessage ? setRef : null}
    >
        <div className={MyMessage ? "bubble alt" : "bubble" }>
        <div className="txt">
            <span className="timestamp">{message.created_At.slice(15, 21)}</span>
            {!MyMessage && <span className="name">{message.user}</span>}
            <p className="message">{message.message}</p>
        </div>
        {!Follow && <div className={MyMessage ? "bubble-arrow-alt" : "bubble-arrow"}></div>}
        </div>
    </div>
  )
}
