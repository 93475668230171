import "./clientEntriesRight.css"

import axios from "axios"
import { saveAs } from "file-saver"

import { CircularProgress } from "@material-ui/core"

import EntryHistoryDatePicker from "./EntryHistoryDatePicker/EntryHistoryDatePicker"
import ChosenSiteName from "./ChosenSiteName/ChosenSiteName"
import ClientEntryInformation from "./ClientEntryInformation/ClientEntryInformation"
import MalfunctionExcelExport from "../../utils/SiteEntriesExcelExport"

export default function ClientEntriesRight({ siteNameArray, chosenSiteName, setChosenSiteName, siteInformationArray, startDate, setStartDate, endDate, setEndDate, errors, setErrors, siteInformationArrayDateSorted }) {

    const createAndDownloadPdf = () => {

        axios.post("/create-pdf", siteInformationArrayDateSorted)
        .then(() => axios.get("/fetch-pdf", { responseType: "arraybuffer" }))
        .then((res) => {
          const pdfBlob = new Blob([res.data], { type: "application/pdf" });
  
          saveAs(pdfBlob, `${chosenSiteName}.pdf`);
        })
      }

    return (
        <div className="ClientEntriesRight">


            <div className="ClientEntriesRightWrapper">
            {Object.keys(siteNameArray).length > 0 ? 
                <>
                <h3 className="ClientEntriesRightTitle">בחר אתר</h3>
                <ChosenSiteName siteNameArray={siteNameArray} chosenSiteName={chosenSiteName} setChosenSiteName={setChosenSiteName}/>
                {chosenSiteName && <ClientEntryInformation siteInformationArray={siteInformationArray}/> }
                {chosenSiteName && <EntryHistoryDatePicker startDate={startDate} setStartDate={setStartDate} 
                endDate={endDate} setEndDate={setEndDate} 
                errors={errors} setErrors={setErrors} /> }
                {Object.keys(errors).length === 0 && chosenSiteName ? 
                    <>
                        <button className="downloadEntriesButton" onClick={createAndDownloadPdf}>הורד קובץ כניסות PDF</button>
                        <MalfunctionExcelExport entries={siteInformationArrayDateSorted} fileName={chosenSiteName} /> 
                    </>
                : 
                    <button className="downloadEntriesButtonDisable">הורד קובץ כניסות </button> 
                }
                </>
                : <div className="clientEntriesWaitingResponse">
                <p className="waitingResponseMessage">הורדת אינפורמציית האתרים עלולה להמשך מספר שניות</p>
                <CircularProgress className="CircularProgressDesign" />
                </div>}
                <div className="backgroundImgMataraClient">
                        <img src="/assets/MataraLogoForAssom.png" alt="MataraLogo" className="MataraLogoImgClient" />
                </div>
            </div>
        </div>
    )
}