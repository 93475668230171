
import * as api from "../api"


export const CreateAccount = async (accountData) =>{

  try {

    const { data } = await api.createAccount(accountData)

    return data    

  } catch (e) {

    console.log(e)
  }
}

// export const FetchAlbumSongs = async (id) =>{

//   try {

//     const { data } = await api.fetchAlbumSongs(id)

//     return data    

//   } catch (e) {

//     console.log(e)
//   }
// }
