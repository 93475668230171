import "./siteInformation.css";
import { WarningRounded } from "@material-ui/icons";

export default function SiteInformation({ information }) {
  return (
    <div>
      <ul className="siteInformationList">
        <li className="siteInformationItem">
          לקוח: {information.client.join(", ")}
        </li>
        <li className="siteInformationItem">מנב"ט: {information.manbat}</li>
        <li className="siteInformationItem">
          מאשר הכניסה: {information.canApprove.join(", ")}
        </li>
        <li className="siteInformationItem siteInformationItemList">
          מורשי הכניסה:{" "}
          {information?.canEnter.length > 0 &&
            information?.canEnter.map((enter, index) => {
              return (
                <p className="siteInformationItemItem" key={index}>
                  {enter}
                </p>
              );
            })}
        </li>
        <li className="siteInformationItem">שערים: {information?.gate}</li>
        <li className="siteInformationItem">כריזה: {information?.alarm}</li>
        <li className="siteInformationItem">
          חברת מערכת: {information.system}
        </li>
        <li className="siteInformationItem">
          טכנולוגיה: {information.technology}
        </li>
        <li className="siteInformationItem">מיקום: {information.location}</li>
        <li className="siteInformationItem">
          חברת אחזקה: {information.maintenance.join(", ")}
        </li>
        <li className="siteInformationItem">
          סיורים: {information.patrol.join(", ")}
        </li>
        <ul className="sideNoteList">
          <WarningRounded className="warningSignIcon" />
          {!information.sideNote ? null : Object.keys(information.sideNote)
              .length > 1 ? (
            information.sideNote.map((note, index) => {
              return (
                <li className="informationSideNote" key={index}>
                  {note}
                </li>
              );
            })
          ) : (
            <li className="informationSideNote">{information.sideNote}</li>
          )}
        </ul>
      </ul>
    </div>
  );
}
