import { FETCH_SITE_INFORMATION, CREATE_NEW_SITE_INFORMATION, UPDATE_SITE_INFORMATION, DELETE_SITE_INFORMATION } from "../constants/actionTypes"

const siteInformationReducer = ( siteInformation = [], action ) => {
    switch (action.type) {
        case CREATE_NEW_SITE_INFORMATION:
            return [...siteInformation, action.payload]

        case FETCH_SITE_INFORMATION:
            return action.payload
        
        case UPDATE_SITE_INFORMATION:
            return siteInformation.map((siteInfo) => siteInfo._id === action.payload._id ? action.payload : siteInfo)

        case DELETE_SITE_INFORMATION:
            return siteInformation.filter((siteInfo) => siteInfo._id !== action.payload)
    
        default:
            return siteInformation
    }
}

export default siteInformationReducer