import "./activeEntriesItem.css";
import { MenuRounded, PeopleOutlineRounded } from "@material-ui/icons";

export default function ActiveEntriesItem({
  activeEntry,
  setPickedSite,
  setSiteNameValue,
}) {
  let [SiteName, EntryData] = activeEntry;

  const employLength = EntryData?.length;

  const createdAt = EntryData?.[0]?.created_At.slice(16, 21);

  const textStyle =
    SiteName.length > 18 ? "activeSiteNameSmall" : "activeSiteName";

  return (
    <div className="activeEntryList fadeInDown">
      {Object.keys(EntryData).length > 0 && (
        <div className="activeEntryItem fadeIn">
          <div className="activeEntryItemTop">
            <p className={textStyle}>{SiteName}</p>
            <MenuRounded
              className="burgerIcon"
              onClick={(e) => {
                setPickedSite(SiteName);
                setSiteNameValue("");
              }}
            />
          </div>
          <div className="activeEntryItemBottom">
            <div className="activeEntryItemBottomRight">
              <PeopleOutlineRounded className="employLengthIcon" />
              <p className="employLengthText">{employLength}</p>
            </div>
            <div className="activeEntryItemBottomLeft">
              <p className="lastUpdated">{createdAt}</p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
