import "./malfunctionsSite.css";

import { useState } from "react";

import MalfunctionExcelExport from "../../../../utils/MalfunctionExcelExport";
import MalfunctionDoneWindow from "./MalfunctionDoneWindow/MalfunctionDoneWindow";

export default function MalfunctionsSite({
  DownloadPdf,
  pickedSite,
  EditHandler,
  isArchive,
  now,
  setMalfunctionUpdater,
  mokedWorkersName,
  chosenGroup,
  sortedMalfunctionGroup,
  allMalfunction,
}) {
  const [isDoneWindow, setIsDoneWindow] = useState(false);
  const [closeWorkerName, setCloseWorkerName] = useState("");
  const [whyClosed, setWhyClosed] = useState("");
  const [malfunctionArchive, setMalfunctionArchive] = useState({});

  if (pickedSite) {
    sortedMalfunctionGroup = sortedMalfunctionGroup.filter(
      (site) => site.siteName === pickedSite
    );
  }

  return (
    <>
      <table className="tableContainer" id="malfunction-table">
        {sortedMalfunctionGroup &&
          sortedMalfunctionGroup.map((site, index) => {
            const filteredMalfunction = allMalfunction.filter(
              (sites) => sites.siteNameValue === site.siteName
            );

            return (
              <div className="siteMalfunctionContainer" key={index}>
                <table className="tableContainer">
                  <thead>
                    <tr>
                      <th className="siteMalfunctionTitle">
                        {site.siteName}{" "}
                        <span className="sideTitle">
                          {site.system} {site.manbat}
                        </span>
                      </th>
                    </tr>
                    <tr className="malfunctionTitles">
                      <th className="malfunctionItemsSmall">תאריך</th>
                      <th className="malfunctionItemsSmall">תקלה</th>
                      <th className="malfunctionItemsSmall">אזור / מספר</th>
                      <th className="malfunctionItemsMedium">תיאור התקלה</th>
                      <th className="malfunctionItems">הערות</th>
                      <th className="malfunctionItemsSmall">מספר קריאה</th>
                      <th className="malfunctionItemsMedium">סיווג מנב״ט</th>
                      <th className="malfunctionItemsSmallTwo">מוקדן פותח</th>
                      {isArchive && <th className="malfunctionItemsBtn"></th>}
                      {isArchive && <th className="malfunctionItemsBtn"></th>}
                      {!isArchive && (
                        <th className="malfunctionItemsSmall">מוקדן סוגר</th>
                      )}
                      {!isArchive && (
                        <th className="malfunctionItemsSmall">סיבת סגירה</th>
                      )}
                      {!isArchive && (
                        <th className="malfunctionItemsSmallTwo">
                          תאריך סגירה
                        </th>
                      )}
                    </tr>
                  </thead>
                </table>
                <table className="tableContainer">
                  {filteredMalfunction.map((malfunction) => {
                    return (
                      <tbody key={malfunction._id}>
                        <tr
                          className="malfunction"
                          style={
                            malfunction?.malfunctionColor &&
                            malfunction?.malfunctionColor !== ""
                              ? {
                                  backgroundColor:
                                    malfunction?.malfunctionColor,
                                }
                              : null
                          }
                        >
                          <td className="malfunctionItemsSmall">
                            {malfunction.created_At.slice(16, 21) +
                              " - " +
                              malfunction.created_At.slice(4, 10) +
                              " " +
                              malfunction.created_At.slice(13, 15)}
                          </td>
                          <td className="malfunctionItemsSmall">
                            {malfunction.malfunctionSort}
                          </td>
                          <td className="malfunctionItemsSmall">
                            {malfunction.malfunctionLocation}
                          </td>
                          <td className="malfunctionItemsMedium">
                            {malfunction.malfunctionProblem}
                          </td>
                          <td className="malfunctionItems">
                            {malfunction.sideNote &&
                              malfunction.sideNote.map((note, index) => {
                                return (
                                  <tr key={index}>
                                    <td>
                                      {index + 1}. {note}
                                    </td>
                                  </tr>
                                );
                              })}
                          </td>
                          <td className="malfunctionItemsSmall">
                            {malfunction.callNumber}
                          </td>
                          <td className="malfunctionItemsMedium">
                            {malfunction.classification}
                          </td>
                          <td className="malfunctionItemsSmallTwo">
                            {malfunction.workerName}
                          </td>
                          {!isArchive && (
                            <td className="malfunctionItemsSmall">
                              {malfunction.closeWorkerName}
                            </td>
                          )}
                          {!isArchive && (
                            <td className="malfunctionItemsSmall">
                              {malfunction.whyClosed}
                            </td>
                          )}
                          {!isArchive && (
                            <td className="malfunctionItemsSmallTwo">
                              {malfunction.updated_At
                                ? malfunction.updated_At.slice(4, 10) +
                                  " " +
                                  malfunction.created_At.slice(13, 15) +
                                  " - " +
                                  malfunction.updated_At.slice(16, 21)
                                : null}
                            </td>
                          )}
                          {isArchive && (
                            <td className="malfunctionItemsBtn">
                              <button
                                className="malfunctionEdit"
                                onClick={() => EditHandler(malfunction._id)}
                              >
                                ערוך / מחק
                              </button>
                            </td>
                          )}
                          {isArchive && (
                            <td className="malfunctionItemsBtn">
                              <button
                                className="malfunctionDone"
                                onClick={() => {
                                  setIsDoneWindow(true);
                                  setMalfunctionArchive(malfunction);
                                }}
                              >
                                טופל
                              </button>
                            </td>
                          )}
                        </tr>
                      </tbody>
                    );
                  })}
                  <tfoot>
                    <tr></tr>
                  </tfoot>
                </table>
              </div>
            );
          })}
        {isDoneWindow && (
          <MalfunctionDoneWindow
            setIsDoneWindow={setIsDoneWindow}
            closeWorkerName={closeWorkerName}
            setCloseWorkerName={setCloseWorkerName}
            whyClosed={whyClosed}
            setWhyClosed={setWhyClosed}
            mokedWorkersName={mokedWorkersName}
            setMalfunctionUpdater={setMalfunctionUpdater}
            now={now}
            malfunctionArchive={malfunctionArchive}
            setMalfunctionArchive={setMalfunctionArchive}
          />
        )}
      </table>

      {!isArchive && chosenGroup && (
        <div className="malfunctionGroupTitleButtons">
          <MalfunctionExcelExport
            allMalfunction={allMalfunction}
            sortedMalfunctionGroup={sortedMalfunctionGroup}
            fileName={chosenGroup}
          />

          <button
            className="malfunctionGroupExport"
            style={{ bottom: "80px" }}
            onClick={DownloadPdf}
          >
            pdf
          </button>
        </div>
      )}
    </>
  );
}
