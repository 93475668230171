
import { CREATE_NEW_CHECKLISTEVENT, FETCH_ALL_CHECKLISTEVENTS, UPDATE_CHECKLISTEVENT, DELETE_CHECKLISTEVENT } from "../constants/actionTypes"

import * as api from "../api"
        
export const CreateCheckListEvent = (newCheckListEvent) => async (dispatch) => {
        
    try {
        
        const { data } = await api.createCheckListEvent(newCheckListEvent)
        
        dispatch({ type: CREATE_NEW_CHECKLISTEVENT, payload: data })
            
    } catch (error) {
        
        console.log(error)
            
    }
}
        
export const FetchAllCheckListEvents = () => async (dispatch) => {

    try {
        
        const { data } = await api.fetchAllCheckListEvents()
        
        dispatch({ type: FETCH_ALL_CHECKLISTEVENTS, payload: data })
            
    } catch (error) {
        
        console.log(error)
            
    }
}
        
export const UpdateCheckListEvent = (id, updatedCheckListEvent) => async (dispatch) => {

    try {
        
        const { data } = await api.updateCheckListEvent(id, updatedCheckListEvent)
        
        dispatch({ type: UPDATE_CHECKLISTEVENT, payload: data })
            
    } catch (error) {
        
        console.log(error)
            
    }

}
        
export const DeleteCheckListEvent = (id) => async (dispatch) => {

    try {
        
        await api.deleteCheckListEvent(id)
        
        dispatch({ type: DELETE_CHECKLISTEVENT, payload: id})
            
    } catch (error) {
        
        console.log(error)
            
    }

}
        