import { CREATE_FINISHED_TRANSFORMS, FETCH_FINISHED_TRANSFORMS } from "../constants/actionTypes"

const FinishedTransformsReducer = ( finishedTransforms = [], action ) => {
    switch (action.type) {
        case CREATE_FINISHED_TRANSFORMS:
            return [...finishedTransforms, action.payload]

        case FETCH_FINISHED_TRANSFORMS:
            return action.payload
    
        default:
            return finishedTransforms
    }
}

export default FinishedTransformsReducer