

export default function DatePickerValidation(startDate, endDate) {


    let errors = {};
  
    if(endDate - startDate < 0){
      errors.overTime = "לא ניתן להזין תאריך סיום מוקדם מתאריך ההתחלה"
    }
    if(endDate - new Date() > 0){
        errors.futureTimeEnd = "לא ניתן להזין תאריך מאוחר מהתאריך של היום"
      }
    if(startDate - new Date() > 0){
        errors.futureTimeStart = "לא ניתן להזין תאריך מאוחר מהתאריך של היום"
    }


    return errors;
  }

