import React, { useMemo } from "react";
import { useGetData } from "../hooks/getData";
import useAuth from "../hooks/useAuth";
import useSelectors from "../hooks/useSelectors";
import useSocket from "../hooks/useSocket";

export const DataContext = React.createContext();

const DataProvider = ({ children }) => {
  const { user, moked, client } = useAuth();
  const { socket } = useSocket();
  const {
    setMokedWorkerUpdater,
    setSiteInfoUpdater,
    setMalfunctionUpdater,
    setMalfunctionTypeUpdater,
    setChatMessageUpdater,
    setMokedScansSchemaUpdater,
    setFarmInfoUpdater,
    setOtherSiteInfoUpdater,
    setScoutsUpdater,
  } = useGetData(user);

  const {
    mokedWorkersName,
    malfunctionType,
    sitesInformation,
    farmsInformation,
    otherSiteInformation,
    shiftPageType,
    imageList,
    scoutInfo,
  } = useSelectors();

  const value = useMemo(() => {
    return {
      setMokedWorkerUpdater,
      setSiteInfoUpdater,
      setMalfunctionUpdater,
      setMalfunctionTypeUpdater,
      setChatMessageUpdater,
      setMokedScansSchemaUpdater,
      setFarmInfoUpdater,
      setOtherSiteInfoUpdater,
      setScoutsUpdater,
      user,
      moked,
      client,
      mokedWorkersName,
      malfunctionType,
      sitesInformation,
      farmsInformation,
      otherSiteInformation,
      shiftPageType,
      imageList,
      scoutInfo,
      socket,
    };
  }, [user, sitesInformation]);

  return <DataContext.Provider value={value}>{children}</DataContext.Provider>;
};

export default DataProvider;
