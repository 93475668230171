import { CREATE_ENTRY, FETCH_CLIENT_ENTRIES } from "../constants/actionTypes"

const EntriesReducer = ( entries = [], action ) => {
    switch (action.type) {
        case CREATE_ENTRY:
            return [...entries, action.payload]

        case FETCH_CLIENT_ENTRIES:
            return action.payload
    
        default:
            return entries
    }
}

export default EntriesReducer