import { CREATE_NEW_ACTIVE_ENTRY, FETCH_ALL_ACTIVE_ENTRIES, UPDATE_ACTIVE_ENTRY, DELETE_ACTIVE_ENTRY } from "../constants/actionTypes"

const activeEntriesReducer = ( activeEntries = [], action ) => {
    switch (action.type) {
        case CREATE_NEW_ACTIVE_ENTRY:
            return [...activeEntries, action.payload]

        case FETCH_ALL_ACTIVE_ENTRIES:
            return action.payload
        
        case UPDATE_ACTIVE_ENTRY:
            return activeEntries.map((entry) => entry._id === action.payload._id ? action.payload : entry)

        case DELETE_ACTIVE_ENTRY:
            return activeEntries.filter((entry) => entry._id !== action.payload)
    
        default:
            return activeEntries
    }
}

export default activeEntriesReducer