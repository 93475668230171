import {
  CREATE_ENTRY,
  DELETE_ACTIVE_ENTRY,
  FETCH_CLIENT_ENTRIES,
} from "../constants/actionTypes";

import * as api from "../api";

export const CreateEntry = (id, activeEntry) => async (dispatch) => {
  try {
    const { data } = await api.createEntry(id, activeEntry);

    dispatch({ type: CREATE_ENTRY, payload: data });

    dispatch({ type: DELETE_ACTIVE_ENTRY, payload: id });
  } catch (error) {
    console.log(error);
  }
};

export const FetchClientEntry = (userName, isMoked) => async (dispatch) => {
  try {
    const { data } = await api.fetchClientEntry(userName, isMoked);

    dispatch({ type: FETCH_CLIENT_ENTRIES, payload: data });
  } catch (error) {
    console.log(error);
  }
};
