const Validation = (entryData, employArray) => {

    let errors = {}
  
    if(!entryData.siteName){
      errors.siteName = "הכנס את שם האתר"
    }
    if(!entryData.companyName){
      errors.companyName = "הכנס שם חברה"
    }
    if(!entryData.creator){
        errors.creator = "הכנס את שמך"
    }
     if(employArray.length === 0){
      errors.employName = "הכנס שם עובד לרשימת הכניסה"
    }
    if(!entryData.approve){
      errors.approve = "הכנס את שם מאשר הכניסה"
    }

    return errors
  }
  
  export default Validation
  