
import { useRef } from "react"
import { NavigateBefore } from "@material-ui/icons"

export default function ManagerCanApproveList({ canApproveArray, setCanApproveArray, activeEntryEditId }) {

    const CanApproveName = useRef("")

    const ClickHandler = (e) => {

        e.preventDefault()

        if(activeEntryEditId){

            if(CanApproveName.current.value !== ""){
                setCanApproveArray([CanApproveName.current.value])
            }

        } else {
        
            if(CanApproveName.current.value !== ""){
                setCanApproveArray([...canApproveArray, CanApproveName.current.value])
            }

        }

        CanApproveName.current.value = ""
    }


    const deleteEmploy = (e) => {

        const CanApproveName = e.target.getAttribute("name")

        setCanApproveArray(canApproveArray.filter(employ => employ !== CanApproveName))
    }

    return (
        <>

            <div className="employList siteInfoList">
                <input type="text" placeholder="הכנס את שמות מאשרי הכניסה (רב בחירה)" 
                className="mokedWorkerInput" 
                ref = {CanApproveName}
                />
                <button onClick={ClickHandler} className="personAddButton AddIconInfo">הוסף</button>
            </div>
            <ul className="employListItem siteInfoListList">
                {canApproveArray.map((employ, index) => {
                    return (
                    <li key={index} name={employ} className="employItem siteInfoListItem" onClick={deleteEmploy}>
                        <NavigateBefore className="employItemIcon"/>
                        {employ}
                    </li>
                    )
                })}
            </ul>
         </>
    )
}
