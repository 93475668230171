
import { useEffect } from "react"

import TransformsDatePickerValidation from "./transformsDatePickerValidation"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"


export default function FinishedTransformsDatePicker({ startDate, setStartDate, endDate, setEndDate, errors, setErrors }) {

    useEffect(() => {

        setErrors(TransformsDatePickerValidation(startDate, endDate))

    }, [startDate, endDate, setErrors])

    return (
        <div className="EntryHistoryDatePicker">
            <div className="startDatePicker">
                <p className="startDatePickerTitle">בחר תאריך התחלה</p>
                <DatePicker className="datePickerItem" selected={startDate} onChange={(date) => setStartDate(date)}  dateFormat="d/MM/yyyy" />
                {errors.futureTimeStart && <p className="emptyField"> {errors.futureTimeStart} </p>}
             </div>
             <div className="endDatePicker">
                <p className="startDatePickerTitle">בחר תאריך סיום</p>
                <DatePicker className="datePickerItem" selected={endDate} onChange={(date) => setEndDate(date)} dateFormat="d/MM/yyyy"/>
                {errors.overTime && <p className="emptyField"> {errors.overTime} </p>}
                {errors.futureTimeEnd && <p className="emptyField"> {errors.futureTimeEnd} </p>}
             </div>
        </div>
    )
}
