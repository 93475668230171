import "./mokedWorkers.css";

import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { EditSignUp, SignUp } from "../../../actions/auth";
import { DeleteMokedWorker } from "../../../actions/mokedWorkers";
import MokedWorkerName from "./MokedWorkerName/MokedWorkerName";

const MokedWorkers = ({ chosenCURDType, fetchMokedWorkers, setMokedWorkerUpdater }) => {

const [ creatorNameValue, setCreatorNameValue ] = useState("")

const [workerData, setWorkerData] = useState({
  userName: "",
  password: "",
  isMoked: true,
  isAhmash:false
});

return(
  <>
    { chosenCURDType && <div className="mokedWorker">
      <div className="mokedWorkerWrapper">
        <div className="mokedWorkerRight">
          <h1 className="mokedWorkerTitle">{chosenCURDType}</h1>
        </div>
          <div className="mokedWorkerLeft">
            {chosenCURDType === "רישום" ?
            <CreateMokedWorkerCRUD workerData={workerData} setWorkerData={setWorkerData} setMokedWorkerUpdater={setMokedWorkerUpdater} />
            : chosenCURDType === "עריכה" ?
            <EditMokedWorkerCRUD fetchMokedWorkers={fetchMokedWorkers} creatorNameValue={creatorNameValue} setCreatorNameValue={setCreatorNameValue}
             workerData={workerData} setWorkerData={setWorkerData} setMokedWorkerUpdater={setMokedWorkerUpdater} />
             :
             <DeleteMokedWorkerCRUD fetchMokedWorkers={fetchMokedWorkers} creatorNameValue={creatorNameValue} setCreatorNameValue={setCreatorNameValue}
             workerData={workerData} setWorkerData={setWorkerData} setMokedWorkerUpdater={setMokedWorkerUpdater} />
            }
          </div>
        </div>
      </div>}
      </>
      )
}

export default MokedWorkers;


const CreateMokedWorkerCRUD = ({ workerData, setWorkerData, isAhmashCheck, setMokedWorkerUpdater }) => {

  const dispatch = useDispatch();
    
  const handleSubmit = (e) => {
    e.preventDefault()

    if(workerData.userName) {

      dispatch(SignUp(workerData))
  
      Clear()
    }

  }
  
  const Clear = () => {
  
    setWorkerData({
      userName: "",
      password: "",
      isMoked: true,
      isAhmash:false
    })

    setMokedWorkerUpdater(prev => !prev)
    }

  return(
    <form className="mokedWorkerForm" onSubmit= {handleSubmit}>
      <input type="text" id="login" className="mokedWorkerInput" name="login" value={workerData.userName} placeholder="שם העובד" onChange={(e) => setWorkerData({ ...workerData, userName: e.target.value })}/>
      <input type="text" className="mokedWorkerInput" value={workerData.password} placeholder="סיסמא" onChange={(e) => setWorkerData({ ...workerData, password: e.target.value })}/>
      <div className="BottomFormMokedWorkers">
        <div className="BottomFormMokedWorkersAhmash">
          <p className="ahmashTitle">האם העובד הוא אחמ"ש?</p>
          <input type="checkbox" className="transformsFormCheck" checked={workerData.isAhmash} onChange={(e) => setWorkerData((prev) => ({...prev, isAhmash: !workerData.isAhmash}))} />
        </div>
        <div className="BottomFormMokedWorkers">
          <input type="submit" className="BottomFormMokedWorkersButton enterCRUDButton" value="רשום עובד" />
        </div>
      </div>
  </form>
  )
}

const EditMokedWorkerCRUD = ({ fetchMokedWorkers, creatorNameValue, setCreatorNameValue, workerData, setWorkerData, isAhmashCheck, setMokedWorkerUpdater }) => {

  const dispatch = useDispatch();

  const chosenMokedWorker = fetchMokedWorkers.find(worker => worker.userName === creatorNameValue)

  useEffect(() => {

    setWorkerData({
      userName: chosenMokedWorker?.userName,
      password: chosenMokedWorker?.password || '',
      isMoked: true,
      isAhmash: chosenMokedWorker?.isAhmash || false,
    })
  }, [chosenMokedWorker])
    
  const handleSubmit = (e) => {
    e.preventDefault()

    if(workerData.userName && chosenMokedWorker) {
  
    dispatch( EditSignUp( chosenMokedWorker._id, workerData ))
  
    Clear()

    }
  }
  
  const Clear = () => {
  
    setWorkerData({
      userName: "",
      password: "",
      isMoked: true,
      isAhmash:false
    })
    
    setMokedWorkerUpdater(prev => !prev)
    setCreatorNameValue("")
  }

  return(
    <>
      <div className="EditMokedWorkerCRUDTop">
        <MokedWorkerName fetchMokedWorkers={fetchMokedWorkers} creatorNameValue={creatorNameValue} setCreatorNameValue={setCreatorNameValue} />
      </div>
      <form className="mokedWorkerForm" onSubmit= {handleSubmit}>
        <input type="text" id="login" className="mokedWorkerInput" name="login" value={workerData.userName} placeholder="שם העובד" onChange={(e) => setWorkerData({ ...workerData, userName: e.target.value })}/>
        <input type="text" className="mokedWorkerInput" value={workerData.password} placeholder="סיסמא" onChange={(e) => setWorkerData({ ...workerData, password: e.target.value })}/>
        <div className="BottomFormMokedWorkers">
          <div className="BottomFormMokedWorkersAhmash">
            <p className="ahmashTitle">האם העובד הוא אחמ"ש?</p>
            <input type="checkbox" className="transformsFormCheck" checked={workerData.isAhmash} onChange={(e) => setWorkerData((prev) => ({...prev, isAhmash: !workerData.isAhmash}))} />
          </div>
          <div className="BottomFormMokedWorkers">
            <input type="submit" className="BottomFormMokedWorkersButton editCRUDButton" value="ערוך עובד" />
          </div>
        </div>
      </form>   
   </>
  )
}

const DeleteMokedWorkerCRUD = ({ fetchMokedWorkers, creatorNameValue, setCreatorNameValue, workerData, setWorkerData, isAhmashCheck, setMokedWorkerUpdater }) => {

  const dispatch = useDispatch();

  const chosenMokedWorker = fetchMokedWorkers.find(worker => worker.userName === creatorNameValue)
    
  const handleSubmit = (e) => {
    e.preventDefault()

    if(chosenMokedWorker) {
  
    dispatch( DeleteMokedWorker( chosenMokedWorker._id ))
  
    Clear()

    }
  }
  
  const Clear = () => {
    
    setMokedWorkerUpdater(prev => !prev)
    setCreatorNameValue("")
  }

  return(
    <>
      <div className="EditMokedWorkerCRUDTop">
        <MokedWorkerName fetchMokedWorkers={fetchMokedWorkers} creatorNameValue={creatorNameValue} setCreatorNameValue={setCreatorNameValue} />
      </div>
      <form className="mokedWorkerForm" onSubmit= {handleSubmit}>
        <div className="BottomFormMokedWorkers">
          <div className="BottomFormMokedWorkers">
            <input type="submit" className="BottomFormMokedWorkersButton deleteCRUDButton" value="מחק עובד" />
          </div>
        </div>
      </form>   
   </>
  )
}