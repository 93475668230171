import './mokedScansMain.css'
import MokedScansSiteScans from './MokedScansSiteScans/MokedScansSiteScans'

export default function MokedScansMain({mokedScanSites, mokedScansSchemaData}) {

    const amountOfScans = Object.keys(mokedScanSites).length > 0 ? Array.from(new Array(mokedScanSites.amountOfScans + 1)) : null

  return (
    <div className='MokedScansMainContainer'>
        {Object.keys(mokedScanSites).length === 0 ?
            <div>
                <p>בחר את סוג הטופס הרלוונטיים</p>
            </div>
        :
        <>
            <div className='MokedScansMain'>
                {amountOfScans.map((title, index) => {
                    return(
                        index === 0 
                        ?
                            <h2 key={index} >{mokedScanSites.timeOfDay}</h2>
                        :
                            <h3 key={index} >שעת סריקה</h3>
                    )
                })}
            </div>
            <div >
                {mokedScanSites.sitesToScan.map((site) => {  
                    return(
                        <MokedScansSiteScans key={site._id + site.siteName} mokedScansSchemaData={mokedScansSchemaData} amountOfScans={amountOfScans} site={site} />
                    )
                })}
            </div>
        </>
        }
    </div>
  )
}
