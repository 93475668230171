
import { CREATE_NEW_MOKEDSCANS, FETCH_ALL_MOKEDSCANS, UPDATE_MOKEDSCANS, DELETE_MOKEDSCANS } from "../constants/actionTypes"

import * as api from "../api"
        
export const CreateMokedScans = (newMokedScans) => async (dispatch) => {
    try {
        
        const { data } = await api.createMokedScans(newMokedScans)
        
        dispatch({ type: CREATE_NEW_MOKEDSCANS, payload: data })
            
    } catch (error) {
        
        console.log(error)
            
    }
}
        
export const FetchAllMokedScans = () => async (dispatch) => {

    try {
        
        const { data } = await api.fetchAllMokedScans()
        
        dispatch({ type: FETCH_ALL_MOKEDSCANS, payload: data })
            
    } catch (error) {
        
        console.log(error)
            
    }
}
        
export const UpdateMokedScans = (id, updatedMokedScans) => async (dispatch) => {

    try {
        
        const { data } = await api.updateMokedScans(id, updatedMokedScans)
        
        dispatch({ type: UPDATE_MOKEDSCANS, payload: data })
            
    } catch (error) {
        
        console.log(error)
            
    }

}
        
export const DeleteMokedScans = (id) => async (dispatch) => {

    try {
        
        await api.deleteMokedScans(id)
        
        dispatch({ type: DELETE_MOKEDSCANS, payload: id})
            
    } catch (error) {
        
        console.log(error)
            
    }

}
        