import EntriesHistoryList from "../EntriesHistoryList/EntriesHistoryList"
import ClientEntriesInstructions from "./ClientEntriesInstructions/ClientEntriesInstructions"
import "./clientEntriesLeft.css"

export default function clientEntriesLeft ({ userName, siteInformationArrayDateSorted, startDate, endDate, chosenSiteName }) {


    return (
        <div className="ClientEntriesLeft">
            <div className="ClientEntriesLeftWrapper">
               {siteInformationArrayDateSorted ? <EntriesHistoryList siteInformationArrayDateSorted={siteInformationArrayDateSorted}
                startDate={startDate} endDate={endDate} chosenSiteName={chosenSiteName} /> 
            : <ClientEntriesInstructions userName={userName}/>}
            </div>
        </div>
    )
}
