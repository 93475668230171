import "./finishedTransformsRight.css"

import FinishedTransformsInformation from "./FinishedTransformsInformation/FinishedTransformsInformation"
import FinishedTransformsDatePicker from "./FinishedTransformsDatePicker/FinishedTransformsDatePicker"
import ChosenDriverName from "./ChosenDriverName/ChosenDriverName"

export default function FinishedTransformsRight({driversNameArray, chosenDriverName, setChosenDriverName, filteredFinishedTransformsDateSorted, startDate, setStartDate, endDate, setEndDate, errors, setErrors}) {

    return (

        <div className="ClientEntriesRightRight">
            <div className="ClientEntriesRightWrapper">

                <h3 className="ClientEntriesRightTitle">ארכיון שינועים</h3>
                <p className="chosenDriverName">מיין לפי נהג שינוע</p>
                <ChosenDriverName driversNameArray={driversNameArray} chosenDriverName={chosenDriverName} setChosenDriverName={setChosenDriverName} />
                {chosenDriverName && <FinishedTransformsDatePicker startDate={startDate} setStartDate={setStartDate} 
                endDate={endDate} setEndDate={setEndDate} 
                errors={errors} setErrors={setErrors} />}
                <div className="finishedTransformsInformationContainer">
                {chosenDriverName && <FinishedTransformsInformation filteredFinishedTransformsDateSorted={filteredFinishedTransformsDateSorted} />}
                </div>
                <div className="backgroundImgMataraClient">
                        <img src="/assets/MataraLogoForAssom.png" alt="MataraLogo" className="MataraLogoImgClient" />
                </div>
            </div>
            
        </div>
    )
}