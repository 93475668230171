import "./malfunctionsMain.css";

import { useState } from "react";

import { Add } from "@material-ui/icons";

import axios from "axios";
import { saveAs } from "file-saver";

import MalfunctionsSiteTable from "./MalfunctionsSite/MalfunctionsSiteTable";
import MalfunctionWindow from "./MalfunctionWindow/MalfunctionWindow";
import MalfunctionDateFilter from "./malfunctionDatePicker/malfunctionDateFilter";

export default function MalfunctionsMain({
  chosenGroup,
  sitesInformation,
  mokedWorkersName,
  allMalfunction,
  setMalfunctionUpdater,
  isArchive,
  malfunctionType,
}) {
  const [openWindow, setOpenWindow] = useState(false);
  const [chosenMalfunction, setChosenMalfunction] = useState("");
  const [pickedSite, setPickedSite] = useState("");
  const [dateFilterOpen, setDatePickerOpen] = useState(false);

  let date = new Date();
  date.setMonth(date.getMonth() - 1);
  const [startDate, setStartDate] = useState(date);
  const [endDate, setEndDate] = useState(new Date());
  const [errors, setErrors] = useState({ 1: 1 });

  let now = new Date();

  let sitesInformationFiltered;

  let sortedMalfunctionGroup;

  if (Object.keys(sitesInformation).length > 0) {
    sitesInformationFiltered = sitesInformation?.filter(
      (malfunction) => malfunction.malfunctions[0] !== undefined
    );

    sortedMalfunctionGroup = sitesInformationFiltered.filter(
      (group) => group.malfunctions[0].malfunctionGroup === chosenGroup
    );
  }

  const EditHandler = (id) => {
    setOpenWindow(true);
    setChosenMalfunction(id);
  };

  let allMalfunctionActive;
  let allMalfunctionArchive;

  if (allMalfunction) {
    allMalfunctionActive = allMalfunction?.filter(
      (malfunction) => malfunction.updated_At === undefined
    );
    allMalfunctionArchive = allMalfunction?.filter(
      (malfunction) => malfunction.updated_At !== undefined
    );
  }

  let malfunctionDateSorted = null;

  if (allMalfunctionArchive) {
    let EndDatePlus = new Date(endDate);

    EndDatePlus.setDate(EndDatePlus.getDate() + 0.5);

    let startDateSorted = allMalfunctionArchive.filter(
      (entry) => new Date(entry.created_At) - startDate >= 0
    );

    malfunctionDateSorted = startDateSorted.filter(
      (entry) => EndDatePlus - new Date(entry.created_At) >= 0
    );

    allMalfunction = [...allMalfunctionActive, ...malfunctionDateSorted];
  }

  const DownloadPdf = () => {
    console.log("sortedMalfunctionGroup", sortedMalfunctionGroup);

    axios
      .post("/create-malfunction-pdf", {
        sortedMalfunctionGroup,
        allMalfunction,
      })
      .then(() =>
        axios.get("/fetch-malfunction-pdf", { responseType: "arraybuffer" })
      )
      .then((res) => {
        const pdfBlob = new Blob([res.data], { type: "application/pdf" });

        saveAs(pdfBlob, `דו"ח תקלות ${chosenGroup}}.pdf`);
      });
  };

  return (
    <div className="MainMalfunctions">
      <div className="malfunctionGroupTitleContainer">
        {isArchive ? (
          <span className="malfunctionGroupTitle">
            דו"ח תקלות {chosenGroup}
          </span>
        ) : (
          <span className="malfunctionGroupTitle">
            ארכיון תקלות {chosenGroup}
          </span>
        )}
        <div>
          <input
            className="groupMalfunctionListInput"
            type="text"
            list="siteSuggestion"
            size="50px"
            placeholder="חפש אתר"
            value={pickedSite}
            onChange={(e) => setPickedSite(e.target.value)}
          />
          <datalist id="siteSuggestion">
            {sortedMalfunctionGroup &&
              sortedMalfunctionGroup.map((site, index) => {
                return <option key={index}>{site.siteName}</option>;
              })}
          </datalist>
          {!isArchive && (
            <button
              className="malfunctionFilterBtn"
              onClick={() => setDatePickerOpen(!dateFilterOpen)}
            >
              סנן
            </button>
          )}
          {dateFilterOpen && (
            <MalfunctionDateFilter
              startDate={startDate}
              setStartDate={setStartDate}
              endDate={endDate}
              setEndDate={setEndDate}
              errors={errors}
              setErrors={setErrors}
            />
          )}
        </div>
      </div>
      <div className="malfunctionGroupNoteContainer">
        {isArchive &&
          sortedMalfunctionGroup &&
          sortedMalfunctionGroup.map((site) => {
            return site.malfunctions[0].malfunctionGroupNotes.map(
              (note, index) => {
                return (
                  <h3 key={index} className="malfunctionGroupNote">
                    {note}
                  </h3>
                );
              }
            );
          })}
      </div>

      <div className="MalfunctionsSiteContainer">
        <MalfunctionsSiteTable
          DownloadPdf={DownloadPdf}
          pickedSite={pickedSite}
          EditHandler={EditHandler}
          sortedMalfunctionGroup={sortedMalfunctionGroup}
          isArchive={isArchive}
          now={now}
          setMalfunctionUpdater={setMalfunctionUpdater}
          mokedWorkersName={mokedWorkersName}
          chosenGroup={chosenGroup}
          allMalfunction={allMalfunction}
        />
      </div>

      <div className="addMalfunctionContainer">
        {isArchive && (
          <button
            className="addNewMalfunction"
            onClick={() => setOpenWindow(!openWindow)}
          >
            <Add className="addNewMalfunctionIcon" />
          </button>
        )}
      </div>

      {openWindow && sortedMalfunctionGroup && (
        <MalfunctionWindow
          setOpenWindow={setOpenWindow}
          sortedMalfunctionGroup={sortedMalfunctionGroup}
          mokedWorkersName={mokedWorkersName}
          chosenMalfunction={chosenMalfunction}
          setChosenMalfunction={setChosenMalfunction}
          setMalfunctionUpdater={setMalfunctionUpdater}
          malfunctionType={malfunctionType}
        />
      )}
    </div>
  );
}
