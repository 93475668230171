export default function MalfunctionDatePickerValidation(startDate, endDate) {
  const startDateValue = new Date(new Date(startDate).setHours(0, 0, 0, 0));
  const endDateValue = new Date(new Date(endDate).setHours(23, 59, 59, 999));
  const now = new Date(new Date().setHours(23, 59, 59, 999));

  let errors = {};

  if (endDateValue - startDateValue < 0) {
    errors.overTime = "לא ניתן להזין תאריך סיום מוקדם מתאריך ההתחלה";
  }
  if (endDateValue - now > 0) {
    errors.futureTimeEnd = "לא ניתן להזין תאריך מאוחר מהתאריך של היום";
  }
  if (startDateValue - now > 0) {
    errors.futureTimeStart = "לא ניתן להזין תאריך מאוחר מהתאריך של היום";
  }

  return errors;
}
