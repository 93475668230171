
import { useRef } from "react"
import { NavigateBefore } from "@material-ui/icons"

export default function ManagerGroupNotes({ malfunctionGroupNotes, setMalfunctionGroupNotes, activeEntryEditId }) {

    const CanApproveName = useRef("")

    const ClickHandler = (e) => {

        e.preventDefault()

        if(activeEntryEditId){

            if(CanApproveName.current.value !== ""){
                setMalfunctionGroupNotes([CanApproveName.current.value])
            }

        } else {
        
            if(CanApproveName.current.value !== ""){
                
                if(malfunctionGroupNotes){
                    setMalfunctionGroupNotes([...malfunctionGroupNotes, CanApproveName.current.value])
                }else{
                    setMalfunctionGroupNotes([CanApproveName.current.value])
                }
                
            }

        }

        CanApproveName.current.value = ""
    }


    const deleteEmploy = (e) => {

        const CanApproveName = e.target.getAttribute("name")

        setMalfunctionGroupNotes(malfunctionGroupNotes.filter(employ => employ !== CanApproveName))
    }

    return (
        <>

            <div className="employList siteInfoList">
                <input type="text" placeholder="הערות לקבוצת תקלות (רב בחירה)" 
                className="mokedWorkerInput" 
                ref = {CanApproveName}
                />
                <button onClick={ClickHandler} className="personAddButton AddIconInfo">הוסף</button>
            </div>
            <ul className="employListItem siteInfoListList">
                {malfunctionGroupNotes && malfunctionGroupNotes.map((employ, index) => {
                    return (
                    <li key={index} name={employ} className="employItem siteInfoListItem" onClick={deleteEmploy}>
                        <NavigateBefore className="employItemIcon"/>
                        {employ}
                    </li>
                    )
                })}
            </ul>
         </>
    )
}
