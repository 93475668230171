import {
  FETCH_SITE_INFORMATION,
  CREATE_NEW_SITE_INFORMATION,
  UPDATE_SITE_INFORMATION,
  DELETE_SITE_INFORMATION,
} from "../constants/actionTypes";

import * as api from "../api";

export const FetchSiteInformation = (id) => async (dispatch) => {
  try {
    const { data } = await api.fetchSiteInformation(id);

    dispatch({ type: FETCH_SITE_INFORMATION, payload: data });
  } catch (error) {
    console.log(error);
  }
};

export const CreateSiteInformation =
  (newSiteInformation) => async (dispatch) => {
    try {
      const { data } = await api.createSiteInformation(newSiteInformation);

      dispatch({ type: CREATE_NEW_SITE_INFORMATION, payload: data });
    } catch (error) {
      console.log(error);
    }
  };

export const UpdateSiteInformation =
  (id, updatedSiteInformation) => async (dispatch) => {
    try {
      const { data } = await api.updateSiteInformation(
        id,
        updatedSiteInformation
      );

      dispatch({ type: UPDATE_SITE_INFORMATION, payload: data });
    } catch (error) {
      console.log(error);
    }
  };

export const DeleteSiteInformation = (id) => async (dispatch) => {
  try {
    await api.deleteSiteInformation(id);

    dispatch({ type: DELETE_SITE_INFORMATION, payload: id });
  } catch (error) {
    console.log(error);
  }
};
