import './chatMessages.css'
import MessageBox from './MessageBox/MessageBox'

export default function ChatMessages({ user, Messages }) {

  return (
    <div>
        {Messages.map((message, index) => {

            const MyMessage = message.user === user
            const Follow = index !== 0 && Messages[index - 1].user === message.user
            const lastMessage = Messages.length - 1 === index


            return(
                <MessageBox key={index} lastMessage={lastMessage} message={message} MyMessage={MyMessage} Follow={Follow} />
            )
        })}
    </div>
  )
}
