import "./clientEntriesInstructions.css"

export default function ClientEntriesInstructions({ userName }) {

    const Time = new Date().toString().slice(16,18)

    let TimeOfDay = "שלום"

  
        if( 4 <= Time && Time <= 12){

            TimeOfDay = "בוקר טוב"

        }else if(13 <= Time && Time <= 17){

            TimeOfDay = "צהריים טובים"

        }else if( 18 <= Time && Time <= 23){

            TimeOfDay = "ערב טוב"

        }else if( 0 <= Time && Time <= 5){

            TimeOfDay = "לילה טוב"

        }


    return (

        <div className="ClientEntriesInformationInstructions">
            <p className="ClientInstructions"> {TimeOfDay} {userName},</p>
            <p className="ClientInstructions">לרשותך מערכת לניהול ובקרת כל הכניסות לחוות הסולאריות הנמצאות תחת פיקוחך.</p>
            <p className="ClientInstructions">בשדה הראשון תוכל לבחור באיזו חווה תרצה לצפות ולקבל אינפורמציה לרבות,</p>
            <p className="ClientInstructions">שם הנכנס, מאיזו חברה הוא הגיע, שעת הכניסה והיציאה שלו מהחווה, מי אישר את כניסתו והערות לכניסה אם היו.</p>
            <p className="ClientInstructions">כניסות המסומנות בצבע <span className="markedWord">ירוק</span> הן כניסות פעילות של עובדים שנמצאים בזמן זה בחווה.</p>
            <p className="ClientInstructions">מיד לאחר בחירת החווה יופיעו עבורך שדות נוספים:</p>
            <p className="ClientInstructions">כמות הכניסות הפעילות בחווה כרגע.</p>
            <p className="ClientInstructions">כמות הכניסות והיציאות הכללית שנרשמה לחווה מרגע התחלת פעילות המוקד עם אתר זה.</p>
            <p className="ClientInstructions">בנוסף יפתחו שני שדות נוספים לבחירת תאריכים.</p>
            <p className="ClientInstructions">כברירת מחדל מוצגות לך הכניסות החל מחודש אחורה, ביכולתך לשנות את תאריך ההתחלה והסיום כרצונך.</p>
            <p className="ClientInstructions">לאחר שבחרת את התאריכים שבהם אתה מעוניין, תוכל להוריד קובץ PDF שיכיל את קובץ הכניסות על פי הפרמטרים שציינת.</p>


            
        </div>
    )
}
