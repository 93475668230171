import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { SignIn } from "../../../actions/auth";

const ClientSignIn = () => {
  const history = useHistory();

  const dispatch = useDispatch();

  const [connectData, setConnectData] = useState({
    userName: "",
    password: "",
    isMoked: false,
  });
  const [isError, setError] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const result = await dispatch(SignIn(connectData, history));

      if (!result) setError(true);
    } catch (error) {
      setError(true);
    }
  };

  return (
    <div>
      <div className="wrapper fadeInDown">
        <div id="formContent">
          <div className="fadeIn first">
            <img src="/assets/assom2.0.png" id="icon" alt="User Icon" />
          </div>

          <form onSubmit={handleSubmit}>
            <input
              type="text"
              value={connectData.userName}
              id="login"
              className="input fadeIn second"
              name="login"
              placeholder="שם משתמש"
              onChange={(e) =>
                setConnectData({
                  ...connectData,
                  userName: e.target.value,
                })
              }
            />
            <input
              value={connectData.password}
              type="password"
              id="password"
              className="input fadeIn third"
              name="login"
              placeholder="סיסמא"
              onChange={(e) =>
                setConnectData({
                  ...connectData,
                  password: e.target.value,
                })
              }
            />
            {isError && (
              <p className="signInErrorMessage">אחד מהפרטים שהוזנו שגוי</p>
            )}
            <input
              type="submit"
              className="input fadeIn fourth"
              value="התחבר"
            />
          </form>
        </div>
      </div>
    </div>
  );
};

export default ClientSignIn;
