import './shiftPageSection.css'
import { useState, useEffect } from 'react'
import { confirmAlert } from "react-confirm-alert"
import { useDispatch } from 'react-redux'
import { CreateShiftPage, UpdateShiftPage, DeleteShiftPage } from '../../../../actions/shiftPage'
import { Backspace, Edit, ArrowForward } from "@material-ui/icons"


export default function ShiftPageSection(props) {

    const dispatch = useDispatch()
    const {filterShiftData, type, isBigMoked} = props;

    const [ shiftPageData, setShiftPageData ] = useState({
        note: "",
        noteType: type,
        mokedType: isBigMoked,
    })


    const data = filterShiftData.filter(data => data.noteType === type)

    const [ lineId, setLineId ] = useState("")

    useEffect(() => {

        setShiftPageData({
            note: "",
            noteType: type,
            mokedType: isBigMoked,
        })

    }, [isBigMoked, type])

    const handleSubmit = (e) => {
        e.preventDefault()

        if(!shiftPageData.note) return setLineId('')

        if(lineId){

            setLineId('')
            dispatch(UpdateShiftPage(lineId, shiftPageData))
        }else{

            dispatch(CreateShiftPage(shiftPageData))
        }

        setShiftPageData({
            note: "",
            noteType: type,
            mokedType: isBigMoked,
        })
    }

    const DeletePageShift = () => {
        
        setLineId('')
        dispatch(DeleteShiftPage(lineId))
    }

  return (
    <div className='ShiftPageSection' onSubmit={handleSubmit} >
        <div>
        <h2>{type}</h2>
        <div className='ShiftPageSectionList' > 
        {data.map(item => {

            const time = item.created_At.slice(15, 21)
            const date = item.created_At.slice(4, 10)
            return(
                <div key={item._id} className='ShiftPageSectionItemContainer' >
                    <div className='shiftPageTimeAndItemContainer' >
                        <p className='shiftPageTime' >{date}</p>
                        <p className='shiftPageTime' >{time}</p>
                        <h4 className='ShiftPageSectionItem' onClick={() => setLineId(item._id)} >{item.note}</h4>
                    </div>
                    {lineId === item._id &&
                        <div>
                            <ArrowForward className='shiftPageItemBack' onClick={() => setLineId('')}  />
                            <Edit className='shiftPageItemEdit' onClick={() => setShiftPageData(prev => ({
                                ...prev,
                                note: item.note
                            }))} />
                            <Backspace className='shiftPageItemDelete' onClick={() => {
                                confirmAlert({
                                    title: <p className="approveTitle">אשר לביצוע הפעולה</p>,
                                    message: <p className="approveMessage"> האם אתה בטוח שברצונך למחוק הערה זאת?</p>,
                                    buttons: [
                                        {
                                            label: <p>בטוח</p>,
                                            onClick: () => {
                                                DeletePageShift()
                                                                    }
                                        },
                                        {
                                            label: <p>בטל</p>
                                        }
                                    ]
                                    })
                            }} />
                        </div>
                    }
                </div>
            )
        })}
        </div>
        </div>
        <form className='ShiftPageSectionForm' >
            <input type='text' className='ShiftPageSectionInput' value={shiftPageData.note} 
            onChange={(e) => setShiftPageData(prev => ({
                ...prev,
                note: e.target.value,
                noteType: type,
            }))}  />
            <input type='submit' className='ShiftPageSectionSubmit' />
        </form>

    </div>
  )
}
