import { CREATE_NEW_CHAT_MESSAGE, FETCH_ALL_CHAT_MESSAGE, UPDATE_CHAT_MESSAGE, DELETE_CHAT_MESSAGE } from "../constants/actionTypes"

import * as api from "../api"

export const CreateChatMessage = (newChatMessage) => async (dispatch) => {

  try {

    const { data } = await api.createChatMessage(newChatMessage)

    dispatch({ type: CREATE_NEW_CHAT_MESSAGE, payload: data })
    
  } catch (error) {

    console.log(error)
    
  }
}

export const FetchAllChatMessage = () => async (dispatch) => {
  try {

    const { data } = await api.fetchAllChatMessage()

    dispatch({ type: FETCH_ALL_CHAT_MESSAGE, payload: data })
    
  } catch (error) {

    console.log(error)
    
  }
}

export const UpdateChatMessage = (id, updatedChatMessage) => async (dispatch) => {
  try {

    const { data } = await api.updateChatMessage(id, updatedChatMessage)

    dispatch({ type: UPDATE_CHAT_MESSAGE, payload: data })
    
  } catch (error) {

    console.log(error)
    
  }
}

export const DeleteChatMessage = (id) => async (dispatch) => {
  try {

    await api.deleteChatMessage(id)

    dispatch({ type: DELETE_CHAT_MESSAGE, payload: id})
    
  } catch (error) {

    console.log(error)
    
  }
}