
import { CREATE_NEW_SCOUT, FETCH_ALL_SCOUTS, UPDATE_SCOUT, DELETE_SCOUT } from "../constants/actionTypes"

import * as api from "../api"
        
export const CreateScout = (newScout) => async (dispatch) => {
        
    try {
        
        const { data } = await api.createScout(newScout)
        
        dispatch({ type: CREATE_NEW_SCOUT, payload: data })
            
    } catch (error) {
        
        console.log(error)
            
    }
}
        
export const FetchAllScouts = () => async (dispatch) => {

    try {
        
        const { data } = await api.fetchAllScouts()
        
        dispatch({ type: FETCH_ALL_SCOUTS, payload: data })
            
    } catch (error) {
        
        console.log(error)
            
    }
}
        
export const UpdateScout = (id, updatedScout) => async (dispatch) => {

    try {
        
        const { data } = await api.updateScout(id, updatedScout)
        
        dispatch({ type: UPDATE_SCOUT, payload: data })
            
    } catch (error) {
        
        console.log(error)
            
    }

}
        
export const DeleteScout = (id) => async (dispatch) => {

    try {
        
        await api.deleteScout(id)
        
        dispatch({ type: DELETE_SCOUT, payload: id})
            
    } catch (error) {
        
        console.log(error)
            
    }

}
        