import { combineReducers } from "redux";

import siteInformation from "./SiteInformation";
import activeEntries from "./ActiveEntries";
import entries from "./Entries";
import auth from "./Auth";
import chatMessage from "./chatMessage";
import shiftPage from "./shiftPage";
import transforms from "./transforms";
import finishedTransforms from "./finishedTransforms";
import mokedWorkers from "./mokedWorkers";
import userClient from "./users";
import malfunctions from "./malfunction";
import malfunctionArchive from "./malfunctionArchive";
import malfunctionType from "./malfunctionType";
import popupMessage from "./popupMessage";
import mokedScansSchema from "./mokedScansSchema";
import mokedScans from "./mokedScans";
import ShiftPageType from "./shiftPageType";
import imageList from "./ImageList";
import checkListEvent from "./CheckListEvent";
import farmInformation from "./FarmsInformation";
import otherSiteInformation from "./OtherSiteInformation";
import scout from "./Scout";

export default combineReducers({
  siteInformation,
  activeEntries,
  entries,
  auth,
  chatMessage,
  transforms,
  finishedTransforms,
  mokedWorkers,
  userClient,
  malfunctions,
  malfunctionArchive,
  malfunctionType,
  shiftPage,
  popupMessage,
  mokedScansSchema,
  mokedScans,
  ShiftPageType,
  imageList,
  checkListEvent,
  farmInformation,
  otherSiteInformation,
  scout,
});
