
import { CREATE_NEW_FARMSINFORMATION, FETCH_ALL_FARMSINFORMATIONS, UPDATE_FARMSINFORMATION, DELETE_FARMSINFORMATION } from "../constants/actionTypes"

import * as api from "../api"
        
export const CreateFarmsInformation = (newFarmsInformation) => async (dispatch) => {
        
    try {
        
        const { data } = await api.createFarmsInformation(newFarmsInformation)
        
        dispatch({ type: CREATE_NEW_FARMSINFORMATION, payload: data })
            
    } catch (error) {
        
        console.log(error)
            
    }
}
        
export const FetchAllFarmsInformations = () => async (dispatch) => {

    try {
        
        const { data } = await api.fetchAllFarmsInformations()
        
        dispatch({ type: FETCH_ALL_FARMSINFORMATIONS, payload: data })
            
    } catch (error) {
        
        console.log(error)
            
    }
}
        
export const UpdateFarmsInformation = (id, updatedFarmsInformation) => async (dispatch) => {

    try {
        
        const { data } = await api.updateFarmsInformation(id, updatedFarmsInformation)
        
        dispatch({ type: UPDATE_FARMSINFORMATION, payload: data })
            
    } catch (error) {
        
        console.log(error)
            
    }

}
        
export const DeleteFarmsInformation = (id) => async (dispatch) => {

    try {
        
        await api.deleteFarmsInformation(id)
        
        dispatch({ type: DELETE_FARMSINFORMATION, payload: id})
            
    } catch (error) {
        
        console.log(error)
            
    }

}
        