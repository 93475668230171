export const FETCH_SITE_INFORMATION = "FETCH_SITE_INFORMATION"

export const CREATE_NEW_SITE_INFORMATION = "CREATE_NEW_SITE_INFORMATION"

export const UPDATE_SITE_INFORMATION = "UPDATE_SITE_INFORMATION"

export const DELETE_SITE_INFORMATION = "DELETE_SITE_INFORMATION"

export const CREATE_NEW_ACTIVE_ENTRY = "CREATE_NEW_ACTIVE_ENTRY"

export const FETCH_ALL_ACTIVE_ENTRIES = "FETCH_ALL_ACTIVE_ENTRIES"

export const UPDATE_ACTIVE_ENTRY = "UPDATE_ACTIVE_ENTRY"

export const DELETE_ACTIVE_ENTRY = "DELETE_ACTIVE_ENTRY"

export const CREATE_ENTRY = "CREATE_ENTRY"

export const FETCH_CLIENT_ENTRIES = "FETCH_CLIENT_ENTRIES"

export const CREATE_NEW_MALFUNCTION = "CREATE_NEW_MALFUNCTION"

export const FETCH_ALL_MALFUNCTIONS = "FETCH_ALL_MALFUNCTIONS"

export const UPDATE_MALFUNCTION = "UPDATE_MALFUNCTION"

export const DELETE_MALFUNCTION = "DELETE_MALFUNCTION"

export const CREATE_MALFUNCTION_ARCHIVE = "CREATE_MALFUNCTION_ARCHIVE"

export const FETCH_MALFUNCTION_ARCHIVE = "FETCH_MALFUNCTION_ARCHIVE"

export const AUTH = "AUTH"

export const AUTH_SIGNUP = "AUTH_SIGNUP"

export const AUTH_EDIT_SIGNUP = "AUTH_EDIT_SIGNUP"

export const LOGOUT = "LOGOUT"

export const CREATE_NEW_CHAT_MESSAGE = "CREATE_NEW_CHAT_MESSAGE"

export const FETCH_ALL_CHAT_MESSAGE = "FETCH_ALL_CHAT_MESSAGE"

export const UPDATE_CHAT_MESSAGE = "UPDATE_CHAT_MESSAGE"

export const DELETE_CHAT_MESSAGE = "DELETE_CHAT_MESSAGE"

export const CREATE_NEW_SHIFT_PAGE = "CREATE_NEW_SHIFT_PAGE"

export const FETCH_ALL_SHIFT_PAGE = "FETCH_ALL_SHIFT_PAGE"

export const UPDATE_SHIFT_PAGE = "UPDATE_SHIFT_PAGE"

export const DELETE_SHIFT_PAGE = "DELETE_SHIFT_PAGE"

export const CREATE_NEW_TRANSFORMS = "CREATE_NEW_TRANSFORMS"

export const FETCH_ALL_TRANSFORMS = "FETCH_ALL_TRANSFORMS"

export const UPDATE_TRANSFORMS = "UPDATE_TRANSFORMS"

export const DELETE_TRANSFORMS = "DELETE_TRANSFORMS"

export const CREATE_FINISHED_TRANSFORMS = "CREATE_FINISHED_TRANSFORMS"

export const FETCH_FINISHED_TRANSFORMS = "FETCH_FINISHED_TRANSFORMS"

export const UPDATE_MOKED_WORKER = "UPDATE_MOKED_WORKER"

export const DELETE_MOKED_WORKER = "DELETE_MOKED_WORKER"

export const CREATE_MOKED_WORKER = "CREATE_MOKED_WORKER"

export const FETCH_MOKED_WORKERS = "FETCH_MOKED_WORKERS"

export const UPDATE_USER_CLIENT = "UPDATE_USER_CLIENT"

export const DELETE_USER_CLIENT = "DELETE_USER_CLIENT"

export const FETCH_USER_CLIENTS = "FETCH_USER_CLIENTS"

export const UPDATE_MALFUNCTION_TYPE = "UPDATE_MALFUNCTION_TYPE"

export const DELETE_MALFUNCTION_TYPE = "DELETE_MALFUNCTION_TYPE"

export const CREATE_MALFUNCTION_TYPE = "CREATE_MALFUNCTION_TYPE"

export const FETCH_MALFUNCTION_TYPES = "FETCH_MALFUNCTION_TYPES"
        
export const CREATE_NEW_POPUPMESSAGE = "CREATE_NEW_POPUPMESSAGE"

export const FETCH_ALL_POPUPMESSAGES = "FETCH_ALL_POPUPMESSAGES"
        
export const UPDATE_POPUPMESSAGE = "UPDATE_POPUPMESSAGE"
        
export const DELETE_POPUPMESSAGE = "DELETE_POPUPMESSAGE"
        
export const CREATE_NEW_MOKEDSCANS = "CREATE_NEW_MOKEDSCANS"

export const FETCH_ALL_MOKEDSCANS = "FETCH_ALL_MOKEDSCANS"
        
export const UPDATE_MOKEDSCANS = "UPDATE_MOKEDSCANS"
        
export const DELETE_MOKEDSCANS = "DELETE_MOKEDSCANS"

        
export const CREATE_NEW_MOKED_SCANS_SCHEMA = "CREATE_NEW_MOKED_SCANS_SCHEMA"

export const FETCH_ALL_MOKED_SCANS_SCHEMAS = "FETCH_ALL_MOKED_SCANS_SCHEMAS"
        
export const UPDATE_MOKED_SCANS_SCHEMA = "UPDATE_MOKED_SCANS_SCHEMA"
        
export const DELETE_MOKED_SCANS_SCHEMA = "DELETE_MOKED_SCANS_SCHEMA"
        
export const CREATE_NEW_SHIFTPAGETYPE = "CREATE_NEW_SHIFTPAGETYPE"

export const FETCH_ALL_SHIFTPAGETYPES = "FETCH_ALL_SHIFTPAGETYPES"
        
export const UPDATE_SHIFTPAGETYPE = "UPDATE_SHIFTPAGETYPE"
        
export const DELETE_SHIFTPAGETYPE = "DELETE_SHIFTPAGETYPE"
        
export const CREATE_NEW_IMAGELIST = "CREATE_NEW_IMAGELIST"

export const FETCH_ALL_IMAGELISTS = "FETCH_ALL_IMAGELISTS"
        
export const UPDATE_IMAGELIST = "UPDATE_IMAGELIST"
        
export const DELETE_IMAGELIST = "DELETE_IMAGELIST"
        
export const CREATE_NEW_CHECKLISTEVENT = "CREATE_NEW_CHECKLISTEVENT"

export const FETCH_ALL_CHECKLISTEVENTS = "FETCH_ALL_CHECKLISTEVENTS"
        
export const UPDATE_CHECKLISTEVENT = "UPDATE_CHECKLISTEVENT"
        
export const DELETE_CHECKLISTEVENT = "DELETE_CHECKLISTEVENT"
        
export const CREATE_NEW_FARMSINFORMATION = "CREATE_NEW_FARMSINFORMATION"

export const FETCH_ALL_FARMSINFORMATIONS = "FETCH_ALL_FARMSINFORMATIONS"
        
export const UPDATE_FARMSINFORMATION = "UPDATE_FARMSINFORMATION"
        
export const DELETE_FARMSINFORMATION = "DELETE_FARMSINFORMATION"
        
export const CREATE_NEW_OTHERSITEINFORMATION = "CREATE_NEW_OTHERSITEINFORMATION"

export const FETCH_ALL_OTHERSITEINFORMATIONS = "FETCH_ALL_OTHERSITEINFORMATIONS"
        
export const UPDATE_OTHERSITEINFORMATION = "UPDATE_OTHERSITEINFORMATION"
        
export const DELETE_OTHERSITEINFORMATION = "DELETE_OTHERSITEINFORMATION"
        
export const CREATE_NEW_SCOUT = "CREATE_NEW_SCOUT"

export const FETCH_ALL_SCOUTS = "FETCH_ALL_SCOUTS"
        
export const UPDATE_SCOUT = "UPDATE_SCOUT"
        
export const DELETE_SCOUT = "DELETE_SCOUT"
        