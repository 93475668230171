import "./entriesHistoryList.css"

export default function EntriesHistoryList({ siteInformationArrayDateSorted, chosenSiteName }) {

    return (
        <div className="EntriesHistoryListContainers">
            <h1 className="siteNameTitles"> {chosenSiteName} </h1>
                    <ul className="EntryHistoryListTitles">
                        <li className="EntryHistoryItems">
                            שם העובד
                        </li>
                        <li className="EntryHistoryItems">
                            שם החברה
                        </li>
                        <li className="EntryHistoryItems EntryHistoryItemHours">
                            שעת כניסה
                        </li>
                        <li className="EntryHistoryItems">
                            שעת יציאה
                        </li>
                        <li className="EntryHistoryItems EntryHistoryApproves">
                            מאשר הכניסה
                        </li>
                        <li className="EntryHistoryItems">
                            הערות
                        </li>
                        <li className="EntryHistoryItems">
                            המוקדן המכניס
                        </li>
                    </ul>
                    <div className="siteInformationWrappers">
                        {siteInformationArrayDateSorted.map((entry, index) => {
                            return(
                                <ul className={entry.updated_At ? "EntryHistoryLists" : "EntryHistoryListActives"} key={index}>
                                    <li className="EntryHistoryItems">
                                        {entry.employName}
                                    </li>
                                    <li className="EntryHistoryItems">
                                        {entry.companyName}
                                    </li>
                                    <li className="EntryHistoryItems">
                                        {entry.created_At.slice(4,10)+" - "+entry.created_At.slice(16,21)}
                                    </li>
                                    <li className="EntryHistoryItems">
                                        {entry.updated_At && entry.updated_At?.slice(4,10)+" - "+entry.updated_At?.slice(16,21)}
                                    </li>
                                    <li className="EntryHistoryItems">
                                        {entry.approve}
                                    </li>
                                    <li className="EntryHistoryItems">
                                        {entry.sideNote || "---"}
                                    </li>
                                    <li className="EntryHistoryItems">
                                        {entry.creator}
                                    </li>
                                </ul>
                                
                                )
                            })}
                    </div>
        </div>
    )
}

