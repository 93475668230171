import React, { useState } from "react";
import ScoutsExcelExport from "../../utils/ScoutsExcelExport";
import "./closeScoutsInfo.css";
import FiltrerScouts from "./FiltrerScouts";
import CloseScoutEdit from "./closeScoutEdit";

function CloseScoutsInfo(props) {
  const {
    scouts,
    setScoutType,
    scoutsTypeNames,
    sitesInformation,
    mokedWorkersName,
    setScoutsUpdater,
  } = props;

  const [filteredScouts, setFilteredScouts] = useState(scouts);
  const [openScoutEdit, setOpenScoutEdit] = useState(null);

  if (!scouts?.length)
    return (
      <div className="closeScoutTypeLayoutContainer">
        <button
          className="closeScoutTypeLayoutBsckButton"
          onClick={() => setScoutType("")}
        >
          חזור
        </button>
        <h2>לא נמצאו סיורים</h2>
      </div>
    );

  const fileName = scoutsTypeNames[scouts[0].type];

  const scoutType = scouts[0].type;

  const handleEdit = (scout) => {
    console.log({ scout });
    setOpenScoutEdit(scout);
  };

  return (
    <div className="closeScoutTypeLayoutContainer">
      <button
        className="closeScoutTypeLayoutBsckButton"
        onClick={() => setScoutType("")}
      >
        חזור
      </button>
      <FiltrerScouts scouts={scouts} setFilteredScouts={setFilteredScouts} />
      <div className="closeScoutInfoWrapper">
        <div className="closeScoutLabels">
          <div className="closeScoutSmallLabelContainer">
            <h3>שם המוקדן</h3>
          </div>
          <div className="closeScoutSmallLabelContainer">
            <h3>שם האתר</h3>
          </div>
          <div className="closeScoutSmallLabelContainer">
            <h3>תאריך</h3>
          </div>
          <div className="closeScoutSmallLabelContainer">
            <h3>שעה</h3>
          </div>
          <div className="closeScoutSmallLabelContainer">
            <h3>שם הסייר</h3>
          </div>
          {scoutType === "weeklyScout" && (
            <div className="closeScoutLabelContainer">
              <h3>מקטעי גדר</h3>
            </div>
          )}
          {scoutType === "jumpScout" && (
            <div className="closeScoutLabelContainer">
              <h3>סיבת הקפצה</h3>
            </div>
          )}
          <div className="closeScoutLabelContainer">
            <h3>הערות</h3>
          </div>
          <div className="closeScoutSmallLabelContainer" />
        </div>
        {filteredScouts.map((scout, index) => (
          <div key={index} className="closeScoutLabels">
            <div className="closeScoutSmallLabelContainer">
              <h3>{scout.mokedWorker}</h3>
            </div>
            <div className="closeScoutSmallLabelContainer">
              <h3>{scout.siteName}</h3>
            </div>
            <div className="closeScoutSmallLabelContainer">
              <h3>{scout.date}</h3>
            </div>
            <div className="closeScoutSmallLabelContainer">
              <h3>{scout.hour}</h3>
            </div>
            <div className="closeScoutSmallLabelContainer">
              <h3>{scout.scouter}</h3>
            </div>
            {scoutType === "weeklyScout" && (
              <div className="closeScoutLabelContainer">
                <h3>{scout.fenceParts}</h3>
              </div>
            )}
            {scoutType === "jumpScout" && (
              <div className="closeScoutLabelContainer">
                <h3>{scout.jumpReason}</h3>
              </div>
            )}
            <div className="closeScoutLabelContainer">
              <h3>{scout.sideNote}</h3>
            </div>
            <div
              className="closeScoutSmallLabelEditDelete"
              onClick={() => handleEdit(scout)}
            >
              <h3>ערוך / מחק</h3>
            </div>
          </div>
        ))}
        <div className="malfunctionGroupTitleButtons">
          <ScoutsExcelExport scouts={filteredScouts} fileName={fileName} />
        </div>
        {openScoutEdit && (
          <CloseScoutEdit
            scoutData={openScoutEdit}
            setOpenScoutEdit={setOpenScoutEdit}
            scoutType={scoutType}
            sitesInformation={sitesInformation}
            mokedWorkersName={mokedWorkersName}
            setScoutsUpdater={setScoutsUpdater}
          />
        )}
      </div>
    </div>
  );
}

export default CloseScoutsInfo;
