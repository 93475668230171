import React from 'react'
import './mokedScansHours.css'

export default function MokedScansHours({currentSite, index, updateScanHours}) {


    const options = {
        weekday: "short",
        year: "numeric",
        month: "short",
        day: "2-digit",
        timeZone: 'Asia/Jerusalem',
        hour12: false,
        hour: '2-digit',
        minute:'2-digit',
        second: '2-digit' 
    }

    const saveScan = () => {

        const now = new Date()

        const currentDate = now.toLocaleString('en-US', options).replace(/,/g,"")
        const currentHour = currentDate.slice(16, 21)

        updateScanHours(currentHour)
    }



  return (
    <button className='MokedScansMainSiteHourButton' onClick={() => saveScan()} >
        {currentSite.timeOfScan[index-1]}
    </button>
    )
}
