import React from "react";
import "./Scouts.css";

function ScoutTypeLayout(props) {
  const { children, setScoutType } = props;

  return (
    <div className="ScoutTypeLayoutContainer">
      <button
        className="ScoutTypeLayoutBsckButton"
        onClick={() => setScoutType("")}
      >
        חזור
      </button>
      {children}
    </div>
  );
}

export default ScoutTypeLayout;
