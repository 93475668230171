
import { useRef } from "react"
import { NavigateBefore } from "@material-ui/icons"

export default function ManagerMaintenanceList({ maintenanceArray, setMaintenanceArray, activeEntryEditId }) {

    const maintenanceName = useRef("")

    const ClickHandler = (e) => {

        e.preventDefault()

        if(activeEntryEditId){

            if(maintenanceName.current.value !== ""){
                setMaintenanceArray([maintenanceName.current.value])
            }

        } else {
        
            if(maintenanceName.current.value !== ""){
                setMaintenanceArray([...maintenanceArray, maintenanceName.current.value])
            }

        }

        maintenanceName.current.value = ""
    }


    const deleteEmploy = (e) => {

        const maintenanceName = e.target.getAttribute("name")

        setMaintenanceArray(maintenanceArray.filter(employ => employ !== maintenanceName))
    }

    return (
        <>

            <div className="employList siteInfoList">
                <input type="text" placeholder="הכנס את שם חברת האחזקה (רב בחירה)" 
                className="mokedWorkerInput" 
                ref = {maintenanceName}
                />
                <button onClick={ClickHandler} className="personAddButton AddIconInfo">הוסף</button>
            </div>
            <ul className="employListItem siteInfoListList">
                {maintenanceArray.map((employ, index) => {
                    return (
                    <li key={index} name={employ} className="employItem siteInfoListItem" onClick={deleteEmploy}>
                        <NavigateBefore className="employItemIcon"/>
                        {employ}
                    </li>
                    )
                })}
            </ul>
         </>
    )
}
