
import { CREATE_NEW_MOKED_SCANS_SCHEMA, FETCH_ALL_MOKED_SCANS_SCHEMAS, UPDATE_MOKED_SCANS_SCHEMA, DELETE_MOKED_SCANS_SCHEMA } from "../constants/actionTypes"

const mokedScansSchemaReducer = ( mokedScansSchema = [], action ) => {

    switch (action.type) {

        case CREATE_NEW_MOKED_SCANS_SCHEMA:
            return [...mokedScansSchema, action.payload]
        
        case FETCH_ALL_MOKED_SCANS_SCHEMAS:
            return action.payload
                
        case UPDATE_MOKED_SCANS_SCHEMA:
            return mokedScansSchema.map((e) => e._id === action.payload._id ? action.payload : e)
        
        case DELETE_MOKED_SCANS_SCHEMA:
            return mokedScansSchema.filter((e) => e._id !== action.payload)
            
        default:
            return mokedScansSchema

    }
}
        
export default mokedScansSchemaReducer
        