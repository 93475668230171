
import { CREATE_NEW_FARMSINFORMATION, FETCH_ALL_FARMSINFORMATIONS, UPDATE_FARMSINFORMATION, DELETE_FARMSINFORMATION } from "../constants/actionTypes"

const FarmsInformationReducer = ( FarmsInformation = [], action ) => {

    switch (action.type) {

        case CREATE_NEW_FARMSINFORMATION:
            return [...FarmsInformation, action.payload]
        
        case FETCH_ALL_FARMSINFORMATIONS:
            return action.payload
                
        case UPDATE_FARMSINFORMATION:
            return FarmsInformation.map((e) => e._id === action.payload._id ? action.payload : e)
        
        case DELETE_FARMSINFORMATION:
            return FarmsInformation.filter((e) => e._id !== action.payload)
            
        default:
            return FarmsInformation

    }
}
        
export default FarmsInformationReducer
        