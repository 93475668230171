import classes from "./phoneExp.module.css"

import { useState } from "react"

import { CreateAccount } from "../../actions/phoneExp"

export default function CreateAccounts() {

    const [ account, setAccount ] = useState({
        fullname: "",
        enabled: true,
        username: "",
        email: "",
        role: "",
        password: "",
        environments: [],
        screens: []
    })

    const HandleSubmit = async (e) => {

        e.preventDefault()

        const result = await CreateAccount(account)

        console.log(result)
    }
    
  return (

      <div className={classes.createContainer}>
          <form className={classes.createForm} onSubmit={HandleSubmit} >
              <label className={classes.formLabel} > שם מלא </label>
              <input type="text" className={classes.inputItem} name="fullname" value={account.fullname} onChange={(e) => setAccount(prev => ({...prev, fullname: e.target.value}))}  />  
              <label className={classes.formLabel} > שם משתמש </label>
              <input type="text" className={classes.inputItem} name="username" value={account.username} onChange={(e) => setAccount(prev => ({...prev, username: e.target.value}))}  />  
              <label className={classes.formLabel} > אימייל </label>
              <input type="text" className={classes.inputItem} name="email" value={account.email} onChange={(e) => setAccount(prev => ({...prev, email: e.target.value}))}  />  
              <label className={classes.formLabel} > תפקיד </label>
              <input type="text" className={classes.inputItem} name="role" value={account.role} onChange={(e) => setAccount(prev => ({...prev, role: e.target.value}))}  />  
              <label className={classes.formLabel} > סיסמא </label>
              <input type="text" className={classes.inputItem} name="password" value={account.password} onChange={(e) => setAccount(prev => ({...prev, password: e.target.value}))}  />  
              <label className={classes.formLabel} > סביבה </label>
              <input type="text" className={classes.inputItem} name="environments" value={account.environments} onChange={(e) => setAccount(prev => ({...prev, environments: e.target.value}))}  />  
              <label className={classes.formLabel} > מסכים </label>
              <input type="text" className={classes.inputItem} name="screens" value={account.screens} onChange={(e) => setAccount(prev => ({...prev, screens: e.target.value}))}  />  
              <input type="submit" value="שלח" className={classes.inputBtn} />
          </form>
      </div>
  )
}
