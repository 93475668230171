import { useDispatch } from 'react-redux'
import { UpdateMokedScans } from '../../../../actions/mokedScans'
import MokedScansHours from './MokedScansHours/MokedScansHours'
import './mokedScansSiteScans.css'

export default function MokedScansSiteScans({site, amountOfScans, mokedScansSchemaData}) {

    const dispatch = useDispatch()

    const currentSite = mokedScansSchemaData.sitesToScan.find(sites => sites.siteName === site.siteName)

    const updateScanHours = (currentHour) => {

        console.log(currentSite)

        const updatedHours = mokedScansSchemaData.sitesToScan.map(site => {
            if(site.siteName === currentSite.siteName){
                return {
                    mokedType: site.mokedType,
                    siteName: site.siteName,
                    timeOfScan: [...currentSite.timeOfScan, currentHour]
                }
            } else {
                return site
            }})
        const updatedMokedScan = {
            amountOfScans: mokedScansSchemaData.amountOfScans,
            currentDate: mokedScansSchemaData.currentDate,
            mokedSize: mokedScansSchemaData.mokedSize,
            sitesToScan: updatedHours,
            timeOfDay: mokedScansSchemaData.timeOfDay
        } 
        console.log(updatedMokedScan)


        dispatch(UpdateMokedScans(mokedScansSchemaData._id, updatedMokedScan))

        }
  return (
    <div className='MokedScansMain'>
        {amountOfScans.map((row, index) => {
            return(
                index === 0 
                ?
                    <h3 key={site._id} className='MokedScansMainSiteName'>{site.siteName}</h3>
                :
                    <MokedScansHours key={site._id + index} currentSite={currentSite} index={index} updateScanHours={updateScanHours} />
                )
        })}
    </div>
  )
}
