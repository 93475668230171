import React from "react";
import { WarningRounded } from "@material-ui/icons";

const OtherSiteInfo = (props) => {
  const { chosenSiteInformation: information } = props;

  return (
    <div className="farmInfoRight">
      <ul className="siteInformationList">
        <li className="siteInformationItem">
          שם הלקוח: {information.siteName}
        </li>
        <li className="siteInformationItem">אבטחה: {information.security}</li>
        <li className="siteInformationItem">
          אחראי אבטחה: {information.manager}
        </li>
        <li className="siteInformationItem">
          האם מחוברת טכנולוגיה: {information.connected}
        </li>
        <li className="siteInformationItem">
          האם טכנולוגיה בשירות שלנו: {information.ourTech}
        </li>
        <li className="siteInformationItem">מיקום: {information.location}</li>
        <li className="siteInformationItem">
          האם ישנן פונציית שערים: {information.gate}
        </li>
        <ul className="sideNoteList">
          <WarningRounded className="warningSignIcon" />
          {!information.sideNote.length > 1 ? (
            information.sideNote?.map((note, index) => {
              return (
                <li className="informationSideNote" key={index}>
                  {note}
                </li>
              );
            })
          ) : (
            <li className="informationSideNote">{information.sideNote}</li>
          )}
        </ul>
      </ul>
    </div>
  );
};
export default OtherSiteInfo;
