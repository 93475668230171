import { CREATE_MALFUNCTION_TYPE, FETCH_MALFUNCTION_TYPES, UPDATE_MALFUNCTION_TYPE, DELETE_MALFUNCTION_TYPE } from "../constants/actionTypes"

import * as api from "../api"

export const CreateMalfunctionType = (newMalfunctionType) => async (dispatch) => {

  try {

    const { data } = await api.createMalfunctionType(newMalfunctionType)

    dispatch({ type: CREATE_MALFUNCTION_TYPE, payload: data })
    
  } catch (error) {

    console.log(error)
    
  }
}

export const FetchAllMalfunctionTypes = () => async (dispatch) => {
  try {

    const { data } = await api.fetchAllMalfunctionTypes()

    dispatch({ type: FETCH_MALFUNCTION_TYPES, payload: data })
    
  } catch (error) {

    console.log(error)
    
  }
}

export const UpdateMalfunctionType = (id, updatedMalfunctionType) => async (dispatch) => {
  try {

    const { data } = await api.updateMalfunctionType(id, updatedMalfunctionType)

    dispatch({ type: UPDATE_MALFUNCTION_TYPE, payload: data })
    
  } catch (error) {

    console.log(error)
    
  }
}

export const DeleteMalfunctionType = (id) => async (dispatch) => {
  try {

    await api.deleteMalfunctionType(id)

    dispatch({ type: DELETE_MALFUNCTION_TYPE, payload: id})
    
  } catch (error) {

    console.log(error)
    
  }
}