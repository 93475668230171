
import { CREATE_NEW_MOKED_SCANS_SCHEMA, FETCH_ALL_MOKED_SCANS_SCHEMAS, UPDATE_MOKED_SCANS_SCHEMA, DELETE_MOKED_SCANS_SCHEMA } from "../constants/actionTypes"

import * as api from "../api"
        
export const CreateMokedScansSchema = (newMokedScansSchema) => async (dispatch) => {
        
    try {
        
        const { data } = await api.createMokedScansSchema(newMokedScansSchema)
        
        dispatch({ type: CREATE_NEW_MOKED_SCANS_SCHEMA, payload: data })
            
    } catch (error) {
        
        console.log(error)
            
    }
}
        
export const FetchAllMokedScansSchemas = () => async (dispatch) => {

    try {
        
        const { data } = await api.fetchAllMokedScansSchemas()
        
        dispatch({ type: FETCH_ALL_MOKED_SCANS_SCHEMAS, payload: data })
            
    } catch (error) {
        
        console.log(error)
            
    }
}
        
export const UpdateMokedScansSchema = (id, updatedMokedScansSchema) => async (dispatch) => {

    try {
        
        const { data } = await api.updateMokedScansSchema(id, updatedMokedScansSchema)
        
        dispatch({ type: UPDATE_MOKED_SCANS_SCHEMA, payload: data })
            
    } catch (error) {
        
        console.log(error)
            
    }

}
        
export const DeleteMokedScansSchema = (id) => async (dispatch) => {

    try {
        
        await api.deleteMokedScansSchema(id)
        
        dispatch({ type: DELETE_MOKED_SCANS_SCHEMA, payload: id})
            
    } catch (error) {
        
        console.log(error)
            
    }

}
        