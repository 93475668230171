import {
  CREATE_MALFUNCTION_ARCHIVE,
  DELETE_MALFUNCTION,
  FETCH_MALFUNCTION_ARCHIVE,
} from "../constants/actionTypes";

import * as api from "../api";

export const CreateMalfunctionArchive =
  (id, malfunction) => async (dispatch) => {
    try {
      const { data } = await api.createMalfunctionArchive(id, malfunction);

      dispatch({ type: CREATE_MALFUNCTION_ARCHIVE, payload: data });

      if (id) {
        dispatch({ type: DELETE_MALFUNCTION, payload: id });
      }
    } catch (error) {
      console.log(error);
    }
  };

export const FetchMalfunctionArchive =
  (userName, isMoked) => async (dispatch) => {
    try {
      const { data } = await api.fetchMalfunctionArchive(userName, isMoked);

      dispatch({ type: FETCH_MALFUNCTION_ARCHIVE, payload: data });
    } catch (error) {
      console.log(error);
    }
  };
