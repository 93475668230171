import { CREATE_NEW_SHIFT_PAGE, FETCH_ALL_SHIFT_PAGE, UPDATE_SHIFT_PAGE, DELETE_SHIFT_PAGE } from "../constants/actionTypes"


const initialState = []

const ShiftPageReducer = ( shiftPage = initialState, action ) => {

    switch (action.type) {
        case CREATE_NEW_SHIFT_PAGE:
            return [...shiftPage, action.payload]

        case FETCH_ALL_SHIFT_PAGE:
            return action.payload
        
        case UPDATE_SHIFT_PAGE:

            return shiftPage.map((shiftInfo) => shiftInfo._id === action.payload._id ? action.payload : shiftInfo)

        case DELETE_SHIFT_PAGE:

            return shiftPage.filter((shiftInfo) => shiftInfo._id !== action.payload)
    
        default:
            return shiftPage
    }

}

export default ShiftPageReducer