import "./mokedScansSchema.css"

import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";

import { CreateMokedScansSchema, UpdateMokedScansSchema, DeleteMokedScansSchema } from "../../../actions/mokedScansSchema"

import SiteNameList from "./SiteNameList/SiteNameList";
import SiteDataList from "./SiteNameList/SiteDataList";

const MokedScansSchema = ({ chosenCURDType, setMokedScansSchemaUpdater, mokedScansSchemaData, sitesInformationNameAndType }) => {

    const [ mokedScansSchemaValue, setMokedScansSchemaValue ] = useState("")

return(
    <>
    { chosenCURDType && <div className="mokedWorker">
      <div className="mokedWorkerWrapperUserClient">
        <div className="mokedWorkerRight">
          <h1 className="mokedWorkerTitle">{chosenCURDType}</h1>
        </div>
          <div className="mokedWorkerLeft">
            {chosenCURDType === "רישום" ?
            <CreateClientUsersCRUD setMokedScansSchemaUpdater={setMokedScansSchemaUpdater} sitesInformationNameAndType={sitesInformationNameAndType} />
            : chosenCURDType === "עריכה" ?
            <EditClientUsersCRUD sitesInformationNameAndType={sitesInformationNameAndType} mokedScansSchemaData={mokedScansSchemaData} setMokedScansSchemaUpdater={setMokedScansSchemaUpdater}
            mokedScansSchemaValue={mokedScansSchemaValue} setMokedScansSchemaValue={setMokedScansSchemaValue} />
             :
             <DeleteClientUsersCRUD setMokedScansSchemaUpdater={setMokedScansSchemaUpdater} mokedScansSchemaData={mokedScansSchemaData}
             mokedScansSchemaValue={mokedScansSchemaValue} setMokedScansSchemaValue={setMokedScansSchemaValue}/>
            }
          </div>
        </div>
      </div>}
      </>
    )
}

export default MokedScansSchema;


const CreateClientUsersCRUD = ({ setMokedScansSchemaUpdater, sitesInformationNameAndType }) => {

    const dispatch = useDispatch();
    
    const [mokedScansSchema, setMokedScansSchema] = useState({
        mokedType: '',        
        timeOfDay: '',
        sitesToScan: [],
        amountOfScans: 0,
    });
    
    const [ siteNameValue, setSiteNameValue ] = useState({});
    
    const AddPermission = (e) => {
      e.preventDefault();

      if(siteNameValue){

        const value = sitesInformationNameAndType.find(site => site.siteName === siteNameValue)

        setMokedScansSchema(prev => ({
            ...prev,
            sitesToScan:[...prev.sitesToScan, value]
          }))
      }
    
      setSiteNameValue("")
    }
    
    
    const handleSubmit = (e) => {
      e.preventDefault()
    
      dispatch(CreateMokedScansSchema(mokedScansSchema))

      Clear()

    }

    const addAllSites = (e) => {
        e.preventDefault()

        setMokedScansSchema(prev => ({
            ...prev,
            sitesToScan:[...sitesInformationNameAndType]
          }))
    }

    const removeAllSites = (e) => {
        e.preventDefault()

        setMokedScansSchema(prev => ({
            ...prev,
            sitesToScan:[]
          }))
    }

    const DeletePermit = (e) => {
        e.preventDefault()

        const permitName = e.target.getAttribute("name")
        console.log(permitName)


        setMokedScansSchema(prev => ({
            ...prev,
            sitesToScan:[...prev.sitesToScan.filter(permit => permit.siteName !== permitName)]
          }))

    }

    const Clear = () => {

        setMokedScansSchema({
            mokedType: '',        
            timeOfDay: '',
            sitesToScan: [],
            amountOfScans: 0,
        })

        setMokedScansSchemaUpdater(prev => !prev)

    }
    
    return(
            <div className="mokedScansSchemaFormContainer">
                    <form className="mokedWorkerForm" onSubmit= {handleSubmit}>
                    <div className="clientUserFormContainerTop">
                        <input type="text" className="mokedWorkerInput" value={mokedScansSchema.mokedType} placeholder="מוקד גדול/ מוקד קטן" onChange={(e) => setMokedScansSchema({ ...mokedScansSchema, mokedType: e.target.value })}/>
                        <input type="text" className="mokedWorkerInput" value={mokedScansSchema.timeOfDay} placeholder="סוג סריקה (בוקר, צהריים, לילה, לילה רק מונחה)" onChange={(e) => setMokedScansSchema({ ...mokedScansSchema, timeOfDay: e.target.value })}/>
                        <input type="number" className="mokedWorkerInput" value={mokedScansSchema.amountOfScans} placeholder="מספר הסריקות הנדרש" onChange={(e) => setMokedScansSchema({ ...mokedScansSchema, amountOfScans: e.target.value })}/>
                        <p className="mokedScansSitesIncludedSentence" >אלו אתרים כלולים בסוג הסריקה?</p>
                        <div>
                            <button className="mokedScansButtonsAdd" onClick={addAllSites}>כל האתרים</button>
                            <button className="mokedScansButtonsSubtract" onClick={removeAllSites}>נקה רשימה</button>
                        </div>
                        <SiteDataList sitesInformationNameAndType={sitesInformationNameAndType} siteNameValue={siteNameValue} setSiteNameValue={setSiteNameValue}/>
                    </div>
                    <div className="clientUserFormContainerPermission mokedScansSiteListContainer">
                        <ul className="clientUserFormPermissionList">
                        {mokedScansSchema.sitesToScan && mokedScansSchema.sitesToScan.map((permit, index) => {
                            return(
                            <li key={index} className="permitItem" name={permit.siteName} onClick={DeletePermit}>{permit.siteName}</li>
                            )
                        })}
                        </ul>
                    </div>
                    <div className="buttonWrapperUserClient">
                        <button onClick={AddPermission} className="BottomFormMokedWorkersButton enterCRUDButton">הוספת הרשאה</button>
                        <input type="submit" className="BottomFormMokedWorkersButton enterCRUDButton" value="רשום מודל סריקות" />
                    </div>
                </form>
            </div>
    )
    
    }

const EditClientUsersCRUD = ({ sitesInformationNameAndType, mokedScansSchemaData, setMokedScansSchemaUpdater, mokedScansSchemaValue, setMokedScansSchemaValue, sitesInformationName }) => {

    const dispatch = useDispatch();

    let mokedSchema = mokedScansSchemaData.find(user => user.timeOfDay === mokedScansSchemaValue)
    
    const [mokedScansSchema, setMokedScansSchema] = useState({
        mokedType: '',        
        timeOfDay: '',
        sitesToScan: [],
        amountOfScans: 0,
    });

    useEffect(() => {

        if(mokedSchema){
        setMokedScansSchema({
            mokedType: mokedSchema.mokedType,
            timeOfDay: mokedSchema.timeOfDay,
            sitesToScan:[...mokedSchema.sitesToScan],
            amountOfScans: mokedSchema.amountOfScans
          });

        }
    }, [mokedSchema])
    
    const [ siteNameValue, setSiteNameValue ] = useState("");
    
    const AddPermission = (e) => {
      e.preventDefault();
    
      if(siteNameValue){

        const value = sitesInformationNameAndType.find(site => site.siteName === siteNameValue)

        setMokedScansSchema(prev => ({
            ...prev,
            sitesToScan:[...prev.sitesToScan, value]
          }))
      }
    
      setSiteNameValue("")
    }
    
    
    const handleSubmit = (e) => {
      e.preventDefault()
    
      dispatch(UpdateMokedScansSchema( mokedSchema._id, mokedScansSchema))

      Clear()

    }

    const DeletePermit = (e) => {
        e.preventDefault()

        const permitName = e.target.getAttribute("name")

        setMokedScansSchema(prev => ({
            ...prev,
            sitesToScan:[...prev.sitesToScan.filter(permit => permit.siteName !== permitName)]
          }))

    }

    const addAllSites = (e) => {
        e.preventDefault()

        setMokedScansSchema(prev => ({
            ...prev,
            sitesToScan:[...sitesInformationNameAndType]
          }))
    }

    const removeAllSites = (e) => {
        e.preventDefault()

        setMokedScansSchema(prev => ({
            ...prev,
            sitesToScan:[]
          }))
    }

    const Clear = () => {

        setMokedScansSchema({
            mokedType: '',        
            timeOfDay: '',
            sitesToScan: [],
            amountOfScans: 0,
        })

        setMokedScansSchemaValue("")
        setMokedScansSchemaUpdater(prev => !prev)

    }
    
    return(
            <div className="mokedScansSchemaFormContainer">
                <div className="EditMokedWorkerCRUDTop">
                    <SiteNameList mokedScansSchemaData={mokedScansSchemaData} mokedScansSchemaValue={mokedScansSchemaValue} setMokedScansSchemaValue={setMokedScansSchemaValue} />
                </div>
                    <form className="mokedWorkerForm" onSubmit= {handleSubmit}>
                    <div className="clientUserFormContainerTop">
                        <input type="text" className="mokedWorkerInput" value={mokedScansSchema.mokedType} placeholder="מוקד גדול/ מוקד קטן" onChange={(e) => setMokedScansSchema({ ...mokedScansSchema, mokedType: e.target.value })}/>
                        <input type="text" className="mokedWorkerInput" value={mokedScansSchema.timeOfDay} placeholder="סוג סריקה (בוקר, צהריים, לילה, לילה רק מונחה)" onChange={(e) => setMokedScansSchema({ ...mokedScansSchema, timeOfDay: e.target.value })}/>
                        <input type="number" className="mokedWorkerInput" value={mokedScansSchema.amountOfScans} placeholder="מספר הסריקות הנדרש" onChange={(e) => setMokedScansSchema({ ...mokedScansSchema, amountOfScans: e.target.value })}/>
                        <p className="mokedScansSitesIncludedSentence" >אלו אתרים כלולים בסוג הסריקה?</p>
                        <div>
                            <button className="mokedScansButtonsAdd" onClick={addAllSites}>כל האתרים</button>
                            <button className="mokedScansButtonsSubtract" onClick={removeAllSites}>נקה רשימה</button>
                        </div>
                        <SiteDataList sitesInformationNameAndType={sitesInformationNameAndType} siteNameValue={siteNameValue} setSiteNameValue={setSiteNameValue}/>
                    </div>
                    <div className="clientUserFormContainerPermission mokedScansSiteListContainer">
                        <ul className="clientUserFormPermissionList">
                        {mokedScansSchema.sitesToScan && mokedScansSchema.sitesToScan.map((permit, index) => {
                            return(
                            <li key={index} className="permitItem" name={permit.siteName} onClick={DeletePermit}>{permit.siteName}</li>
                            )
                        })}
                        </ul>
                    </div>
                    <div className="buttonWrapperUserClient">
                        <button onClick={AddPermission} className="BottomFormMokedWorkersButton enterCRUDButton">הוספת הרשאה</button>
                        <input type="submit" className="BottomFormMokedWorkersButton editCRUDButton" value="ערוך מודל" />
                    </div>
                </form>
            </div>
    )
}

const DeleteClientUsersCRUD = ({ mokedScansSchemaData, setMokedScansSchemaUpdater, mokedScansSchemaValue, setMokedScansSchemaValue }) => {

    const dispatch = useDispatch();

    let mokedSchema = mokedScansSchemaData.find(user => user.timeOfDay === mokedScansSchemaValue)
    
    const handleSubmit = (e) => {
      e.preventDefault()
    
      dispatch(DeleteMokedScansSchema( mokedSchema._id))

      Clear()

    }

    const Clear = () => {

        setMokedScansSchemaValue("")
        setMokedScansSchemaUpdater(prev => !prev)
    }
    
    return(
            <div className="mokedScansSchemaFormContainer">
                <div className="EditMokedWorkerCRUDTop">
                <SiteNameList mokedScansSchemaData={mokedScansSchemaData} mokedScansSchemaValue={mokedScansSchemaValue} setMokedScansSchemaValue={setMokedScansSchemaValue} />
                </div>
                    <form className="mokedWorkerForm" onSubmit= {handleSubmit}>
                    <div className="buttonWrapperUserClient">
                        <input type="submit" className="BottomFormMokedWorkersButton deleteCRUDButton" value="מחק מודל" />
                    </div>
                </form>
            </div>
    )
}