
import { CREATE_NEW_SCOUT, FETCH_ALL_SCOUTS, UPDATE_SCOUT, DELETE_SCOUT } from "../constants/actionTypes"

const ScoutReducer = ( Scout = [], action ) => {

    switch (action.type) {

        case CREATE_NEW_SCOUT:
            return [...Scout, action.payload]
        
        case FETCH_ALL_SCOUTS:
            return action.payload
                
        case UPDATE_SCOUT:
            return Scout.map((e) => e._id === action.payload._id ? action.payload : e)
        
        case DELETE_SCOUT:
            return Scout.filter((e) => e._id !== action.payload)
            
        default:
            return Scout

    }
}
        
export default ScoutReducer
        