
import { CREATE_NEW_SHIFTPAGETYPE, FETCH_ALL_SHIFTPAGETYPES, UPDATE_SHIFTPAGETYPE, DELETE_SHIFTPAGETYPE } from "../constants/actionTypes"

import * as api from "../api"
        
export const CreateShiftPageType = (newShiftPageType) => async (dispatch) => {
        
    try {
        
        const { data } = await api.createShiftPageType(newShiftPageType)
        
        dispatch({ type: CREATE_NEW_SHIFTPAGETYPE, payload: data })
            
    } catch (error) {
        
        console.log(error)
            
    }
}
        
export const FetchAllShiftPageTypes = () => async (dispatch) => {

    try {
        
        const { data } = await api.fetchAllShiftPageTypes()
        
        dispatch({ type: FETCH_ALL_SHIFTPAGETYPES, payload: data })
            
    } catch (error) {
        
        console.log(error)
            
    }
}
        
export const UpdateShiftPageType = (id, updatedshiftPageType) => async (dispatch) => {

    try {
        
        const { data } = await api.updateShiftPageType(id, updatedshiftPageType)
        
        dispatch({ type: UPDATE_SHIFTPAGETYPE, payload: data })
            
    } catch (error) {
        
        console.log(error)
            
    }

}
        
export const DeleteShiftPageType = (id) => async (dispatch) => {

    try {
        
        await api.deleteShiftPageType(id)
        
        dispatch({ type: DELETE_SHIFTPAGETYPE, payload: id})
            
    } catch (error) {
        
        console.log(error)
            
    }

}
        