import { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { CreateMokedScans } from '../../../actions/mokedScans'

export const CreateNewDay = () => {

    const dispatch = useDispatch()

    const mokedScansSchemaData = useSelector((state) => state.mokedScansSchema)

    let now = new Date()
    
    const options = {
        weekday: "short",
        year: "numeric",
        month: "short",
        day: "2-digit",
        timeZone: 'Asia/Jerusalem',
        hour12: false,
        hour: '2-digit',
        minute:'2-digit',
        second: '2-digit' 
    }

    const createNewDay = () => {

        mokedScansSchemaData.forEach(schema => {

            const sitesWithScan = schema.sitesToScan.map(site => {
                return {
                    siteName: site.siteName,
                    mokedType: site.mokedType,
                    timeOfScan: []
                }
            })

            const mokedScan = {
                mokedSize: schema.mokedType,   
                timeOfDay: schema.timeOfDay,
                currentDate: now.toLocaleString('en-US', options).replace(/,/g,"").slice(0, 16),
                sitesToScan: sitesWithScan,
                amountOfScans: schema.amountOfScans,
            }

            dispatch(CreateMokedScans(mokedScan))

        });

    }

    return(
        <>
            <p className="mokedScansChooseDay">ליום הקודם</p>
            <p className="mokedScansChooseDay" onClick={createNewDay}>ליום הבא</p>
        </>
    )

}