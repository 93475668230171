import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { CreateScout } from "../../actions/Scout";
import JumpScout from "./JumpScout/JumpScout";
import ScoutLevelB from "./ScoutLevelB/ScoutLevelB";
import "./Scouts.css";
import WeeklyScout from "./WeeklyScout/WeeklyScout";

function Scouts(props) {
  const { mokedWorkersName, sitesInformation } = props;
  const [scoutType, setScoutType] = useState("");

  const dispatch = useDispatch();

  const handleScoutSubmit = (data) => {
    dispatch(CreateScout(data));
  };

  const ScourTypeChoose = () => {
    return (
      <div className="ScoutContainer">
        <button
          className="ScoutChooseTypeButton"
          onClick={() => setScoutType("WeeklyScout")}
        >
          סיור שבועי
        </button>
        <button
          className="ScoutChooseTypeButton"
          onClick={() => setScoutType("JumpScout")}
        >
          סיור הקפצה
        </button>
        <button
          className="ScoutChooseTypeButton"
          onClick={() => setScoutType("ScoutLevelB")}
        >
          סיור מתקן רמה ב׳
        </button>
      </div>
    );
  };

  const RenderScoutType = () => {
    const propsParams = {
      setScoutType,
      handleScoutSubmit,
      sitesInformation,
      mokedWorkersName,
    };
    switch (scoutType) {
      case "WeeklyScout":
        return <WeeklyScout {...propsParams} />;
      case "JumpScout":
        return <JumpScout {...propsParams} />;
      case "ScoutLevelB":
        return <ScoutLevelB {...propsParams} />;
      default:
        return <div>ScoutTypeChoose</div>;
    }
  };
  return !scoutType ? ScourTypeChoose() : RenderScoutType();
}

export default Scouts;
