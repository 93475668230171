const Validation = ( chosenTransforms, guardsHours ) => {

    let errors = {}
  
    if(chosenTransforms.guards[0].guards_name !== "אין שומרים"){

      if(!guardsHours){
        errors.guards = "הכנס שעות שומרים"
      }

    }

    return errors;
  }
  
  export default Validation
  