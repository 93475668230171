import useAutocomplete from "@material-ui/lab/useAutocomplete";

export default function CreatorName( { userNameValue, setUserNameValue, fetchUserClient } ) {
 
    const CreatorsNameList = fetchUserClient.map(worker => {return worker.userName})

    const { getRootProps, getInputProps, getListboxProps, getOptionProps, groupedOptions} = useAutocomplete({
        value: userNameValue || null,
        onChange:(event, newValue) => {setUserNameValue(newValue)},
        openOnFocus: true,
        matchFrom: "start",
        options: CreatorsNameList,
        getOptionLabel: (option) => option
        
      });      

      return (
        <div className="editMokedWorkerInputEmployList">
          <div  {...getRootProps()}>
            <div className="siteNameInput">
                <input 
                className="entryFormInput entryFormInputEmployList editMokedWorkerInputEmployList" 
                placeholder="שם הלקוח שברצונך לערוך"
                {...getInputProps()} />
            
            {groupedOptions.length > 0 ? (
                <ul className="creatorNameList" {...getListboxProps()}>
                {groupedOptions.map((option, index) => (
                    <li className="siteNameListItem" {...getOptionProps({ option, index })}>{option}</li>
                ))}
                </ul>
            ) : null}
            </div>
          </div>
        </div>
      );
    }