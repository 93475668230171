import React from "react";

const OtherSiteInfoEmails = (props) => {
  const { emailList } = props;

  return (
    <div className="malfunctionContainer farmMalfunctionLeft">
      <div className="malfunctionTitleContainer">
        <h4 className="malfunctionTitle">רשימת האימיילים הרלוונטיים לאתר</h4>
        <button
          className="copyBtn"
          onClick={() => {
            navigator.clipboard.writeText(emailList);
          }}
        >
          העתק
        </button>
      </div>
      <ul className="siteInformationList">
        {emailList?.map((emailListItem, index) => {
          return (
            <li className="urgentItem" key={index}>
              {emailListItem}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default OtherSiteInfoEmails;
