import React, { useState } from "react";
import MultiChoiseInput from "../MultiChoiseInput/MultiChoiseInput";
import { useDispatch } from "react-redux";
import { DeleteScout, UpdateScout } from "../../actions/Scout";

function CloseScoutEdit(props) {
  const {
    scoutType,
    scoutData,
    setOpenScoutEdit,
    sitesInformation,
    mokedWorkersName,
    setScoutsUpdater,
  } = props;
  const [scout, setScout] = useState(scoutData);
  const [siteNameValue, setSiteNameValue] = useState(scoutData.siteName);
  const [mokedWorkerValue, setMokedWorkerValue] = useState(
    scoutData.mokedWorker
  );

  const dispatch = useDispatch();

  const params = {
    siteName: {
      options: sitesInformation,
      chooseBy: "siteName",
      placeholder: "הכנס את שם האתר המבוקש",
      value: siteNameValue,
      setValue: setSiteNameValue,
    },
    mokedWorker: {
      options: mokedWorkersName,
      chooseBy: "userName",
      placeholder: "הכנס את שמך",
      value: mokedWorkerValue,
      setValue: setMokedWorkerValue,
    },
  };

  const dateChangeHandler = (e) => {
    const date = e.target.value;
    const datePattern = /^[A-Za-z]{3} \d{2} \d{2}$/;

    if (datePattern.test(date)) {
      setScout({ ...scout, date });
    } else {
      alert("אנא הכנס תאריך בפורמט: חודש יום שנה");
    }
  };

  const hourChangeHandler = (e) => {
    const time = e.target.value;

    const timePattern = /^([01]\d|2[0-3]):[0-5]\d$/;

    if (timePattern.test(time)) {
      setScout({ ...scout, hour: time });
    } else {
      alert("אנא הכנס שעה בפורמט: HH:MM");
    }
  };

  const handleEdit = () => {
    const updatedScout = {
      ...scout,
      siteName: siteNameValue,
      mokedWorker: mokedWorkerValue,
    };
    dispatch(UpdateScout(scout._id, updatedScout));
    setScoutsUpdater((prev) => !prev);
    setOpenScoutEdit(null);
  };

  const handleDelete = () => {
    dispatch(DeleteScout(scout._id));
    setScoutsUpdater((prev) => !prev);
    setOpenScoutEdit(null);
  };

  return (
    <div className="closeScoutEditContainer">
      <div className="closeScoutEditInner">
        <button
          className="closeScoutEditCloseButton"
          onClick={() => setOpenScoutEdit(null)}
        >
          X
        </button>
        <p>לאחר עריכה או מחיקה יש לרענן את העמוד על מנת לצפות בשינויים</p>
        <div className="closeScoutEditFields">
          <label className="ScoutTypeFormLabel">
            שם האתר:
            <MultiChoiseInput {...params.siteName} />
          </label>
          <label className="ScoutTypeFormLabel">
            שם המוקדן:
            <MultiChoiseInput {...params.mokedWorker} />
          </label>
          <label className="ScoutTypeFormLabel">
            תאריך:
            <input
              type="text"
              placeholder="תאריך"
              value={scout.date}
              onChange={dateChangeHandler}
              className="ScoutFormInput"
            />
          </label>
          <label className="ScoutTypeFormLabel">
            שעה:
            <input
              type="text"
              placeholder="שעה"
              value={scout.hour}
              onChange={hourChangeHandler}
              className="ScoutFormInput"
            />
          </label>
          <label className="ScoutTypeFormLabel">
            שם הסייר:
            <input
              type="text"
              placeholder="שם הסייר"
              value={scout.scouter}
              onChange={(e) => setScout({ ...scout, scouter: e.target.value })}
              className="ScoutFormInput"
            />
          </label>
          {scoutType === "weeklyScout" && (
            <label className="ScoutTypeFormLabel">
              מקטעי גדר:
              <input
                type="text"
                placeholder="מקטעי גדר"
                value={scout.fenceParts}
                onChange={(e) =>
                  setScout({ ...scout, fenceParts: e.target.value })
                }
                className="ScoutFormInput"
              />
            </label>
          )}
          {scoutType === "jumpScout" && (
            <label className="ScoutTypeFormLabel">
              סיבת הקפצה:
              <input
                type="text"
                placeholder="סיבת הקפצה"
                value={scout.jumpReason}
                onChange={(e) =>
                  setScout({ ...scout, jumpReason: e.target.value })
                }
                className="ScoutFormInput"
              />
            </label>
          )}
          <label className="ScoutTypeFormLabel">
            הערות:
            <input
              type="text"
              placeholder="הערות"
              value={scout.sideNote}
              onChange={(e) => setScout({ ...scout, sideNote: e.target.value })}
              className="ScoutFormInput"
            />
          </label>
        </div>
        <div className="closeScoutEditButtonContainer">
          <button className="closeScoutEditButton" onClick={handleEdit}>
            ערוך
          </button>
          <button className="closeScoutEditButton" onClick={handleDelete}>
            מחק
          </button>
        </div>
      </div>
    </div>
  );
}

export default CloseScoutEdit;
