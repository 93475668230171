import "./clientEntries.css";

import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FetchClientEntry } from "../../actions/entries";

import ClientEntriesRight from "../../components/ClientEntriesRight/ClientEntriesRight";
import ClientEntriesLeft from "../../components/ClientEntriesLeft/ClientEntriesLeft";

export default function ClientEntries() {
  const dispatch = useDispatch();

  const user = JSON.parse(localStorage.getItem("profile")) || "";

  let userName = "";
  let isMoked = false;

  if (user) {
    userName = user.result.userName;
    isMoked = user.result?.isMoked;
  }

  const [chosenSiteName, setChosenSiteName] = useState("");
  let date = new Date();
  date.setMonth(date.getMonth() - 1);
  const [startDate, setStartDate] = useState(date);
  const [endDate, setEndDate] = useState(new Date());
  const [errors, setErrors] = useState({ 1: 1 });

  useEffect(() => {
    dispatch(FetchClientEntry(userName, isMoked));
  }, [dispatch, userName]);

  const clientEntries = useSelector((state) => state.entries);

  const sortedClientEntries = clientEntries.reduce((r, a) => {
    r[a.siteName] = r[a.siteName] || [];
    r[a.siteName].push(a);
    return r;
  }, Object.create(null));

  const fetchClientEntries = Object.entries(sortedClientEntries);

  const siteNameArray = fetchClientEntries.map((entry) => {
    return entry[0];
  });

  const siteInformationArray = fetchClientEntries
    .map((entry) => {
      return entry[1];
    })
    .filter((chosenEntry) => chosenEntry[0].siteName === chosenSiteName)[0];

  let siteInformationArrayDateSorted = null;

  if (siteInformationArray) {
    let EndDatePlus = new Date(endDate);

    EndDatePlus.setDate(EndDatePlus.getDate() + 0.5);

    let startDateSorted = siteInformationArray.filter(
      (entry) => new Date(entry.created_At) - startDate >= 0
    );

    siteInformationArrayDateSorted = startDateSorted.filter(
      (entry) => EndDatePlus - new Date(entry.created_At) >= 0
    );
  }

  return (
    <>
      <div className="ClientEntriesContainer">
        <ClientEntriesRight
          siteNameArray={siteNameArray}
          siteInformationArray={siteInformationArray}
          chosenSiteName={chosenSiteName}
          setChosenSiteName={setChosenSiteName}
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
          errors={errors}
          setErrors={setErrors}
          siteInformationArrayDateSorted={siteInformationArrayDateSorted}
        />

        <ClientEntriesLeft
          siteInformationArrayDateSorted={siteInformationArrayDateSorted}
          chosenSiteName={chosenSiteName}
          startDate={startDate}
          endDate={endDate}
          userName={userName}
        />
      </div>
    </>
  );
}
