import "./endTransforms.css"

import { useState, useEffect } from "react"
import { useDispatch } from "react-redux"

import { Close } from "@material-ui/icons"

import { CreateFinishedTransforms } from "../../../../actions/finishedTransforms"
import GuardsWorkTime from "./GuardsWorkTime/GuardsWorkTime"
import AlarmMessage from "./AlarmMessage/AlarmMessage"
import Validation from "./Validation"

export default function EndTransforms({ setIsOpenEndTransform, chosenTransforms, transformId, setTransformId, setGetTransformsUpdater, isOpenEndTransform, isAlarmOpen, setIsAlarmOpen, setTypeAlarmOpen, typeAlarmOpen }) {

    const dispatch = useDispatch()

    const [ sideNote, setSideNote ] = useState()
    const [startDate, setStartDate] = useState()
    const [ endDate, setEndDate ] = useState()
    const [ guardsHours, setGuardsHours ] = useState()
    const [ errors, setErrors ] = useState({ 1:1 })
    const [ fill, setFill ] = useState(false)

    useEffect(() => {

        if(startDate && endDate){
        setGuardsHours(startDate.toString().slice(16,21) + "-" + endDate.toString().slice(16,21))
        }
        chosenTransforms.guards.map((guards) => {
            return(
            guards.guards_Hours = guardsHours
            )
            })
        chosenTransforms.sideNote = sideNote

    }, [startDate, endDate, chosenTransforms.guards, guardsHours, chosenTransforms, sideNote, transformId])

    const handleFormSubmit = (e) => {

        e.preventDefault()

        setFill(true)

        if(Object.keys(errors).length === 0){

            setTypeAlarmOpen("sentForm")

            setIsAlarmOpen(true)

            setIsOpenEndTransform(false)

            dispatch(CreateFinishedTransforms(transformId, chosenTransforms))

            setFill(false)

            setErrors("")

        }

    }

    useEffect(() => {

        setErrors(Validation(chosenTransforms, guardsHours))

    }, [chosenTransforms, guardsHours])

    return (
        <>
        {isOpenEndTransform ? <div className="endTransforms fadeInDown"> 
            <div className="endTransformsInner fadeIn">
                <button className="endTransformsInnerExit" onClick={(e) => setIsOpenEndTransform(false)}><Close/></button>
                <div className="endTransformsContainer">
                    <div className="endTransformsTop">
                        <h2 className="endTransformsTitle">סיום שינוע - {chosenTransforms.driver}</h2>
                        <div className="endTransformsTopContainer">
                            <div className="endTransformsTopRight">
                                <h3 className="transformsDataTitle">נתוני שינוע</h3>
                                <ul className="activeEntryInfoList transformsInfoList">
                                    <li className="activeEntryInfoItem transformsInfoItem">שם הנהג: {chosenTransforms.driver}</li>
                                    {Object.keys(chosenTransforms.guards).length > 0 &&
                                    chosenTransforms.guards.map((guards, index) => {
                                    return(
                                        <li className="activeEntryInfoItem transformsInfoItem" key={index}>שם המאבטח: {guards.guards_name}</li>
                                    )})}
                                    <li className="activeEntryInfoItem transformsInfoItem">שעת תחילת שינוע: {chosenTransforms.created_At.slice(16,21)}</li>
                                    <li className="activeEntryInfoItem transformsInfoItem">שעת סיום שינוע: {chosenTransforms.transformsEndHour.slice(16,21)}</li>
                                    <li className="activeEntryInfoItem transformsInfoItem">סה"כ שורות שנכתבו: {Object.keys(chosenTransforms.data).length}</li>
                                </ul>
                            </div>
                            <div className="endTransformsTopLeft">
                            {Object.keys(chosenTransforms.guards).length > 0 && chosenTransforms.guards[0].guards_name !== "אין שומרים" &&
                            <>
                                <div className="transformsGuardsHoursFill">
                                <h3 className="transformsDataTitle">הכנס שעות מאבטחים</h3>
                                {fill && errors.guards && <p className="emptyField guardsField">{errors.guards}</p> }
                                </div>
                                <ul className="activeEntryInfoList transformsInfoList guardsHoursList">
                                    {chosenTransforms.guards.map((guards, index) => {
                                    return(
                                        <li className="activeEntryInfoItem transformsInfoItem guardsHoursItem" key={index}><span className="guardsNameHourPicker">{guards.guards_name} </span>
                                        <GuardsWorkTime startDate={startDate} setStartDate={setStartDate} setGuardsHours={setGuardsHours}
                                        endDate={endDate} setEndDate={setEndDate} /></li>
                                    )})}
                                </ul>
                                </>}
                            </div>
                        </div>
                    </div>
                    <div className="endTransformsBottom">
                        <textarea 
                        className="endTransformsSideNote ShiftInformationInput" 
                        placeholder="האם היו אירועים חריגים במהלך השינוע?" cols="30" rows="10" 
                        value={sideNote} 
                        onChange={(e) => setSideNote(e.target.value)} />
                        <button className="endTransformsBtn" onClick={handleFormSubmit}>שלח טופס</button>
                    </div>
                </div>
            </div>
        </div> : isAlarmOpen ? 
        <AlarmMessage setIsAlarmOpen={setIsAlarmOpen} setGetTransformsUpdater={setGetTransformsUpdater} setTransformId={setTransformId}
        setTypeAlarmOpen={setTypeAlarmOpen} typeAlarmOpen={typeAlarmOpen} /> : null }
        </>
    )
}
