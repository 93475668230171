import { CREATE_NEW_MALFUNCTION, FETCH_ALL_MALFUNCTIONS, UPDATE_MALFUNCTION, DELETE_MALFUNCTION } from "../constants/actionTypes"

const malfunctionsReducer = ( malfunctions = [], action ) => {
    switch (action.type) {
        case CREATE_NEW_MALFUNCTION:
            return [...malfunctions, action.payload]

        case FETCH_ALL_MALFUNCTIONS:
            return action.payload
        
        case UPDATE_MALFUNCTION:
            return malfunctions.map((malfunction) => malfunction._id === action.payload._id ? action.payload : malfunction)

        case DELETE_MALFUNCTION:
            return malfunctions.filter((malfunction) => malfunction._id !== action.payload)
    
        default:
            return malfunctions
    }
}

export default malfunctionsReducer