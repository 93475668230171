import "./transformsFormsForm.css"

import { useState, useRef, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"

import { CreateTransforms, UpdateTransforms } from "../../../../actions/transforms"
import Validation from "./Validation"

import { Done, Close } from "@material-ui/icons"

import CreatorsName from "./CreatorsName/CreatorsName"
import GuardsName from "./GuardsName/GuardsName"

export default function TransformsFormsForm({ transformsFormIdToEdit, setTransformsFormIdToEdit, setTransformId, setIsAlarmOpen, setTypeAlarmOpen, mokedWorkersName }) {

    const dispatch = useDispatch()

    const [ isOpen, setIsOpen ] = useState(false)
    const [ isGourd, setIsGourd] = useState(true)
    const [ errors, setErrors ] = useState({1:1})
    const [ fill, setFill ] = useState(false)
    const [ guardsArray, setGuardsArray ] = useState([])
    
    const [ transformsFormsData, setTransformsFormsData ] = useState({
        mokdan: "",
        driver: "",
        guards: [{
            guards_name: "",
            guards_Hours: ""
        }],
        cameras:false,
        ituran:false,
        mears:false,
        sign:false,
        data: [],
        created_At: ""
    })

    const noGuards = [{
        guards_name: "אין שומרים",
        guards_Hours: 0
    }]

    const cameras = useRef()
    const ituran = useRef()
    const mears = useRef()
    const sign = useRef()

    const editTransformsForms = useSelector((state) => transformsFormIdToEdit && state.transforms.find((e) => e._id === transformsFormIdToEdit))

    useEffect(() => {

        if(transformsFormIdToEdit){

            setIsOpen(true)

            setTransformsFormsData(editTransformsForms)

            if(editTransformsForms.guards[0].guards_name === "אין שומרים"){
                setGuardsArray([])
            }else{
                setGuardsArray(editTransformsForms.guards)
            }

        }

    }, [editTransformsForms, transformsFormIdToEdit])

    useEffect(() => {
        setTransformsFormsData((prev) => ({
            ...prev,
            guards: guardsArray
        }))
    }, [guardsArray])

    
    const handleSubmit = (e) => {

        e.preventDefault()

        setFill(true)

        if(Object.keys(errors).length === 0){

            if(transformsFormIdToEdit){

                dispatch(UpdateTransforms(transformsFormIdToEdit, transformsFormsData))

                setTransformId(transformsFormIdToEdit)

                setTypeAlarmOpen("editAlarm")

                setIsAlarmOpen(true)

            }else{

                dispatch(CreateTransforms(transformsFormsData))

            }

            Clear()

        }
    }
    

    useEffect(() => {

        setErrors(Validation(transformsFormsData, cameras, ituran, mears, sign))

    }, [transformsFormsData, cameras, ituran, mears, sign])

    const CancelHandler = (e) => {
        e.preventDefault()

        Clear()
    }

    const Clear = () => {

        setTransformsFormsData((prev) => ({
            mokdan: "",
            driver: "",
            guards: [{
                guards_name: "",
                guards_Hours: ""
            }],
            cameras:false,
            ituran:false,
            mears:false,
            sign:false
        }))

        setIsOpen(false)

        setFill(false)

        setIsGourd(true)

        setGuardsArray([])

        setTransformsFormIdToEdit("")
    }

    return (
        <div className="TransformsFormsWrapper">
            {!isOpen ? <button className="newFormBtn" onClick={() => setIsOpen(true)}>צור טופס חדש</button> :
            <div className="transformsForm">
              <form onSubmit={handleSubmit}>
                <div className="transformsFormInputContainer">
                    <div className="transformsFormInputBox">
                        <label className="transformsFormLabel">מוקדן פותח</label>
                        <CreatorsName transformsFormsData={transformsFormsData} setTransformsFormsData={setTransformsFormsData} mokedWorkersName={mokedWorkersName} />
                    </div>
                    <div className="transformsFormInputBox">
                        <label className="transformsFormLabel">נהג השינוע</label>
                        <input type="text" placeholder="הכנס את שם נהג השינוע" value={transformsFormsData.driver} className="transformsFormInput" onChange={(e) => setTransformsFormsData((prev) => ({...prev, driver:e.target.value}))}/>
                    </div>
                </div>
                <label className="entryFormLabel approvedLabel">האם יש שומרים שמלווים את השינוע?
                            
                <Close className="approvedIcon isApprovedIcon"
                    onClick={() => {
                        return(
                            setIsGourd(false),
                            setTransformsFormsData((prev) => ({ ...prev, guards:noGuards}))
                            )
                            }}/>
                <Done className="approvedIcon notApprovedIcon" 
                onClick={() =>{ 
                    return(
                    setIsGourd(true),
                    setTransformsFormsData((prev) => ({ ...prev, guards:[...guardsArray]}))
                    )
                    }}/>

                </label>
                {isGourd && 
                 <GuardsName guardsArray={guardsArray} setGuardsArray={setGuardsArray}/>
                }
                <div className="transformsFormCheckContainer">
                    <div className="transformsFormCheckboxItem">
                    <input type="checkbox" className="transformsFormCheck" ref={cameras}  checked={transformsFormsData.cameras === true ? true : false} onChange={(e) => setTransformsFormsData((prev) => ({...prev, cameras: !transformsFormsData.cameras}))}/>
                    <label className="transformsFormCheckboxLabel"> בדיקת תקינות מצלמות</label>
                    </div>
                    <div className="transformsFormCheckboxItem">
                    <input type="checkbox" className="transformsFormCheck" ref={ituran} checked={transformsFormsData.ituran === true ? true : false} onChange={(e) => setTransformsFormsData((prev) => ({...prev, ituran: !transformsFormsData.ituran}))} />
                    <label className="transformsFormCheckboxLabel"> העלאת איתורן "פוינטר"</label>
                    </div>
                    <div className="transformsFormCheckboxItem">
                    <input type="checkbox" className="transformsFormCheck" ref={mears} checked={transformsFormsData.mears === true ? true : false} onChange={(e) => setTransformsFormsData((prev) => ({...prev, mears: !transformsFormsData.mears}))} />
                    <label className="transformsFormCheckboxLabel"> בדיקת קשר מירס</label>
                    </div>
                    <div className="transformsFormCheckboxItem">
                    <input type="checkbox" className="transformsFormCheck" ref={sign} checked={transformsFormsData.sign === true ? true : false} onChange={(e) => setTransformsFormsData((prev) => ({...prev, sign: !transformsFormsData.sign}))} />
                    <label className="transformsFormCheckboxLabel"> על שני המוקדנים לקרוא ולחתום על נספח "אירוע חירום"</label>
                    </div>
                    <div className="transformsFormError">
                       {fill && <p className="emptyField">{errors.mokdan || errors.driver || errors.checked || ""}</p>}
                    </div>
                    <div className="createFormBtn">
                    {transformsFormIdToEdit ? <button className="newFormBtn editFormBtn" type="submit" >ערוך טופס</button> :<button className="newFormBtn" type="submit" >צור טופס</button>}
                    <button className="cancelFormBtn" onClick={CancelHandler} >ביטול</button>
                    </div>
                </div>
                </form>
            </div>}
        </div>
    )
}
