
import { useState } from "react";
import { useDispatch } from "react-redux";
import { CreateImageList, DeleteImageList } from "../../../actions/ImageList";
import MokedWorkerName from "./MokedWorkerName/MokedWorkerName"

const ImageUploader = ({ chosenCURDType, imageList }) => {

const [ creatorNameValue, setCreatorNameValue ] = useState("")

const [workerData, setWorkerData] = useState({
  base64: '',
  imageName: '',
  companyName: '',
});

return(
  <>
    { chosenCURDType && <div className="mokedWorker">
      <div className="mokedWorkerWrapper">
        <div className="mokedWorkerRight">
          <h1 className="mokedWorkerTitle">{chosenCURDType}</h1>
        </div>
          <div className="mokedWorkerLeft">
            {chosenCURDType === "רישום" ?
            <CreateImageListCRUD workerData={workerData} setWorkerData={setWorkerData} />
            : chosenCURDType === "עריכה" ?
            <EditMokedWorkerCRUD />
             :
             <DeleteImageListCRUD imageList={imageList} creatorNameValue={creatorNameValue} setCreatorNameValue={setCreatorNameValue}
             workerData={workerData} setWorkerData={setWorkerData} />
            }
          </div>
        </div>
      </div>}
      </>
      )
}

export default ImageUploader;


const CreateImageListCRUD = ({ workerData, setWorkerData }) => {

  const dispatch = useDispatch();

  const [image, setImage] = useState()

  const UploadImage = async (e) => {
    e.preventDefault()

    try {
      
      let baseURL = "";
      let reader = new FileReader();
        reader.readAsDataURL(image);
        reader.onload = () => {
        baseURL = reader.result;
        
        setWorkerData(prev => ({
          ...prev,
          base64: baseURL.split(',')[1],
          imageName: image.name,
        }));
        }
    } catch (error) {
        console.log(error)
    }
  }

    
  const handleSubmit = (e) => {
    e.preventDefault()

    if(workerData.base64 && workerData.companyName) {

      dispatch(CreateImageList(workerData))
  
      Clear()
    }

  }
  
  const Clear = () => {
  
    setWorkerData({
        base64: '',
        imageName: '',
        companyName: ''
    })
  
  }

  return(
    <form className="mokedWorkerForm" onSubmit= {handleSubmit}>
      <input type="file" className="mokedWorkerInput" onChange={(e => setImage(e.target.files[0]))}/>
      <button onClick={(e) => UploadImage(e)} className="BottomFormMokedWorkersButton enterCRUDButton">העלה תמונה</button>
      {workerData.imageName && <p>{workerData.imageName}</p>}
      <input type="text" className="mokedWorkerInput" value={workerData.companyName} placeholder="שם הלקוח" onChange={(e) => setWorkerData(prev => ({ ...prev, companyName: e.target.value }))}/>
      <div className="BottomFormMokedWorkers">
        <div className="BottomFormMokedWorkers">
          <input type="submit" className="BottomFormMokedWorkersButton enterCRUDButton" value="הכנס תמונה" />
        </div>
      </div>
  </form>
  )
}

const EditMokedWorkerCRUD = () => {

  return(
    <>
      <div className="EditMokedWorkerCRUDTop">
          <h1>לא ניתן לערוך בחלק זה</h1>
      </div> 
   </>
  )
}

const DeleteImageListCRUD = ({ imageList, creatorNameValue, setCreatorNameValue}) => {

  const dispatch = useDispatch();

  const chosenMokedWorker = imageList.find(worker => worker.imageName === creatorNameValue)
    
  const handleSubmit = async (e) => {
    e.preventDefault()

    if(chosenMokedWorker) {
        dispatch( DeleteImageList( chosenMokedWorker._id ))
      
        Clear()

    }
  }
  
  const Clear = () => {
    
    setCreatorNameValue("")
  }

  return(
    <>
      <div className="EditMokedWorkerCRUDTop">
      <MokedWorkerName imageList={imageList} creatorNameValue={creatorNameValue} setCreatorNameValue={setCreatorNameValue} />
      </div>
      <form className="mokedWorkerForm" onSubmit= {handleSubmit}>
        <div className="BottomFormMokedWorkers">
          <div className="BottomFormMokedWorkers">
            <input type="submit" className="BottomFormMokedWorkersButton deleteCRUDButton" value="מחק תמונה" />
          </div>
        </div>
      </form>   
   </>
  )
}