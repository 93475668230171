import { CREATE_FINISHED_TRANSFORMS, DELETE_TRANSFORMS, FETCH_FINISHED_TRANSFORMS } from "../constants/actionTypes"

import * as api from "../api"

  export const CreateFinishedTransforms = (id, activeTransforms) => async (dispatch) => {
    try {
  
      const { data } = await api.createFinishedTransforms(id, activeTransforms)
  
      dispatch({ type: CREATE_FINISHED_TRANSFORMS, payload: data})

      dispatch({ type: DELETE_TRANSFORMS, payload: id})
      
    } catch (error) {
  
      console.log(error)
      
    }
  }

  export const FetchFinishedTransforms = () => async (dispatch) => {

    try {

      const { data } = await api.fetchFinishedTransforms()

      dispatch({ type: FETCH_FINISHED_TRANSFORMS, payload: data })
      
    } catch (error) {

      console.log(error)
      
    }
  }



