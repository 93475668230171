import { saveAs } from "file-saver";
import XLSX from "sheetjs-style";

export default function MalfunctionExcelExport(props) {
  const { allMalfunction, sortedMalfunctionGroup, fileName } = props;

  const filteredMalfunction = sortedMalfunctionGroup.map((site, index) => {
    const data = allMalfunction.filter(
      (sites) => sites.siteNameValue === site.siteName
    );

    const formattedData = data.map((malfunction, index) => {
      return {
        תאריך_סגירה: malfunction?.updated_At
          ? malfunction?.updated_At?.slice(16, 21) +
            " - " +
            malfunction?.updated_At?.slice(4, 10) +
            " " +
            malfunction?.updated_At?.slice(13, 15)
          : "",
        סיבת_סגירה: malfunction?.whyClosed,
        מוקדן_סוגר: malfunction?.closeWorkerName,
        מוקדן_פותח: malfunction?.workerName,
        סיווג_מנבט: malfunction?.classification,
        מספר_קריאה: malfunction?.callNumber,
        הערות: malfunction?.sideNote.join(" ,\n"),
        תיאור_תקלה: malfunction?.malfunctionProblem,
        אזור_מספר: malfunction?.malfunctionLocation,
        תקלה: malfunction?.malfunctionSort,
        תאריך_פתיחה:
          malfunction?.created_At.slice(16, 21) +
          " - " +
          malfunction?.created_At.slice(4, 10) +
          " " +
          malfunction?.created_At.slice(13, 15),
      };
    });

    return {
      siteName: site.siteName,
      formattedData,
    };
  });

  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const exportToCSV = async () => {
    try {
      const wb = XLSX.utils.book_new(); // Create a new workbook

      filteredMalfunction.forEach((site, index) => {
        const ws = XLSX.utils.json_to_sheet(site.formattedData); // Create a worksheet from the data array
        const sheetName = `${site.siteName}`; // Generate a name for the sheet
        ws["!cols"] = site.formattedData[0]
          ? Object.keys(site.formattedData[0]).map((key) => {
              const maxLength = site.formattedData.reduce(
                (max, item) => Math.max(max, (item[key] || "").length),
                0
              );
              return { wch: maxLength < 15 ? 15 : maxLength };
            })
          : [];

        // Set alignment for all cells
        Object.keys(ws).forEach((cell) => {
          if (cell[0] !== "!") {
            // Avoid special keys like '!cols', '!ref', etc.
            ws[cell].s = {
              alignment: { horizontal: "right" },
            };
          }
        });

        // Apply style to header cells if they exist
        const header_cells = [
          "A1",
          "B1",
          "C1",
          "D1",
          "E1",
          "F1",
          "G1",
          "H1",
          "I1",
          "J1",
          "K1",
        ];
        header_cells.forEach((cell) => {
          if (ws[cell]) {
            // Ensure the cell exists
            ws[cell].s = { fill: { fgColor: { rgb: "D3D3D3" } } };
          }
        });

        XLSX.utils.book_append_sheet(wb, ws, sheetName); // Append the worksheet to the workbook
      });

      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });
      saveAs(data, "דו״ח תקלות " + fileName + fileExtension);
    } catch (error) {
      console.error("Error exporting file:", error);
      alert(
        "אירעה שגיאה ביצוא הקובץ, אנא נסה שוב מאוחר יותר. אם הבעיה נמשכת, צור קשר עם מנהל המערכת."
      );
    }
  };

  return (
    <button className="malfunctionGroupExport" onClick={() => exportToCSV()}>
      Exl
    </button>
  );
}
