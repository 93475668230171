import "./activeTransforms.css"

import { MenuRounded } from "@material-ui/icons"

export default function ActiveTransforms({ form, setTransformId, setTransformsFormIdToEdit }) {

    return (
        
            <div className="activeTransformsList"> 
                {Object.keys(form).length > 0 &&
                <div className="activeTransformsItem">
                    <div className="activeEntryItemTop">
                    <p className="activeSiteName activeTransformsTitle" onClick={e => {setTransformsFormIdToEdit(form._id)}} >{form.driver}</p>
                    <MenuRounded className="burgerIcon" onClick={e => {setTransformId(form._id)}} />
                    </div>
                    <div className="activeEntryItemBottom">
                        <div className="activeEntryItemBottomLeft">
                            <p className="lastUpdated">{form.created_At.slice(16,21)}</p>
                        </div>
                    </div>
                </div>
                }
            </div>
        
    )
}
