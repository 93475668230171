import "./clientEntryInformation.css"

export default function ClientEntryInformation({ siteInformationArray }) {

    const activeEntries = siteInformationArray.filter((entry) => entry.updated_At === undefined)


    return (
        <div className="EntryInformation">
            <ul className="EntryInformationList">
                <li className="EntryInformationItem">
                    {Object.keys(activeEntries).length + " כניסות פעילות באתר "}
                </li>
                <li className="EntryInformationItem">
                    { "סך הכל " + Object.keys(siteInformationArray).length + " כניסות לאתר"}
                </li>
            </ul>
        </div>
    )
}
