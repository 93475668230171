import "./malfunctionDoneWindow.css"

import { useState, useEffect } from "react"

import { useDispatch } from "react-redux"

import SiteNameInput from "../../MalfunctionWindow/SiteNameInput/SiteNameInput"

import { CreateMalfunctionArchive } from "../../../../../actions/malfunctionArchive"

import { confirmAlert } from "react-confirm-alert"
import "react-confirm-alert/src/react-confirm-alert.css"

import { Check, Close } from "@material-ui/icons"
import { UpdateMalfunction } from "../../../../../actions/malfunction"


export default function MalfunctionDoneWindow({ setIsDoneWindow, mokedWorkersName, closeWorkerName, setCloseWorkerName, whyClosed, setWhyClosed, setMalfunctionUpdater, now, malfunctionArchive, setMalfunctionArchive }) {

    const dispatch = useDispatch()
    const [ chosenSideNote, setChosenSideNote ] = useState(null)
    const [ malfunctionArchiveEdited, setMalfunctionArchiveEdited ] = useState({})
    const [ malfunctionArchiveId, setMalfunctionArchiveId ] = useState('')

    

    const options = {
        weekday: "short",
        year: "numeric",
        month: "short",
        day: "2-digit",
        timeZone: 'Asia/Jerusalem',
        hour12: false,
        hour: '2-digit',
        minute:'2-digit',
        second: '2-digit' 
    }
    
    let updated_At = now.toLocaleString('en-US', options).replace(/,/g,"")
    
    const userName = mokedWorkersName.map(name => {
        return name.userName
    })
    
    const sideNote = malfunctionArchive.sideNote
    
    const sideNoteAdded = sideNote.length > 1 ? ['כל ההערות', ...sideNote] : [...sideNote]

    useEffect(() => {

        if(!chosenSideNote) return

        const sideNoteEdited = sideNote.filter(note => note !== `${chosenSideNote}`)

        setMalfunctionArchiveEdited({
            ...malfunctionArchive,
            sideNote: [...sideNoteEdited]

        })

        setMalfunctionArchiveId(malfunctionArchive._id)

        
    }, [chosenSideNote, malfunctionArchive, sideNote])
    
    return (
        <div className="malfunctionWindow fadeInDown"> 
            <div className="malfunctionWindowInner fadeIn">
                <button className="closeButton" 
                onClick={() =>{
                    
                    setMalfunctionArchive({})
                    setIsDoneWindow(false)
                 }} >
                <Close className="closeButtonIcon"/></button>
                <div className="malfunctionWindowName">
                    <SiteNameInput searchGroup={userName} wantedValue={closeWorkerName} setWantedValue={setCloseWorkerName} placeHolder="הכנס את שם המוקדן הסוגר" />
                    { sideNote.length > 1 && <SiteNameInput searchGroup={sideNoteAdded} wantedValue={chosenSideNote} setWantedValue={setChosenSideNote} placeHolder="איזו הערה לשלוח לארכיון?" />}                    <div className="siteNameInput">
                        <input type="text" placeholder="סיבת הסגירה" className="entryFormInput entryFormInputEmployList inputWindow" value={whyClosed}
                             onChange={(e) => setWhyClosed(e.target.value)}/>
                    </div>
                </div>
                <div className="addMalfunctionContainer">
                    { !chosenSideNote || chosenSideNote === 'כל ההערות'  ?
                    <button className="addNewMalfunction" onClick={() => {
                                confirmAlert({
                                    title: <p className="approveTitle">אשר לביצוע הפעולה</p>,
                                    message: <p className="approveMessage"> האם אתה בטוח שברצונך להעביר תקלה זאת לארכיון?</p>,
                                    buttons: [
                                    {
                                        label: <p>בטוח</p>,
                                        onClick: () => {

                                        let MalfunctionArchive = malfunctionArchive
                                        MalfunctionArchive.updated_At = updated_At
                                        MalfunctionArchive.closeWorkerName = closeWorkerName
                                        MalfunctionArchive.whyClosed = whyClosed
                                        dispatch(CreateMalfunctionArchive(malfunctionArchive._id, MalfunctionArchive))
                                        setMalfunctionArchive({})
                                        setMalfunctionUpdater(prev => !prev)
                                        setIsDoneWindow(false)

                                        }
                                    },
                                    {
                                        label: <p>בטל</p>,
                                    }
                                    ]
                                })}}  ><Check className="addNewMalfunctionIcon"/></button>
                    :
                    <button className="addNewMalfunction" onClick={() => {
                        confirmAlert({
                            title: <p className="approveTitle">אשר לביצוע הפעולה</p>,
                            message: <p className="approveMessage"> האם אתה בטוח שברצונך להעביר הערה זאת לארכיון?</p>,
                            buttons: [
                            {
                                label: <p>בטוח</p>,
                                onClick: () => {

                                let MalfunctionArchive = malfunctionArchive
                                MalfunctionArchive.updated_At = updated_At
                                MalfunctionArchive.closeWorkerName = closeWorkerName
                                MalfunctionArchive.whyClosed = whyClosed
                                delete MalfunctionArchive._id
                                MalfunctionArchive.sideNote = [chosenSideNote]
                                console.log(MalfunctionArchive)
                                dispatch(CreateMalfunctionArchive(null, MalfunctionArchive))
                                dispatch(UpdateMalfunction(malfunctionArchiveId, malfunctionArchiveEdited))
                                setChosenSideNote(null)
                                setMalfunctionArchiveId('')
                                setMalfunctionArchiveEdited({})
                                setMalfunctionArchive({})
                                setMalfunctionUpdater(prev => !prev)
                                setIsDoneWindow(false)

                                }
                            },
                            {
                                label: <p>בטל</p>,
                            }
                            ]
                        })}}  ><Check className="addNewMalfunctionIcon"/></button>
                    }
                </div>
            </div>
        </div>
    )
}
