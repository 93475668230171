import "./siteNameInput.css"
import useAutocomplete from "@material-ui/lab/useAutocomplete";

export default function SiteNameInput( props ) {

    const { getRootProps, getInputProps, getListboxProps, getOptionProps, groupedOptions} = useAutocomplete({
        value: props.wantedValue || null,
        onChange:(event, newValue) => {props.setWantedValue(newValue)},
        matchFrom: "start",
        openOnFocus: true,
        options: props.searchGroup,
        getOptionLabel: (option) => option
      })     

      return (
        <div >
          <div  {...getRootProps()}>
            <div className="siteNameInput">
                <input 
                className="entryFormInput entryFormInputEmployList inputWindow" 
                placeholder={props.placeHolder}

                {...getInputProps()} />
            
            {groupedOptions.length > 0 ? (
                <ul className="siteNameListWindow" {...getListboxProps()}>
                {groupedOptions.map((option, index) => (
                    <li className="siteNameListItem" {...getOptionProps({ option, index })}>{option}</li>
                ))}
                </ul>
            ) : null}
            </div>
          </div>
        </div>
      );
    }