
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { CreateFarmsInformation, DeleteFarmsInformation, UpdateFarmsInformation } from "../../../actions/FarmsInformation";
import ManagerPatrolList from "./ManagerPatrolList/ManagerPatrolList";
import ManagerSideNoteList from "./ManagerSideNoteList/ManagerSideNoteList";
import ManagerSiteInformationName from "./ManagerSiteInformationName/ManagerSiteInformationName";

export default function ManagerFarmsInformation({ chosenCURDType, setFarmInfoUpdater, farmsInformation }) {

    const [ siteNameValue, setSiteNameValue ] = useState("")

    const farmsInformationName = farmsInformation.map((farm) => farm.siteName)

return(
    <>
    { chosenCURDType && <div className="siteInformationSection">
      <div className="siteInformationWrapper">
        <div className="mokedWorkerRight">
          <h1 className="mokedWorkerTitle">{chosenCURDType}</h1>
        </div>
          <div className="siteInformationContainer">
            {chosenCURDType === "רישום" ?
            <CreateSiteInformationCRUD setFarmInfoUpdater={setFarmInfoUpdater} />
            : chosenCURDType === "עריכה" ?
            <EditSiteInformationCRUD farmsInformation={farmsInformation} setFarmInfoUpdater={setFarmInfoUpdater}
            siteNameValue={siteNameValue} setSiteNameValue={setSiteNameValue} farmsInformationName={farmsInformationName} />
             :
             <DeleteSiteInformationCRUD setFarmInfoUpdater={setFarmInfoUpdater} siteNameValue={siteNameValue} setSiteNameValue={setSiteNameValue} 
             farmsInformationName={farmsInformationName} farmsInformation={farmsInformation} />
            }
          </div>
        </div>
      </div>}
      </>
    )
}

const CreateSiteInformationCRUD = ({ setFarmInfoUpdater }) => {

    const dispatch = useDispatch();

    const [ patrolArray, setPatrolArray ] = useState([])
    const [ sideNoteArray, setSideNoteArray ] = useState([])     

    const [siteInformationData, setSiteInformationData] = useState({
        siteName: '',
        manbat: '',
        manager: '',
        system: '',
        connected: '',
        password: '',
        patrol: [],
        security: '',
        sideNote: [],
        gate: '',
        malfunctions: [{
          urgent: [],
          notUrgent: [],
          emailList: [],
        }] 
    })

    useEffect(() => {

        setSiteInformationData((prev) => ({
            ...prev,
            patrol: patrolArray,
            sideNote: sideNoteArray
        }))

    }, [ patrolArray, sideNoteArray ])

    const handleSubmit = (e) => {
        e.preventDefault()
        
          dispatch(CreateFarmsInformation(siteInformationData))
      
          Clear()
    
      }
      
      const Clear = () => {
      
        setSiteInformationData({
            siteName: '',
            manbat: '',
            manager: '',
            system: '',
            connected: '',
            password: '',
            patrol: [],
            security: '',
            sideNote: [],
            gate: '',
            malfunctions: [{
              urgent: [],
              notUrgent: [],
              emailList: [],
            }] 
        })

        setPatrolArray([])
        setSideNoteArray([])

        setFarmInfoUpdater((prev) => !prev)
    
      }    
    
    
    return(
            <div>
                    <form className="mokedWorkerForm" onSubmit= {handleSubmit}>
                    <div className="siteInformationContainerTop">
                        <input type="text" className="mokedWorkerInput" value={siteInformationData.siteName} placeholder="שם האתר" onChange={(e) => setSiteInformationData({ ...siteInformationData, siteName: e.target.value })}/>
                        <input type="text" className="mokedWorkerInput" value={siteInformationData.manager} placeholder="מנהל תחום קנאביס" onChange={(e) => setSiteInformationData({ ...siteInformationData, manager: e.target.value })}/>
                        <input type="text" className="mokedWorkerInput" value={siteInformationData.manbat} placeholder="שם המנב''ט" onChange={(e) => setSiteInformationData({ ...siteInformationData, manbat: e.target.value })}/>
                        <input type="text" className="mokedWorkerInput" value={siteInformationData.system} placeholder="שירות טכנולוגי" onChange={(e) => setSiteInformationData({ ...siteInformationData, system: e.target.value })}/>
                        <input type="text" className="mokedWorkerInput" value={siteInformationData.connected} placeholder="באיזה אופן מחובר" onChange={(e) => setSiteInformationData({ ...siteInformationData, connected: e.target.value })}/>
                        <ManagerPatrolList patrolArray={patrolArray} setPatrolArray={setPatrolArray} />
                        <input type="text" className="mokedWorkerInput" value={siteInformationData.security} placeholder="אבטחה" onChange={(e) => setSiteInformationData({ ...siteInformationData, security: e.target.value })}/>
                        <input type="text" className="mokedWorkerInput" value={siteInformationData.gate} placeholder="שערים" onChange={(e) => setSiteInformationData({ ...siteInformationData, gate: e.target.value })}/>
                        <input type="text" className="mokedWorkerInput" value={siteInformationData.password} placeholder="סיסמת לחצן מצוקה" onChange={(e) => setSiteInformationData({ ...siteInformationData, password: e.target.value })}/>
                        <ManagerSideNoteList sideNoteArray={sideNoteArray} setSideNoteArray={setSideNoteArray} />
                    </div>
                    <div className="buttonWrapperUserClient">
                        <input type="submit" className="BottomFormMokedWorkersButton enterCRUDButton" value="רשום חווה" />
                    </div>
                </form>
            </div>
    )
    
    }

const EditSiteInformationCRUD = ({ farmsInformationName, siteNameValue, setSiteNameValue, farmsInformation, setFarmInfoUpdater }) => {

    const dispatch = useDispatch();

    const chosenSiteInformation = farmsInformation.find(site => site.siteName === siteNameValue)

    const [ patrolArray, setPatrolArray ] = useState([])
    const [ sideNoteArray, setSideNoteArray ] = useState([])

    const [siteInformationData, setSiteInformationData] = useState({
        siteName: '',
        manbat: '',
        manager: '',
        system: '',
        connected: '',
        password: '',
        patrol: [],
        security: '',
        sideNote: [],
        gate: '',
        malfunctions: [{
          urgent: [],
          notUrgent: [],
          emailList: [],
        }] 
    })

    useEffect(() => {

        if(chosenSiteInformation){

        setSiteInformationData({
            ...chosenSiteInformation,
            gate: chosenSiteInformation?.gate ? chosenSiteInformation?.gate : "",
        })

        setPatrolArray(chosenSiteInformation.patrol)
        setSideNoteArray(chosenSiteInformation.sideNote)

    }

    }, [chosenSiteInformation])

    useEffect(() => {

        setSiteInformationData((prev) => ({
            ...prev,
            patrol: patrolArray,
            sideNote: sideNoteArray
        }))

    }, [ patrolArray, sideNoteArray ])

    const handleSubmit = (e) => {
        e.preventDefault()  
        
          dispatch(UpdateFarmsInformation(chosenSiteInformation._id, siteInformationData))
      
          Clear()
    
      }
      
      const Clear = () => {
      
        setSiteInformationData({
            siteName: '',
            manbat: '',
            manager: '',
            system: '',
            connected: '',
            password: '',
            patrol: [],
            security: '',
            sideNote: [],
            gate: '',
            malfunctions: [{
              urgent: [],
              notUrgent: [],
              emailList: [],
            }] 
        })

        setPatrolArray([])
        setSideNoteArray([])

        setSiteNameValue("")

        setFarmInfoUpdater((prev) => !prev)
    
      }  
    
    return(
        <div>
        <form className="mokedWorkerForm" onSubmit= {handleSubmit}>
            <div className="siteInformationContainerTop">
                <ManagerSiteInformationName sitesInformationName={farmsInformationName} siteNameValue={siteNameValue} setSiteNameValue={setSiteNameValue}/>
                <input type="text" className="mokedWorkerInput" value={siteInformationData.siteName} placeholder="שם האתר" onChange={(e) => setSiteInformationData({ ...siteInformationData, siteName: e.target.value })}/>
                <input type="text" className="mokedWorkerInput" value={siteInformationData.manager} placeholder="מנהל תחום קנאביס" onChange={(e) => setSiteInformationData({ ...siteInformationData, manager: e.target.value })}/>
                <input type="text" className="mokedWorkerInput" value={siteInformationData.manbat} placeholder="שם המנב''ט" onChange={(e) => setSiteInformationData({ ...siteInformationData, manbat: e.target.value })}/>
                <input type="text" className="mokedWorkerInput" value={siteInformationData.system} placeholder="שירות טכנולוגי" onChange={(e) => setSiteInformationData({ ...siteInformationData, system: e.target.value })}/>
                <input type="text" className="mokedWorkerInput" value={siteInformationData.connected} placeholder="באיזה אופן מחובר" onChange={(e) => setSiteInformationData({ ...siteInformationData, connected: e.target.value })}/>
                <ManagerPatrolList patrolArray={patrolArray} setPatrolArray={setPatrolArray} />
                <input type="text" className="mokedWorkerInput" value={siteInformationData.security} placeholder="אבטחה" onChange={(e) => setSiteInformationData({ ...siteInformationData, security: e.target.value })}/>
                <input type="text" className="mokedWorkerInput" value={siteInformationData.gate} placeholder="שערים" onChange={(e) => setSiteInformationData({ ...siteInformationData, gate: e.target.value })}/>
                <input type="text" className="mokedWorkerInput" value={siteInformationData.password} placeholder="סיסמת לחצן מצוקה" onChange={(e) => setSiteInformationData({ ...siteInformationData, password: e.target.value })}/>
                <ManagerSideNoteList sideNoteArray={sideNoteArray} setSideNoteArray={setSideNoteArray} />
            </div>
            <div className="buttonWrapperUserClient">
                <input type="submit" className="BottomFormMokedWorkersButton editCRUDButton" value="ערוך חווה" />
            </div>
        </form>
        </div>
    )
}

const DeleteSiteInformationCRUD = ({ setFarmInfoUpdater, farmsInformationName, siteNameValue, setSiteNameValue, farmsInformation }) => {

    const dispatch = useDispatch();

    const chosenSiteInformation = farmsInformation.find(site => site.siteName === siteNameValue)
    
    const handleSubmit = (e) => {
      e.preventDefault()
    
      dispatch(DeleteFarmsInformation( chosenSiteInformation._id))

      Clear()

    }

    const Clear = () => {

        setSiteNameValue("")

        setFarmInfoUpdater((prev) => !prev)
    
    }
    
    return(
            <div className="clientUserFormContainer">
                <div className="EditMokedWorkerCRUDTop">
                    <ManagerSiteInformationName sitesInformationName={farmsInformationName} siteNameValue={siteNameValue} setSiteNameValue={setSiteNameValue}/>
                </div>
                    <form className="mokedWorkerForm" onSubmit= {handleSubmit}>
                    <div className="buttonWrapperUserClient">
                        <input type="submit" className="BottomFormMokedWorkersButton deleteCRUDButton" value="מחק חווה" />
                    </div>
                </form>
            </div>
    )
}