import {
  CREATE_NEW_MALFUNCTION,
  FETCH_ALL_MALFUNCTIONS,
  UPDATE_MALFUNCTION,
  DELETE_MALFUNCTION,
} from "../constants/actionTypes";

import * as api from "../api";

export const CreateMalfunction = (newMalfunction) => async (dispatch) => {
  try {
    const { data } = await api.createMalfunction(newMalfunction);

    dispatch({ type: CREATE_NEW_MALFUNCTION, payload: data });
  } catch (error) {
    console.log(error);
  }
};

export const FetchAllMalfunctions = (userName, isMoked) => async (dispatch) => {
  try {
    const { data } = await api.fetchAllMalfunctions(userName, isMoked);

    dispatch({ type: FETCH_ALL_MALFUNCTIONS, payload: data });
  } catch (error) {
    console.log(error);
  }
};

export const UpdateMalfunction =
  (id, updatedMalfunction) => async (dispatch) => {
    try {
      const { data } = await api.updateMalfunction(id, updatedMalfunction);

      dispatch({ type: UPDATE_MALFUNCTION, payload: data });
    } catch (error) {
      console.log(error);
    }
  };

export const DeleteMalfunction = (id) => async (dispatch) => {
  try {
    await api.deleteMalfunction(id);

    dispatch({ type: DELETE_MALFUNCTION, payload: id });
  } catch (error) {
    console.log(error);
  }
};
