import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { SignIn } from "../../../actions/auth";

const MokedSignIn = () => {
  const history = useHistory();

  const dispatch = useDispatch();

  const [mokedData, setMokedData] = useState({
    userName: "",
    password: "",
    phone: "",
    type: "",
    isMoked: true,
  });

  const [isError, setError] = useState(false);

  const checkboxPressHandler = (e) => {
    setMokedData({
      ...mokedData,
      type: e.target.checked ? e.target.value : "",
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!mokedData.type) {
      alert("הכנס סוג מוקד");
    } else {
      try {
        const result = await dispatch(SignIn(mokedData, history));

        if (!result) setError(true);
      } catch (error) {
        setError(true);
      }
    }
  };

  return (
    <div>
      <div className="wrapper fadeInDown">
        <div id="formContent">
          <div className="fadeIn first">
            <img src="/assets/assom2.0.png" id="icon" alt="User Icon" />
          </div>

          <form onSubmit={handleSubmit}>
            <input
              value={mokedData.userName}
              type="text"
              id="login"
              className="input fadeIn second"
              name="login"
              placeholder="שם משתמש"
              onChange={(e) =>
                setMokedData({ ...mokedData, userName: e.target.value })
              }
            />
            <input
              value={mokedData.password}
              type="password"
              id="password"
              className="input fadeIn second"
              name="password"
              placeholder="סיסמא"
              onChange={(e) =>
                setMokedData({ ...mokedData, password: e.target.value })
              }
            />
            <input
              value={mokedData.phone}
              disabled
              type="text"
              id="phone"
              className="input fadeIn second disabled"
              name="phone"
              placeholder="מספר שלוחה"
              onChange={(e) =>
                setMokedData({ ...mokedData, phone: e.target.value })
              }
            />
            <div className="signInCheckbox">
              <input
                value="נתיבות"
                type="checkbox"
                id="typeSmall"
                className="mokedTypeMangerInfoCheckbox fadeIn second"
                name="type"
                onChange={(e) => checkboxPressHandler(e)}
              />
              <p className="mokedTypeMangerInfoLabel">מוקד 1</p>
              <input
                value="אשכול"
                type="checkbox"
                id="typeBig"
                className="mokedTypeMangerInfoCheckbox fadeIn second"
                name="type"
                onChange={(e) => checkboxPressHandler(e)}
              />
              <p className="mokedTypeMangerInfoLabel">מוקד 2</p>
            </div>
            {isError && (
              <p className="signInErrorMessage">אחד מהפרטים שהוזנו שגוי</p>
            )}
            <input
              type="submit"
              className="input fadeIn fourth"
              value="התחבר למוקד"
            />
          </form>
        </div>
      </div>
    </div>
  );
};

export default MokedSignIn;
